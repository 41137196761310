import React, { Fragment, FunctionComponent } from "react";

import {
  IDeviceDownlink,
  IDownlinkParameter,
  ISeparatedDownlinkFormat
} from "../../models/IDownlinks";
import { StyledTextField, TextField } from "../../../../components/TextField";
import { splitCamelCase } from "../../../../core/utilities/ServiceUtilities";
import { Validators } from "../../../../util/validators";
import { Typography } from "@material-ui/core";
import { ArrayHelpers } from "formik/dist/FieldArray";
import { FormikProps } from "formik/dist/types";
type SimpleFormFieldArrayType = (
  splitedFormat: ISeparatedDownlinkFormat[],
  isLoading: boolean,
  isSubmitting: boolean
) => FunctionComponent<
  ArrayHelpers & {
    form: FormikProps<IDeviceDownlink>;
    name: string;
  }
>;
export const SimpleDownlinkFormArray: SimpleFormFieldArrayType = (
  splitedFormat,
  isLoading,
  isSubmitting
) => ({
  form: {
    values: { tmpHexs }
  }
}) => {
  return (
    <Fragment>
      <Typography variant="h6" gutterBottom={true}>
        Downlink Payload
      </Typography>
      {tmpHexs.length > 0 &&
        tmpHexs.map((hex: IDownlinkParameter, index: number) =>
          !hex.initialValue ? (
            <Fragment key={index}>
              <StyledTextField
                type="text"
                label={splitCamelCase(hex.name)}
                validate={Validators.editDeviceDownlinks.simpleDownlink(
                  hex.format
                )}
                name={`tmpHexs[${index}].value`}
                disabled={isLoading || isSubmitting}
                component={TextField}
              />
            </Fragment>
          ) : null
        )}
    </Fragment>
  );
};
