import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { RouteComponentProps } from "react-router";
import { Grid, Hidden, CircularProgress, Typography } from "@material-ui/core";
import { withSnackbar, WithSnackbarProps } from "notistack";

import { withThemeProvider } from "../core/withThemeProvider";
import { IRootState } from "../core/store";
import { IDevice } from "../Devices/models/IDevice";
import {
  getDeviceByIdWithModel,
  getDeviceMeasurements
} from "../Devices/actions/deviceAction";
import { ResourcePredictions } from "./components/ResourcePredictions/ResourcePredictions";
import { DeviceMap } from "../Location/components/DeviceMap";
import { ResourceGridTile } from "./components/ResourceGridTile";
import { ResourceVolume } from "./components/ResourceVolume/ResourceVolume";
import { ResourceVolumeChanges } from "./components/ResourceVolumeChanges";
import { ResourceVolumeHistory } from "./components/ResourceVolumeHistory";
import { IInfluxTableQuery, IResource } from "./models/IResource";
import { getResourceMeasurements, setTabState } from "./actions/resourceAction";
import { DeviceTable } from "../Devices/components/DeviceTable";
import { StyledBackground } from "../components/sharedStyledComponents";
import { DeviceList } from "../Devices/components/DeviceList";
import {
  isSnackbarError,
  SnackbarError
} from "../core/utilities/SnackbarUtilities";

interface IStyledProgress {
  margin?: string;
}
interface ITileLabel {
  padding?: string;
}
interface IPathParamsType {
  id: string;
}
interface IResourcesStateProps {
  device: IDevice;
  resource: IResource;
}
interface IResourcesDispatchProps {
  getDevice(id: string): Promise<IDevice | SnackbarError>;
  deviceMeasurements(
    device: IDevice
  ): Promise<IInfluxTableQuery[] | SnackbarError>;
  setTab(tabId: number): void;
  resourceMeasurements(id: string): Promise<undefined | SnackbarError[]>;
}

type ResourcesProps = IResourcesStateProps &
  IResourcesDispatchProps &
  RouteComponentProps<IPathParamsType> &
  WithSnackbarProps;

export const StyledProgress = styled(CircularProgress)<IStyledProgress>`
  ${props => (props.margin ? "margin: " + props.margin : "")};
`;
StyledProgress.displayName = "StyledProgress";

export const DelimiterGrid = styled(Grid)`
  border-right: 1px dashed #e6e6e6;
`;
DelimiterGrid.displayName = "DelimiterGrid";

export const TileLabel = styled(Grid)<ITileLabel>`
  &&& {
    color: #6f6f6f;
    padding: ${props => (props.padding ? props.padding : "40px 25px 10px")};
    font-size: 15px;
    text-transform: uppercase;
  }
`;
TileLabel.displayName = "TileLabel";

export const ForecastNote = styled(Typography)`
  && {
    padding: 10px 10px 0;
    color: #6f6f6f;
    font-style: italic;
  }
`;
ForecastNote.displayName = "ForecastNote";

export class Resources extends React.Component<ResourcesProps> {
  public async componentDidMount() {
    const {
      resourceMeasurements,
      getDevice,
      deviceMeasurements,
      enqueueSnackbar,
      match: {
        params: { id }
      }
    } = this.props;
    const [resources, device] = await Promise.all([
      resourceMeasurements(id),
      getDevice(id)
    ]);
    if (resources && resources.length > 0) {
      resources.forEach(resource => {
        if (isSnackbarError(resource)) {
          enqueueSnackbar(resource.message, resource.options);
        }
      });
    }
    if (device) {
      if (isSnackbarError(device)) {
        enqueueSnackbar(device.message, device.options);
      } else {
        const measurements = await deviceMeasurements(device);
        if (isSnackbarError(measurements)) {
          enqueueSnackbar(measurements.message, measurements.options);
        }
      }
    }
  }

  public setOpenedTab = (_: any, id: number) => {
    this.props.setTab(id);
  };

  public render() {
    const { resource, device } = this.props;
    return (
      <React.Fragment>
        <Grid
          container={true}
          item={true}
          direction="row"
          justify="center"
          alignItems="stretch"
          alignContent="stretch"
          xs={12}
        >
          <TileLabel item={true} xs={12}>
            Status
          </TileLabel>
          <Grid
            container={true}
            item={true}
            xl={3}
            lg={4}
            md={5}
            sm={6}
            xs={12}
            direction="column"
          >
            <ResourceGridTile name="Volume" stretch={1} padding="10px 10px 0 0">
              <ResourceVolume data={resource} />
            </ResourceGridTile>
          </Grid>
          <Grid
            container={true}
            item={true}
            xl={9}
            lg={8}
            md={7}
            sm={6}
            xs={12}
            direction="column"
            justify="flex-start"
            alignItems="stretch"
          >
            <ResourceGridTile name="Volume Changes" padding="10px 0 0">
              <ResourceVolumeChanges
                volumeChanges={resource.volumeChanges}
                isLoading={resource.isLoading}
              />
            </ResourceGridTile>
            <ResourceGridTile
              name="Past Volumes"
              padding="10px 0 0"
              stretch={1}
            >
              <Grid
                container={true}
                item={true}
                justify="space-evenly"
                xs={12}
                direction="row"
              >
                <ResourceVolumeHistory
                  month={1}
                  monthName={resource.pastVolumeOneMonthName}
                  volume={resource.pastVolumeOneMonthValue}
                  volumeUnit={resource.pastVolumeOneMonthValueUnit}
                  volumeLastYear={resource.pastVolumeOneMonthLastYear}
                  volumeLastYearUnit={resource.pastVolumeOneMonthLastYearUnit}
                  loading={resource.isLoading}
                />
                <Hidden mdDown={true}>
                  <DelimiterGrid />
                </Hidden>

                <ResourceVolumeHistory
                  month={3}
                  monthName={resource.pastVolumeThreeMonthName}
                  volume={resource.pastVolumeThreeMonthValue}
                  volumeUnit={resource.pastVolumeThreeMonthValueUnit}
                  volumeLastYear={resource.pastVolumeThreeMonthLastYear}
                  volumeLastYearUnit={resource.pastVolumeThreeMonthLastYearUnit}
                  loading={resource.isLoading}
                />
              </Grid>
            </ResourceGridTile>
          </Grid>
        </Grid>
        {resource.weatherAvailable && (
          <React.Fragment>
            <TileLabel item={true} xs={12} padding="40px 25px 20px">
              Predictions
            </TileLabel>
            <ResourcePredictions
              resource={resource}
              setTab={this.setOpenedTab}
            />
            <Grid container={true} item={true} xs={12}>
              <ForecastNote variant="caption">
                Predicative rainfall data is provided by Bureau of Meteorology.
                Forecast updated fortnightly at mid and end of month.
              </ForecastNote>
            </Grid>
          </React.Fragment>
        )}
        <Grid container={true} item={true} xs={12}>
          <TileLabel item={true} xs={12} padding="40px 25px 20px">
            Device Information
          </TileLabel>
          <StyledBackground padding="10px 15px 0" overflow="auto">
            <Hidden smDown={true}>
              <DeviceTable rows={[device]} />
            </Hidden>
            <Hidden mdUp={true}>
              <DeviceList rows={[device]} />
            </Hidden>
          </StyledBackground>
        </Grid>
        <Grid item={true} container={true} xs={12} justify={"center"}>
          <TileLabel item={true} xs={12} padding="40px 25px 20px">
            Location
          </TileLabel>
          <Grid
            container={true}
            item={true}
            direction="row"
            justify="center"
            xs={12}
          >
            <StyledBackground padding="0">
              <DeviceMap device={device as any} mapType="satellite" />
            </StyledBackground>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IResourcesStateProps => ({
  device: state.device as any,
  resource: state.resource
});

const mapDispatchToProps = (dispatch: any): IResourcesDispatchProps => {
  return {
    getDevice: (id: string): Promise<IDevice | undefined> => {
      return dispatch(getDeviceByIdWithModel(id));
    },
    deviceMeasurements: (device: IDevice) =>
      dispatch(getDeviceMeasurements(device)),
    setTab: (tabId: number) => {
      dispatch(setTabState(tabId));
    },
    resourceMeasurements: (id: string) => dispatch(getResourceMeasurements(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(Resources)));
