import * as React from "react";
import { Field, FormikProps } from "formik";
import styled from "styled-components";
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Typography
} from "@material-ui/core";

import { Validators } from "../../util/validators";
import { ISubscriptionWithRole, IUserProfile } from "../models/IUserProfile";
import { StyledTextField, TextField } from "../../components/TextField";
import { CheckboxWithLabel } from "../../components/CheckboxWithLabel";
import { Select, StyledSelect } from "../../components/Select";
import { IRole } from "../../Roles/models/IRole";
import { HighlightedBox } from "./HighlightedBox";
import {
  countryCodes,
  ICountryCode
} from "../../core/configuration/countryCodes";
import { theme } from "../../core/withThemeProvider";
import { trimPhoneNumber } from "../../core/utilities/PhoneUtilities";

export const CheckboxSegmentLabel = styled(Typography)`
  margin-top: 20px;
`;
CheckboxSegmentLabel.displayName = "CheckboxSegmentLabel";

export const UserProfileCheckbox = styled(Field)`
  height: 35px;
`;
UserProfileCheckbox.displayName = "UserProfileCheckbox";

export const GreyPhoneCode = styled.span`
  color: ${theme.palette.secondary.main};
`;
GreyPhoneCode.displayName = "GreyPhoneCode";

export const EditUserProfileForm: React.FunctionComponent<FormikProps<
  IUserProfile
>> = ({
  submitForm,
  isSubmitting,
  values,
  setFieldValue,
  handleBlur
}: FormikProps<IUserProfile>) => {
  const { isLoading, countryCode, mobile } = values;
  const {
    editUserProfile: { email, phone, name, subscription, role: roleValidator }
  } = Validators;
  const currentRole = values.hasRoles.filter(
    subWithRole => subWithRole.id === values.activeSubscription
  );
  const handleSubscriptionChange = ({
    target: { name: eventName, value }
  }: React.ChangeEvent<HTMLSelectElement>) => {
    setFieldValue(eventName, value);
    const sub = values.hasRoles.filter(({ id }) => id === value).pop();
    if (sub?.roles.length === 1) {
      setFieldValue("activeRole", sub.roles[0].id);
    } else {
      setFieldValue("activeRole", "");
    }
  };
  const trimMobile = (e: React.SyntheticEvent) => {
    setFieldValue("mobile", trimPhoneNumber(mobile));
    handleBlur(e);
  };
  const renderPhoneCode = (code: string) => `+${code}`;
  return (
    <form>
      <HighlightedBox title="Switch Role">
        <StyledSelect>
          <InputLabel htmlFor="activeSubscription">
            Active Subscription
          </InputLabel>
          <Field
            name="activeSubscription"
            component={Select}
            disabled={isSubmitting || isLoading}
            validate={subscription}
            onChange={handleSubscriptionChange}
          >
            {values.hasRoles.map((subWithRole: ISubscriptionWithRole) => (
              <MenuItem key={subWithRole.id} value={subWithRole.id}>
                {subWithRole.name}
              </MenuItem>
            ))}
          </Field>
        </StyledSelect>
        <br />
        <StyledSelect>
          <InputLabel htmlFor="activeRole">Active Role</InputLabel>
          <Field
            name="activeRole"
            component={Select}
            disabled={isSubmitting || isLoading}
            validate={roleValidator}
          >
            {currentRole.length === 1
              ? currentRole[0].roles.map((role: IRole) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))
              : null}
          </Field>
        </StyledSelect>
      </HighlightedBox>
      <br />
      <StyledTextField
        type="text"
        label="Full Name"
        name="name"
        validate={name}
        disabled={isLoading || isSubmitting}
        component={TextField}
      />
      <br />
      <StyledTextField
        type="text"
        label="Email"
        name="email"
        validate={email}
        disabled={isLoading || isSubmitting}
        component={TextField}
      />
      <br />
      <StyledTextField
        type="text"
        label="Mobile"
        name="mobile"
        onBlur={trimMobile}
        validate={phone(countryCode)}
        disabled={isLoading || isSubmitting}
        component={TextField}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FormControl>
                <Field
                  name="countryCode"
                  component={Select}
                  disabled={isSubmitting || isLoading}
                  renderValue={renderPhoneCode}
                >
                  {countryCodes.map((country: ICountryCode) => (
                    <MenuItem key={country.iso} value={country.code}>
                      <span>{country.country}&nbsp;</span>
                      <GreyPhoneCode>+{country.code}</GreyPhoneCode>
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </InputAdornment>
          )
        }}
      />
      <br />
      <CheckboxSegmentLabel align={"left"} variant={"caption"}>
        How do you prefer to receive alerts?
      </CheckboxSegmentLabel>
      <br />
      <UserProfileCheckbox
        color={"primary"}
        Label={{ label: "SMS" }}
        name="smsNotification"
        component={CheckboxWithLabel}
        disabled={isLoading || isSubmitting}
      />
      <br />
      <UserProfileCheckbox
        color={"primary"}
        Label={{ label: "Email" }}
        name="emailNotification"
        component={CheckboxWithLabel}
        disabled={isLoading || isSubmitting}
      />
      <br />
      {(isLoading || isSubmitting) && <LinearProgress />}
      <br />
      <Grid
        container={true}
        item={true}
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Button
          variant="contained"
          color="primary"
          disabled={isLoading || isSubmitting}
          onClick={submitForm}
        >
          Save
        </Button>
      </Grid>
    </form>
  );
};
EditUserProfileForm.displayName = "EditUserProfileForm";
