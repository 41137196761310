import {
  Action,
  ActionCreator,
  applyMiddleware,
  combineReducers,
  createStore
} from "redux";
import thunk, { ThunkAction } from "redux-thunk";
import { History } from "history";

import { ENVIRONMENT, environment } from "./configuration/config";
import { IDevice, IDeviceCollection } from "../Devices/models/IDevice";
import { logger } from "./middleware/logger.middleware";
import { deviceCollectionReducer } from "../Devices/reducers/deviceCollectionReducer";
import { IGlobalState } from "../models/IGlobalState";
import {
  globalStateReducer,
  globalStateReducerTypes
} from "../reducers/globalStateReducer";
import { deviceReducer } from "../Devices/reducers/deviceReducer";
import { IUserProfile } from "../Profile/models/IUserProfile";
import { userProfileReducer } from "../Profile/reducers/userProfileReducer";
import { ILocation } from "../Location/models/IGeolocation";
import { locationReducer } from "../Location/reducers/locationReducer";
import { IExportDevice } from "../ExportDevice/models/IExportDevice";
import { exportDeviceReducer } from "../ExportDevice/reducers/exportDeviceReducer";
import { IResource } from "../Resources/models/IResource";
import { resourceReducer } from "../Resources/reducers/resourceReducer";
import {
  IDeviceAlert,
  IDeviceAlertCollection
} from "../Devices/DeviceAlerts/models/IAlerts";
import { deviceAlertReducer } from "../Devices/DeviceAlerts/reducers/alertReducer";
import { deviceAlertCollectionReducer } from "../Devices/DeviceAlerts/reducers/alertCollectionReducer";
import { IViewDeviceParameters } from "../Devices/DeviceParameters/models/IViewDeviceParameters";
import { deviceParametersReducer } from "../Devices/DeviceParameters/reducers/DeviceParametersReducer";
import {
  IDeviceDownlink,
  IDeviceDownlinkCollection
} from "../Devices/DeviceDownlinks/models/IDownlinks";
import { deviceDownlinkCollectionReducer } from "../Devices/DeviceDownlinks/reducers/downlinkCollectionReducer";
import { deviceDownlinkReducer } from "../Devices/DeviceDownlinks/reducers/downlinkReducer";
import { history } from "./configuration/history";
import { blocker } from "./middleware/blocker.middleware";
import { ILocationsHistory } from "../Location/models/ILocationsHistory";
import { deviceLocationsHistoryReducer } from "../Location/reducers/deviceLocationsHistoryReducer";

export interface IRootState {
  deviceCollection: IDeviceCollection;
  resource: IResource;
  globalState: IGlobalState;
  device: IDevice;
  profile: IUserProfile;
  exportDevice: IExportDevice;
  locations: ILocation;
  deviceLocationsHistory: ILocationsHistory;
  deviceAlertCollection: IDeviceAlertCollection;
  deviceAlert: IDeviceAlert;
  deviceParameters: IViewDeviceParameters;
  deviceDownlinkCollection: IDeviceDownlinkCollection;
  deviceDownlink: IDeviceDownlink;
}

const rootReducer = combineReducers<IRootState>({
  deviceCollection: deviceCollectionReducer,
  resource: resourceReducer,
  globalState: globalStateReducer,
  device: deviceReducer,
  profile: userProfileReducer,
  locations: locationReducer,
  deviceLocationsHistory: deviceLocationsHistoryReducer,
  exportDevice: exportDeviceReducer,
  deviceAlertCollection: deviceAlertCollectionReducer,
  deviceAlert: deviceAlertReducer,
  deviceParameters: deviceParametersReducer,
  deviceDownlinkCollection: deviceDownlinkCollectionReducer,
  deviceDownlink: deviceDownlinkReducer
});

export const store = createStore(
  rootReducer,
  {},
  environment === ENVIRONMENT.PRODUCTION
    ? applyMiddleware(thunk.withExtraArgument({ history }), blocker)
    : applyMiddleware(thunk.withExtraArgument({ history }), blocker, logger)
);

export type Thunk<T extends Action<any>> = ActionCreator<
  ThunkAction<
    void,
    IRootState,
    { history: History },
    T | globalStateReducerTypes
  >
>;
