import * as React from "react";
import moment from "moment";
import styled from "styled-components";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from "recharts";

import { Configuration } from "../../core/configuration/config";
import { IVolumeChanges } from "../models/IResource";
import { StyledProgress } from "../Resources";
import { theme } from "../../core/withThemeProvider";
import { ILoadable } from "../../core/models/MixinInterfaces";

interface IChartContainer {
  height: number;
}
interface IVolumeProps extends ILoadable {
  volumeChanges: IVolumeChanges[];
}

export const ChartContainer = styled.div<IChartContainer>`
  height: ${props => props.height}px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 45px;
  & > * {
    font-size: initial;
  }
`;
ChartContainer.displayName = "ChartContainer";

export const TooltipContainer = styled.div`
  background: rgba(0, 0, 0, 0.83);
  border-radius: 7px;
  padding: 5px 20px;
  color: white;
  opacity: 0.8;
  & > p {
    margin: 5px;
  }
  & > p:not(:first-child) {
    text-align: left;
    ::before {
      content: "●";
      color: ${theme.palette.secondary.main};
      padding-right: 3px;
    }
  }
`;
TooltipContainer.displayName = "TooltipContainer";

export const MinLabel = styled.p`
  &&&::before {
    color: ${Configuration.colors.charts.deviceChart.min};
  }
`;
MinLabel.displayName = "MinLabel";
export const MaxLabel = styled.p`
  &&&::before {
    color: ${Configuration.colors.charts.deviceChart.max};
  }
`;
MaxLabel.displayName = "MaxLabel";

export const chartComparableNumber = (value: number | null): number =>
  value === null ? -Infinity : value;

export const ResourceVolumeChanges: React.FunctionComponent<IVolumeProps> = props => {
  const { volumeChanges, isLoading } = props;
  const chartData = volumeChanges.map(volume => {
    const currentMonth = volume.time.format("MMM") === moment().format("MMM");
    return {
      month: volume.time.format("MMM"),
      max: volume.max || null,
      min: volume.min || null,
      prediction:
        currentMonth && volume.max ? volume.max : volume.prediction || null,
      unit: volume.unit,
      date: volume.time
    };
  });
  const maximum =
    chartData.reduce((acc, cur) => {
      return Math.max(
        chartComparableNumber(cur.max),
        chartComparableNumber(cur.min),
        chartComparableNumber(cur.prediction)
      ) >
        Math.max(
          chartComparableNumber(acc.max),
          chartComparableNumber(acc.min),
          chartComparableNumber(acc.prediction)
        )
        ? cur
        : acc;
    }, chartData[0]) || {};

  return (
    <ChartContainer height={Configuration.isMobile ? 300 : 200}>
      {isLoading && <StyledProgress />}
      {!isLoading && (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={chartData}
            margin={{ top: 20, left: 0, right: 40, bottom: 30 }}
          >
            <CartesianGrid stroke={Configuration.colors.charts.cartesianGrid} />
            <XAxis dataKey="month" axisLine={false} />
            <YAxis
              axisLine={false}
              domain={
                maximum.max || maximum.min || maximum.prediction
                  ? [0, "auto"]
                  : [1, 2]
              }
            />
            <Tooltip content={ResourceVolumeChangesTooltip} />
            <ReferenceLine
              stroke={Configuration.colors.charts.referenceLine}
              x={moment().format("MMM")}
            />
            <Line
              type="linear"
              dataKey="min"
              stroke={Configuration.colors.charts.deviceChart.min}
              connectNulls={true}
              strokeWidth={4}
            />
            <Line
              type="linear"
              dataKey="max"
              stroke={Configuration.colors.charts.deviceChart.max}
              connectNulls={true}
              strokeWidth={4}
            />
            <Line
              type="linear"
              dataKey="prediction"
              strokeDasharray="5 5"
              stroke={theme.palette.secondary.main}
              strokeWidth={4}
              connectNulls={true}
              dot={{
                stroke: theme.palette.secondary.main,
                strokeWidth: 4,
                strokeDasharray: "0 0"
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </ChartContainer>
  );
};
ResourceVolumeChanges.displayName = "ResourceVolumeChanges";

export const ResourceVolumeChangesTooltip: React.FunctionComponent<TooltipProps> = props => {
  const { active } = props;
  if (active) {
    const { payload } = props;
    if (payload && payload.length >= 1) {
      const customizedPayload = payload[0] as any; // workaround to get time
      if (customizedPayload) {
        const {
          payload: { date, prediction, max, min, unit }
        } = customizedPayload;
        return (
          <TooltipContainer>
            <p>{date.format("MMMM Y")}</p>
            {prediction && !(max || min) && (
              <p>
                <i />
                {`Volume: ${prediction}${unit}`}
              </p>
            )}
            {max && (
              <MaxLabel>
                <i />
                {`Volume max: ${max}${unit}`}
              </MaxLabel>
            )}
            {min && (
              <MinLabel>
                <i />
                {`Volume min: ${min}${unit}`}
              </MinLabel>
            )}
          </TooltipContainer>
        );
      } else {
        return <TooltipContainer>No data</TooltipContainer>;
      }
    }
  }
  return null;
};
ResourceVolumeChangesTooltip.displayName = "ResourceVolumeChangesTooltip";
