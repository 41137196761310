import * as React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { blue, grey, red } from "@material-ui/core/colors";
import axios from "axios";
import { Configuration } from "./configuration/config";

export const theme = createMuiTheme({
  palette: {
    contrastThreshold: 3,
    error: red,
    primary: blue,
    secondary: {
      main: "#89849b",
      light: grey["400"],
      dark: grey["900"],
      contrastText: "#fff"
    },
    tonalOffset: 0.2
  }
});

export const fetchStyles = async () => {
  const hostname = window.location.hostname;
  try {
    const {
      data: { primaryTheme, secondaryTheme, appTitle }
    } = await axios.get(`/v1/edui/domain/${hostname}/settings.json`);
    if (primaryTheme) {
      theme.palette.primary = primaryTheme;
    }
    if (secondaryTheme) {
      theme.palette.secondary = secondaryTheme;
    }
    if (typeof appTitle === "string") {
      Configuration.companyName = appTitle;
    }
    await axios.head(`/v1/edui/domain/${hostname}/logo.png`);
    Configuration.logo = `/v1/edui/domain/${hostname}/logo.png`;
  } catch (e) {
    return;
  }
};

export function withThemeProvider<P>(Component: React.ComponentType<P>) {
  function WithTheme(props: P) {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }
  return WithTheme;
}

// Google maps font overriding fix
const head: any = document.getElementsByTagName("head")[0];
const insertBefore = head.insertBefore;
head.insertBefore = (newElement: any, referenceElement: any) => {
  if (
    newElement.href &&
    newElement.href.indexOf(
      "https://fonts.googleapis.com/css?family=Roboto"
    ) === 0
  ) {
    return;
  }

  insertBefore.call(head, newElement, referenceElement);
};
