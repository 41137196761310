import * as React from "react";
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps
} from "@material-ui/core/TextField";
import { Field, FieldProps, getIn } from "formik";
import styled from "styled-components";

export const TextField: React.ComponentType<FieldProps & MuiTextFieldProps> = ({
  field,
  form,
  disabled = false,
  ...props
}) => {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;
  const errorList = getIn(errors, name);
  const touchedList = getIn(touched, name);

  return (
    <MuiTextField
      {...field}
      {...props}
      error={touchedList && !!errorList}
      helperText={touchedList && errorList ? errorList : props.helperText}
      disabled={isSubmitting || disabled}
    />
  );
};
TextField.displayName = "FormikMaterialUITextField";

export const StyledTextField = styled(Field)`
  &&& {
    width: 100%;
    margin-bottom: 20px;
  }
`;
StyledTextField.displayName = "StyledTextField";
