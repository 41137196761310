import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Hidden, Button, Grid } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { withSnackbar, WithSnackbarProps } from "notistack";

import { withThemeProvider } from "../../core/withThemeProvider";
import {
  NoStyleLink,
  StyledBackground,
  StyledTitle,
  StyledTitleButtonGrid
} from "../../components/sharedStyledComponents";
import { IRootState } from "../../core/store";
import {
  getAlertCollectionByDeviceId,
  changePageAlertCollectionByDeviceId
} from "./actions/alertCollectionAction";
import { IDeviceAlertCollection, IDeviceAlert } from "./models/IAlerts";
import { AlertList } from "./components/AlertList";
import { AlertTable } from "./components/AlertTable";
import { StyledListIcon } from "../EditDevice";
import { IDeviceCollection, IDevice } from "../models/IDevice";
import { ITableDeleteCell } from "../../core/models/IShared";
import {
  deleteDeviceAlert,
  deviceAlertDeleteConfirmDialog
} from "./actions/alertAction";
import { DeviceAlertDeleteDialog } from "./components/DeviceAlertDeleteDialog";
import {
  snackbarMessages,
  getStyledSnackbarOptions,
  isSnackbarError,
  SnackbarError
} from "../../core/utilities/SnackbarUtilities";

export const StyledGrid = styled(Grid)`
  && {
    padding-top: 1%;
  }
`;
StyledGrid.displayName = "StyledGrid";

interface IPathParamsType {
  id: string;
}

interface IDevicesStateProps {
  alertCollection: IDeviceAlertCollection;
  deviceCollection: IDeviceCollection;
  deviceAlert: IDeviceAlert;
  device: IDevice;
}

interface IDevicesDispatchProps {
  getAlertCollection: (
    id: string
  ) => Promise<IDeviceAlertCollection | SnackbarError>;
  changePage: (
    id: string,
    page: string
  ) => Promise<IDeviceAlertCollection | SnackbarError>;
  deviceAlertDeleteConfirmDialog: (deviceAlertInfo: ITableDeleteCell) => void;
  removeDeviceAlert: (
    deviceId: string,
    deviceAlertInfo: ITableDeleteCell
  ) => Promise<SnackbarError>;
}

type DeviceAlertsProps = IDevicesStateProps &
  IDevicesDispatchProps &
  RouteComponentProps<IPathParamsType> &
  WithSnackbarProps;

export class DeviceAlerts extends React.Component<DeviceAlertsProps> {
  public fetchDeviceAlerts = async (page?: string) => {
    const {
      changePage,
      getAlertCollection,
      match: {
        params: { id }
      },
      enqueueSnackbar
    } = this.props;

    let collection: IDeviceAlertCollection | SnackbarError | undefined;
    if (page) {
      collection = await changePage(id, page);
    } else {
      collection = await getAlertCollection(id);
    }

    if (isSnackbarError(collection)) {
      enqueueSnackbar(collection.message, collection.options);
    }
  };

  public changePage = (page: string) => {
    this.fetchDeviceAlerts(page);
  };

  public componentDidMount() {
    this.fetchDeviceAlerts();
  }

  public handleDeviceAlertDeleteDialogOpen = (
    deviceAlertInfo: ITableDeleteCell
  ) => (_: React.MouseEvent) => {
    this.props.deviceAlertDeleteConfirmDialog(deviceAlertInfo);
  };

  public handleDeviceAlertDeleteDialogClose = (
    deviceAlertInfo: ITableDeleteCell
  ) => {
    this.props.deviceAlertDeleteConfirmDialog({ ...deviceAlertInfo, visible: false });
  };

  public handleDeleteDeviceAlert = async (
    deviceAlertInfo: ITableDeleteCell
  ) => {
    const {
      removeDeviceAlert,
      match: {
        params: { id }
      },
      enqueueSnackbar
    } = this.props;
    const deleteDeviceAlertResult = await removeDeviceAlert(
      id,
      deviceAlertInfo
    );

    if (isSnackbarError(deleteDeviceAlertResult)) {
      enqueueSnackbar(
        deleteDeviceAlertResult.message,
        deleteDeviceAlertResult.options
      );
    } else {
      enqueueSnackbar(
        snackbarMessages.DEVICE_ALERT_DELETE_SUCCESS,
        getStyledSnackbarOptions("success")
      );
    }
  };

  public render() {
    const {
      alertCollection: { isLoading, view, totalItems, members },
      match: {
        params: { id }
      },
      deviceAlert: { deviceAlertDelete, isLoading: deviceAlertIsLoading },
      device
    } = this.props;

    return (
      <React.Fragment>
        <StyledTitle
          container={true}
          alignItems="center"
          justify="space-between"
        >
          <Grid container={true}>
            <StyledGrid item={true} xs={8}>
              <StyledTitleButtonGrid item={true}>
                <Grid container={true} justify="flex-start">
                  Alerts for Device "{device.name || "..."}"
                </Grid>
              </StyledTitleButtonGrid>
            </StyledGrid>

            <Grid item={true} xs={4}>
              <StyledTitleButtonGrid item={true}>
                <Grid container={true} justify="flex-end">
                  <NoStyleLink to={`/devices/${id}/alerts/create`}>
                    <Button color="default">
                      <AddIcon />
                      Create
                    </Button>
                  </NoStyleLink>
                </Grid>
              </StyledTitleButtonGrid>
            </Grid>
          </Grid>
        </StyledTitle>

        <StyledBackground padding="10px 15px 0" overflow="auto">
          <Hidden smDown={true}>
            <AlertTable
              isLoading={isLoading}
              rows={members}
              pagination={view}
              totalItems={totalItems}
              onChangePage={this.changePage}
              handleDialogOpen={this.handleDeviceAlertDeleteDialogOpen}
              device={device}
            />
          </Hidden>
          <Hidden mdUp={true}>
            <Grid container={true} justify="flex-end">
              <NoStyleLink to="/devices">
                <Button color="default">
                  <StyledListIcon />
                  List
                </Button>
              </NoStyleLink>
            </Grid>
            <AlertList
              isLoading={isLoading}
              rows={members}
              pagination={view}
              totalItems={totalItems}
              onChangePage={this.changePage}
              handleDialogOpen={this.handleDeviceAlertDeleteDialogOpen}
              device={device}
            />
          </Hidden>
        </StyledBackground>
        <DeviceAlertDeleteDialog
          deviceAlertInfo={deviceAlertDelete}
          handleDeleteDialogClose={this.handleDeviceAlertDeleteDialogClose}
          handleDeleteDeviceAlert={this.handleDeleteDeviceAlert}
          isLoading={deviceAlertIsLoading}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IDevicesStateProps => ({
  alertCollection: state.deviceAlertCollection,
  deviceCollection: state.deviceCollection,
  deviceAlert: state.deviceAlert,
  device: state.device
});

const mapDispatchToProps = (dispatch: any): IDevicesDispatchProps => {
  return {
    getAlertCollection: (
      id: string
    ): Promise<IDeviceAlertCollection | SnackbarError> => {
      return dispatch(getAlertCollectionByDeviceId(id));
    },
    changePage: (
      id: string
    ): Promise<IDeviceAlertCollection | SnackbarError> => {
      return dispatch(changePageAlertCollectionByDeviceId(id));
    },
    deviceAlertDeleteConfirmDialog: (deviceAlertInfo: ITableDeleteCell) => {
      return dispatch(deviceAlertDeleteConfirmDialog(deviceAlertInfo));
    },
    removeDeviceAlert: (
      deviceId: string,
      deviceAlertInfo: ITableDeleteCell
    ): Promise<SnackbarError> => {
      return dispatch(deleteDeviceAlert(deviceId, deviceAlertInfo));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(DeviceAlerts)));
