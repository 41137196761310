import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Hidden, Button, Grid } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { withSnackbar, WithSnackbarProps } from "notistack";

import { withThemeProvider } from "../../core/withThemeProvider";
import {
  NoStyleLink,
  StyledBackground,
  StyledTitle,
  StyledTitleButtonGrid
} from "../../components/sharedStyledComponents";
import { IRootState } from "../../core/store";
import {
  getDownlinkCollectionByDeviceId,
  changePageDownlinkCollectionByDeviceId,
  clearDeviceDownlinkCollection
} from "./actions/downlinkCollectionAction";
import {
  IDeviceDownlinkApi,
  IDeviceDownlinkCollection
} from "./models/IDownlinks";
import { DownlinkList } from "./components/DownlinkList";
import { DownlinkTable } from "./components/DownlinkTable";
import { StyledListIcon } from "../EditDevice";
import { IDevice } from "../models/IDevice";

import {
  getStyledSnackbarOptions,
  isSnackbarError,
  SnackbarError,
  snackbarMessages
} from "../../core/utilities/SnackbarUtilities";
import { deleteDownlinkById } from "./actions/downlinkAction";

export const StyledGrid = styled(Grid)`
  && {
    padding-top: 1%;
  }
`;
StyledGrid.displayName = "StyledGrid";

interface IPathParamsType {
  id: string;
}

interface IDeviceDownlinksStateProps {
  downlinkCollection: IDeviceDownlinkCollection;
  device: IDevice;
}

interface IDeviceDownlinksDispatchProps {
  getDownlinkCollection: (
    id: string
  ) => Promise<IDeviceDownlinkCollection | SnackbarError>;
  deleteDownlink: (
    downlink: IDeviceDownlinkApi
  ) => Promise<void | SnackbarError>;
  changePage: (
    id: string,
    page: string
  ) => Promise<IDeviceDownlinkCollection | SnackbarError>;
  clearDeviceDownlinkCollection: () => void;
}

type DeviceDownlinksProps = IDeviceDownlinksStateProps &
  IDeviceDownlinksDispatchProps &
  RouteComponentProps<IPathParamsType> &
  WithSnackbarProps;

export class DeviceDownlinks extends React.Component<DeviceDownlinksProps> {
  public fetchDeviceDownlinks = async (page?: string) => {
    const {
      getDownlinkCollection,
      changePage,
      match: {
        params: { id }
      },
      enqueueSnackbar
    } = this.props;

    let collection: IDeviceDownlinkCollection | SnackbarError | undefined;
    if (page) {
      collection = await changePage(id, page);
    } else {
      collection = await getDownlinkCollection(id);
    }

    if (isSnackbarError(collection)) {
      enqueueSnackbar(collection.message, collection.options);
    }
  };

  public changePage = (page: string) => {
    this.fetchDeviceDownlinks(page);
  };

  public componentDidMount() {
    this.fetchDeviceDownlinks();
  }

  public componentWillUnmount() {
    this.fetchDeviceDownlinks();
  }

  public deleteDownlink = async (id: string) => {
    const {
      deleteDownlink,
      downlinkCollection,
      enqueueSnackbar
    } = this.props;
    const downlink = downlinkCollection.__initialRequest.member
      .filter(({ "@id": downlinkId }) => downlinkId === id)
      .pop();
    if (downlink) {
      const response = await deleteDownlink({ ...downlink, status: "DELETED" });
      if (isSnackbarError(response)) {
        enqueueSnackbar(response.message, response.options);
      } else {
        enqueueSnackbar(
          snackbarMessages.DEVICE_DOWNLINK_DELETED,
          getStyledSnackbarOptions("success")
        );
      }
    } else {
      enqueueSnackbar(
        snackbarMessages.DEVICE_DOWNLINK_DELETE_MISSING_ID,
        getStyledSnackbarOptions("warning")
      );
    }
    this.fetchDeviceDownlinks();
    return;
  };

  public render() {
    const {
      downlinkCollection: { isLoading, view, totalItems, members },
      device,
      match: {
        params: { id }
      }
    } = this.props;

    return (
      <React.Fragment>
        <StyledTitle
          container={true}
          alignItems="center"
          justify="space-between"
        >
          <Grid container={true}>
            <StyledGrid item={true} xs={8}>
              <StyledTitleButtonGrid item={true}>
                <Grid container={true} justify="flex-start">
                  Downlinks for Device "{device.name || "..."}"
                </Grid>
              </StyledTitleButtonGrid>
            </StyledGrid>

            <Grid item={true} xs={4}>
              <StyledTitleButtonGrid item={true}>
                <Grid container={true} justify="flex-end">
                  <NoStyleLink to={`/devices/${id}/downlinks/send`}>
                    <Button color="default">
                      <AddIcon />
                      Send new downlink message
                    </Button>
                  </NoStyleLink>
                </Grid>
              </StyledTitleButtonGrid>
            </Grid>
          </Grid>
        </StyledTitle>

        <StyledBackground padding="10px 15px 0" overflow="auto">
          <Hidden smDown={true}>
            <DownlinkTable
              isLoading={isLoading}
              rows={members}
              pagination={view}
              totalItems={totalItems}
              onChangePage={this.changePage}
              deleteDownlink={this.deleteDownlink}
            />
          </Hidden>
          <Hidden mdUp={true}>
            <Grid container={true} justify="flex-end">
              <NoStyleLink to="/devices">
                <Button color="default">
                  <StyledListIcon />
                  List
                </Button>
              </NoStyleLink>
            </Grid>
            <DownlinkList
              isLoading={isLoading}
              rows={members}
              pagination={view}
              totalItems={totalItems}
              onChangePage={this.changePage}
              deleteDownlink={this.deleteDownlink}
            />
          </Hidden>
        </StyledBackground>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IDeviceDownlinksStateProps => ({
  downlinkCollection: state.deviceDownlinkCollection,
  device: state.device
});

const mapDispatchToProps = (dispatch: any): IDeviceDownlinksDispatchProps => {
  return {
    getDownlinkCollection: (
      id: string
    ): Promise<IDeviceDownlinkCollection | SnackbarError> => {
      return dispatch(getDownlinkCollectionByDeviceId(id));
    },
    changePage: (
      id: string,
      page: string
    ): Promise<IDeviceDownlinkCollection | SnackbarError> => {
      return dispatch(changePageDownlinkCollectionByDeviceId(id, page));
    },
    clearDeviceDownlinkCollection: () => {
      return dispatch(clearDeviceDownlinkCollection());
    },
    deleteDownlink: downlink => dispatch(deleteDownlinkById(downlink))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(DeviceDownlinks)));
