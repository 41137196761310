import React, { ChangeEvent, FunctionComponent, useState } from "react";
import styled from "styled-components";
import { Field, FieldArray, FormikProps } from "formik";
import {
  Grid,
  Button,
  Typography,
  LinearProgress,
  InputLabel,
  MenuItem,
  Card,
  CardContent,
  ListSubheader
} from "@material-ui/core";

import {
  IDeviceDownlink,
  initialDeviceDownlink,
  ISeparatedDownlinkFormat
} from "../models/IDownlinks";
import { IDevice, IDownlinkFormats } from "../../models/IDevice";
import { Select, StyledSelect } from "../../../components/Select";
import { StyledTextField, TextField } from "../../../components/TextField";
import { generateDownlinkDataMask } from "../services/DeviceDownlinksService";
import { Validators } from "../../../util/validators";
import {
  EditAlertContainer,
  Elevation
} from "../../DeviceAlerts/components/EditDeviceAlertForm";
import { CheckboxWithLabel } from "../../../components/CheckboxWithLabel";
import { AdvancedDownlinkFormArray } from "./DeviceDownlinkForm/AdvancedFormArray";
import { SimpleDownlinkFormArray } from "./DeviceDownlinkForm/SimpleFormArray";

export const StyledDownlinkFormat = styled(Typography)`
  && {
    display: inline;
    font-size: 15px;
    color: grey;
  }
`;
StyledDownlinkFormat.displayName = "StyledDownlinkFormat";

export const DownlinkCategory = styled(ListSubheader)`
  pointer-events: none;
`;
DownlinkCategory.displayName = "DownlinkCategory";

export const StyledDownlinkFormatTitle = styled(Typography)`
  && {
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 5px;
  }
`;
StyledDownlinkFormatTitle.displayName = "StyledDownlinkFormatTitle";

export const StyledCard = styled(Card)`
  && {
    border-radius: 2px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      1px 1px 1px 0px rgba(0, 0, 0, 0.14), -1px 1px 0px 0px rgba(0, 0, 0, 0.12);
  }
`;
StyledCard.displayName = "StyledCard";

export const StyledCardContent = styled(CardContent)`
  && {
    padding-bottom: 14px;
  }
`;
StyledCardContent.displayName = "StyledCardContent";

export const StyledGrid = styled(Grid)`
  && {
    padding-top: 34px;
    padding-left: inherit;
  }
`;
StyledGrid.displayName = "StyledGrid";

interface IEditDeviceDownlinkFormProps {
  device: IDevice;
  downlinkFormatInitial: (
    downlink: IDeviceDownlink,
    formats: ISeparatedDownlinkFormat[]
  ) => void;
}

type EditDeviceDownlinkFormType = FormikProps<IDeviceDownlink> &
  IEditDeviceDownlinkFormProps;

export const EditDeviceDownlinkForm: FunctionComponent<EditDeviceDownlinkFormType> = ({
  submitForm,
  isSubmitting,
  values,
  values: { isLoading, downlinkFormat },
  device: { downlinkFormats },
  setFieldValue,
  downlinkFormatInitial
}: EditDeviceDownlinkFormType) => {
  const [splitedFormat, setSplitedFormat] = useState<
    ISeparatedDownlinkFormat[]
  >([
    { formatField: "", mask: [], format: "", initialValue: "", hexLength: 0 }
  ]);

  const { downlinkFormatName } = Validators.editDeviceDownlinks;
  const handleDownlinkFormatName = ({
    target: { name, value }
  }: ChangeEvent<{ name: string; value: string }>) => {
    setFieldValue("downlinkFormat.name", value);
    const modelFormats = values.deviceModel?.template?.downlinkFormats;
    let allDownlinkFormats = downlinkFormats;
    if (modelFormats) {
      allDownlinkFormats = [...downlinkFormats, ...modelFormats];
    }
    const filteredFormat = allDownlinkFormats
      .filter(({ name: formatName }) => formatName === value)
      .pop() || { ...initialDeviceDownlink.downlinkFormat };

    setFieldValue("downlinkFormat.format", filteredFormat.format);
    if (filteredFormat.name !== downlinkFormat.name) {
      const formats = generateDownlinkDataMask(filteredFormat.format);
      setSplitedFormat(formats);
      downlinkFormatInitial(
        {
          ...values,
          data: {
            hex: ""
          },
          downlinkFormat: {
            ...downlinkFormat,
            name: value,
            format: filteredFormat.format
          },
          tmpHexs: formats.map(
            ({ initialValue, formatField, format, mask }) => ({
              initialValue,
              value: initialValue,
              mask,
              format,
              name: formatField
            })
          )
        },
        formats
      );
    }
  };

  const advancedModeChangeHandler = (
    { target: { name } }: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setFieldValue(
      "tmpHexs",
      values.tmpHexs.map(value => ({ ...value, value: value.initialValue }))
    );
    setFieldValue(name, checked);
  };

  return (
    <EditAlertContainer>
      <Elevation>
        <StyledTextField
          type="text"
          label="Device Network"
          name="network.name"
          disabled={true}
          component={TextField}
        />

        <StyledSelect>
          <InputLabel htmlFor="Downlink">Downlink Type</InputLabel>
          <Field
            name="downlinkFormat.name"
            label="Downlink"
            component={Select}
            onChange={handleDownlinkFormatName}
            validate={downlinkFormatName}
            disabled={isLoading || isSubmitting}
          >
            {values.deviceModel && (
              <DownlinkCategory>
                Device Model: {values.deviceModel?.name}
              </DownlinkCategory>
            )}
            {values.deviceModel?.template?.downlinkFormats
              ?.filter(
                ({ name: deviceModelFormatName }) =>
                  downlinkFormats.filter(
                    ({ name: deviceFormatName }) =>
                      deviceFormatName === deviceModelFormatName
                  ).length === 0
              )
              .map(({ name }) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            <DownlinkCategory>Device Specific</DownlinkCategory>
            {downlinkFormats &&
              downlinkFormats.map(({ name }: IDownlinkFormats) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
          </Field>
        </StyledSelect>
        <br />
        <Field
          color="primary"
          onChange={advancedModeChangeHandler}
          Label={{
            label: <span>Advanced</span>
          }}
          name="advanced"
          component={CheckboxWithLabel}
          disabled={isLoading || isSubmitting}
        />
        <br />
        {!values.advanced && downlinkFormat.name && (
          <FieldArray
            name="tmpHexs"
            render={SimpleDownlinkFormArray(
              splitedFormat,
              isLoading,
              isSubmitting
            )}
          />
        )}
        {values.advanced && downlinkFormat.name && (
          <FieldArray
            name="tmpHexs"
            render={AdvancedDownlinkFormArray(
              splitedFormat,
              isLoading,
              isSubmitting
            )}
          />
        )}

        {(isLoading || isSubmitting) && <LinearProgress />}
        <br />
        <Grid container={true} item={true} justify="flex-start">
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading || isSubmitting}
            onClick={submitForm}
          >
            Schedule downlink message
          </Button>
        </Grid>
      </Elevation>
    </EditAlertContainer>
  );
};
EditDeviceDownlinkForm.displayName = "EditDeviceDownlinkForm";
