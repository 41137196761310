import * as React from "react";
import styled from "styled-components";
import { theme } from "../core/withThemeProvider";

export const DepthIcon: React.FunctionComponent = props => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      style={{
        stroke: "none",
        strokeWidth: 1,
        fill: "none",
        fillRule: "evenodd"
      }}
    >
      <path
        d="M20.9454545,2.63545453 L20.9454545,2.62195044 C20.9454545,2.0499936 21.2205565,1.54229028 21.6456592,1.22394187 C21.8821155,1.04686582 22.1649813,0.92837536 22.4727273,0.889999986 M20.9454545,2.48633381 L20.9454545,22.6909091 L3.49090909,22.6909091 L3.49090909,2.83636364 L3.49090909,2.48633381 M1.96363636,0.886231365 C2.8246498,0.99359822 3.49090909,1.72808342 3.49090909,2.61818182 C3.49090909,2.69207135 3.48631782,2.76488853 3.477405,2.83636364"
        style={{
          strokeWidth: 0.872727273,
          stroke: "currentColor"
        }}
      />
      <rect
        style={{
          fill: "currentColor",
          width: 14.8363636,
          height: 6.54545455
        }}
        x="4.8"
        y="14.8363636"
      />
    </g>
  </svg>
);
DepthIcon.displayName = "DepthIcon";

export const StyledDepthIcon = styled(DepthIcon)`
  color: ${theme.palette.primary.main};
  width: 25px;
  height: 25px;
`;
StyledDepthIcon.displayName = "StyledDepthIcon";
