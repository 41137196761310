import * as React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { LinearProgress } from "@material-ui/core";

import { IDeviceChart } from "../../models/IDevice";
import { Configuration } from "../../../core/configuration/config";
import { theme } from "../../../core/withThemeProvider";
import { CustomizedTableCell } from "../DeviceTable";
import { DeviceTableTooltip } from "./DeviceTableChartTooltip";

interface IChartCell {
  measurements: IDeviceChart;
  hide: 1 | 0;
}

export const ChartCell: React.FunctionComponent<IChartCell> = ({
  measurements: {
    isLoading,
    values,
    thresholds: { critical, warning },
    domain: { maxThreshold, minThreshold }
  },
  hide
}: IChartCell) => {
  const units = values && values[0] ? values[0].unit : "";
  const {
    colors: {
      charts: { cartesianGrid, thresholdReferenceLine }
    }
  } = Configuration;
  return (
    <CustomizedTableCell textalign="center" hide={hide} width="320px">
      {isLoading ? (
        <LinearProgress />
      ) : (
        <AreaChart width={300} height={100} data={values}>
          <XAxis dataKey="time" />
          <YAxis
            domain={[
              dataMin => Math.min(0, dataMin, minThreshold || Infinity),
              dataMax => Math.max(2, dataMax, maxThreshold || -Infinity)
            ]}
          />
          <CartesianGrid stroke={cartesianGrid} />
          <Tooltip content={DeviceTableTooltip} />
          {warning.map((threshold, index) => (
            <ReferenceLine
              key={index}
              y={threshold}
              stroke={thresholdReferenceLine}
              strokeDasharray="6 6"
              strokeOpacity={0.5}
            />
          ))}
          {critical.map((threshold, index) => (
            <ReferenceLine
              key={index + warning.length}
              y={threshold}
              stroke={thresholdReferenceLine}
              strokeDasharray="15 5"
              strokeOpacity={0.6}
            />
          ))}
          <Area
            dot={true}
            type="monotone"
            dataKey="value"
            unit={units}
            stroke={theme.palette.primary.main}
            fillOpacity={0.2}
            fill={theme.palette.primary.main}
          />
          <Area
            dot={true}
            type="monotone"
            dataKey="minValue"
            unit={units}
            stroke={Configuration.colors.charts.deviceChart.min}
            fillOpacity={0.2}
            fill={Configuration.colors.charts.deviceChart.min}
          />
          <Area
            dot={true}
            type="monotone"
            dataKey="maxValue"
            unit={units}
            stroke={Configuration.colors.charts.deviceChart.max}
            fillOpacity={0.2}
            fill={Configuration.colors.charts.deviceChart.max}
          />
        </AreaChart>
      )}
    </CustomizedTableCell>
  );
};
