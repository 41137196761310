import React, { Fragment, FunctionComponent } from "react";
import { Grid, InputLabel, Typography } from "@material-ui/core";
import MaskedInput from "react-text-mask";
import styled from "styled-components";

import {
  IDeviceDownlink,
  IDownlinkParameter,
  ISeparatedDownlinkFormat
} from "../../models/IDownlinks";
import { StyledTextField, TextField } from "../../../../components/TextField";
import { Validators } from "../../../../util/validators";
import Alert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";
import { FormikProps } from "formik/dist/types";
import { ArrayHelpers } from "formik/dist/FieldArray";

export const DownlinkFormContainer = styled(Grid)`
  padding: 0 15px;
  && > h6 {
    margin-bottom: 20px;
  }
`;
DownlinkFormContainer.displayName = "DownlinkFormContainer";

export const StyledTextFieldInput = styled(StyledTextField)`
  && {
    > p {
      margin-top: -3px;
    }

    > div:before {
      border-bottom: unset;
      position: fixed;
    }

    > div:after {
      border-bottom: unset;
      position: fixed;
    }

    > div:hover {
      border-bottom: unset;
    }
  }
`;
StyledTextFieldInput.displayName = "StyledTextFieldInput";

export const StyledMaskedInput = styled(MaskedInput)`
  && {
    font-size: 19px;
    font-family: monospace;
    padding: unset;
    letter-spacing: 5px;
  }
`;
StyledMaskedInput.displayName = "StyledMaskedInput";

export const StyledInputLabel = styled(InputLabel)`
  && {
    font-size: 14px;
    display: inline;
  }
`;
StyledInputLabel.displayName = "StyledInputLabel";

export interface IDownlinkDataMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  mask: string[];
}

export const downlinkDataMask = ({
  mask,
  inputRef,
  ...other
}: IDownlinkDataMaskProps) => (
  <StyledMaskedInput
    {...other}
    ref={(ref: any) => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={mask}
    placeholderChar={"\u2015"}
    showMask={true}
  />
);

type AdvancedFormFieldArrayType = (
  splitedFormat: ISeparatedDownlinkFormat[],
  isLoading: boolean,
  isSubmitting: boolean
) => FunctionComponent<
  ArrayHelpers & {
    form: FormikProps<IDeviceDownlink>;
    name: string;
  }
>;

export const AdvancedDownlinkFormArray: AdvancedFormFieldArrayType = (
  splitedFormat,
  isLoading,
  isSubmitting
) => ({
  form: {
    values: {
      tmpHexs,
      downlinkFormat: { format }
    }
  }
}) => {
  return (
    <Fragment>
      <br />
      <Alert variant="outlined" severity="info">
        <AlertTitle>Downlink Format</AlertTitle>
        {format}
      </Alert>
      <br />

      <DownlinkFormContainer container={true}>
        <Typography variant="h6" gutterBottom={true}>
          Downlink Payload
        </Typography>
        {tmpHexs.length > 0 &&
          tmpHexs.map((hex: IDownlinkParameter, index: number) => (
            <Fragment key={index}>
              <Grid container={true}>
                <Grid item={true} xs={12} sm={4} md={4} lg={4} xl={2}>
                  <StyledInputLabel htmlFor="Downlink Data">
                    {hex.name}
                  </StyledInputLabel>
                </Grid>
                <Grid item={true} xs={12} sm={8} md={8} lg={8} xl={10}>
                  <StyledTextFieldInput
                    type="text"
                    name={`tmpHexs[${index}].value`}
                    disabled={isLoading || isSubmitting}
                    component={TextField}
                    validate={Validators.editDeviceDownlinks.downlinkData(
                      splitedFormat,
                      index
                    )}
                    InputProps={{
                      inputComponent: downlinkDataMask,
                      inputProps: { mask: hex.mask }
                    }}
                  />
                </Grid>
              </Grid>
            </Fragment>
          ))}
      </DownlinkFormContainer>
      <br />
    </Fragment>
  );
};
