import { ISubscription, ISubscriptionAPI } from "../models/ISubscription";
import { getShortId } from "../../core/utilities/ServiceUtilities";

export const subscriptionFromApi = (
  subscription: ISubscriptionAPI
): ISubscription => {
  return {
    id: subscription["@id"],
    name: subscription.name,
    owner: subscription.owner,
    shortId: getShortId(subscription["@id"]),
    type: subscription["@type"]
  };
};
