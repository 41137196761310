import axios, { AxiosResponse } from "axios";

import {
  Configuration,
  ediApiHeaders,
  elaApiHeaders
} from "../../core/configuration/config";
import { IDevice, IDeviceCollectionApi } from "../../Devices/models/IDevice";
import { ActionType } from "../../core/ActionTypes";
import {
  getBounds,
  getDeviceCollectionLocation,
  locationHistoryCollectionApiToGeolocationArray,
  locationHistoryCollectionFromApi
} from "../services/LocationService";
import { deviceCollectionFromApi } from "../../Devices/services/DeviceService";
import { Thunk } from "../../core/store";
import { locationReducerTypes } from "../reducers/locationReducer";
import {
  createBlockableDispatch,
  createErrorConsoleMessage,
  getShortId
} from "../../core/utilities/ServiceUtilities";
import { DevicePrimaryParameter, ILocation } from "../models/IGeolocation";
import {
  checkError,
  getStyledSnackbarOptions,
  SnackbarError,
  snackbarMessages
} from "../../core/utilities/SnackbarUtilities";
import { getTableMeasurements } from "../../Resources/services/InfluxService";
import {
  ILocationHistoryCollectionAPI,
  ILocationsHistoryQuery
} from "../models/ILocationsHistory";

export const getLocations: Thunk<locationReducerTypes> = (
  height: number,
  width: number
) => {
  return async (dispatch, _, opt): Promise<ILocation | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    blockableDispatch({
      type: ActionType.DEVICE_LOCATIONS_LOADING,
      payload: true
    });
    try {
      const {
        data: deviceDataCollection
      }: AxiosResponse<IDeviceCollectionApi> = await axios.get(
        Configuration.EdiAPIUrl + "/devices",
        ediApiHeaders
      );
      const deviceCollection = deviceCollectionFromApi(deviceDataCollection);
      const measurements = await getTableMeasurements(deviceCollection.members);
      deviceCollection.members.forEach(device => {
        device.measurements = {
          isLoading: false
        };
        if (device.template.parameters) {
          const parameters = device.template.parameters.map(parameter =>
            parameter.name.toLowerCase()
          );
          if (parameters.indexOf("level") >= 0) {
            device.measurements.primaryParameter = DevicePrimaryParameter.LEVEL;
          } else if (parameters.indexOf("pressure") >= 0) {
            device.measurements.primaryParameter =
              DevicePrimaryParameter.PRESSURE;
          } else if (parameters.indexOf("temperature") >= 0) {
            device.measurements.primaryParameter =
              DevicePrimaryParameter.TEMPERATURE;
          } else if (parameters.indexOf("turbidity") >= 0) {
            device.measurements.primaryParameter =
              DevicePrimaryParameter.TURBIDITY;
          } else if (parameters.indexOf("chargelevel") >= 0) {
            device.measurements.primaryParameter =
              DevicePrimaryParameter.CHARGE_LEVEL;
          }
        }
        measurements.forEach(measurement => {
          if (device.shortId === measurement.device) {
            device.measurements.values = measurement;
          }
        });
      });
      const location = getDeviceCollectionLocation(
        deviceCollection,
        height,
        width
      );
      blockableDispatch({
        type: ActionType.GET_DEVICE_LOCATIONS,
        payload: location
      });
      return location;
    } catch (err) {
      createErrorConsoleMessage(err, "getLocations", { height, width });
      return checkError(err);
    } finally {
      blockableDispatch({
        type: ActionType.DEVICE_LOCATIONS_LOADING,
        payload: false
      });
    }
  };
};

export const getDeviceLocationsHistory: Thunk<locationReducerTypes> = (
  query: ILocationsHistoryQuery,
  height: number,
  width: number
) => {
  return async (dispatch, _, opt): Promise<void | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    blockableDispatch({
      type: ActionType.DEVICE_LOCATIONS_HISTORY_LOADING,
      payload: query
    });
    try {
      let device = query.deviceId;
      let deviceCollection: IDevice[] = [];
      if (!device) {
        const {
          data: deviceCollectionResponse
        }: AxiosResponse<IDeviceCollectionApi> = await axios.get(
          Configuration.EdiAPIUrl + "/devices",
          ediApiHeaders
        );
        deviceCollection = deviceCollectionFromApi(deviceCollectionResponse)
          .members;
        if (deviceCollection.length === 0) {
          return {
            message: snackbarMessages.DEVICE_COLLECTION_EMPTY,
            options: getStyledSnackbarOptions("warning")
          };
        } else {
          device = deviceCollection[0].id;
        }
      }
      const {
        data: locationHistoryCollectionResponse
      }: AxiosResponse<ILocationHistoryCollectionAPI> = await axios.get(
        Configuration.ElaAPIUrl + "/historicallocations",
        {
          ...elaApiHeaders,
          params: {
            device,
            from: query.from.toISOString(),
            to: query.to.toISOString()
          }
        }
      );
      const {
        zoom,
        center: { lat, lng }
      } = getBounds(
        locationHistoryCollectionApiToGeolocationArray(
          locationHistoryCollectionResponse
        ),
        height,
        width
      );
      const locationHistoryCollection = locationHistoryCollectionFromApi(
        locationHistoryCollectionResponse,
        query,
        lng,
        lat,
        zoom
      );
      blockableDispatch({
        type: ActionType.GET_DEVICE_LOCATIONS_HISTORY,
        payload: {
          ...locationHistoryCollection,
          from: query.from,
          to: query.to,
          deviceId: device,
          deviceShortId: getShortId(device),
          radius: query.radius,
          timeout: query.timeout,
          devices: deviceCollection
        }
      });
    } catch (err) {
      createErrorConsoleMessage(err, "getDeviceLocationsHistory", {
        ...query,
        height,
        width
      });
      return checkError(err);
    }
    blockableDispatch({
      type: ActionType.DEVICE_LOCATIONS_HISTORY_LOADING,
      payload: false
    });
  };
};
