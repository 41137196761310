import * as React from "react";
import { Field, Form, FormikProps } from "formik";
import styled from "styled-components";
import {
  Grid,
  MenuItem,
  InputLabel,
  Button,
  Checkbox,
  ListItemText
} from "@material-ui/core";

import { IViewDeviceParameters } from "../models/IViewDeviceParameters";
import { Select, StyledSelect } from "../../../components/Select";
import { FormikDatePicker } from "../../../components/DatePicker";
import { ExportDatepicker } from "../../../ExportDevice/components/ExportDeviceForm";
import { Validators } from "../../../util/validators";
import { CheckboxWithLabel } from "../../../components/CheckboxWithLabel";
import { FormikDateTimePicker } from "../../../components/DateTimePicker";

interface IMaxWidthGrid {
  maxwidth?: number;
}
export const MaxWidthGrid = styled(Grid)<IMaxWidthGrid>`
  && {
    ${props => (props.maxwidth ? "max-width: " + props.maxwidth + "px" : "")};
    padding-right: 10px;
  }
`;
MaxWidthGrid.displayName = "MaxWidthGrid";

export const StretchedForm = styled(Form)`
  width: 100%;
  padding: 15px 10px 10px 20px;
`;
StretchedForm.displayName = "StretchedForm";

export const ViewDeviceParameterHeaderForm: React.FunctionComponent<FormikProps<
  IViewDeviceParameters
>> = ({
  submitForm,
  isSubmitting,
  values,
  setFieldValue,
  initialValues,
  errors
}: FormikProps<IViewDeviceParameters>) => {
  const {
    isLoading,
    parameter,
    parameterList,
    aggregationEnabled,
    aggregationIntervalList,
    aggregationFunction,
    aggregationFunctionList,
    from,
    showHours
  } = values;
  const renderMultiselect = (selected: string[]) =>
    aggregationFunctionList
      .filter(aggregation => selected.indexOf(aggregation.value) >= 0)
      .map(({ name }) => name)
      .join(", ");
  const SelectHandler = ({
    target: { value, name }
  }: React.ChangeEvent<HTMLSelectElement>) => {
    if (name === "aggregationInterval" || value.length > 0) {
      setFieldValue(name, value);
    }
  };
  const handleHoursChange = (e: React.SyntheticEvent) => {
    if (errors.from) {
      setFieldValue("from", initialValues.from);
    }
    if (errors.to) {
      setFieldValue("to", initialValues.to);
    }
  };
  return (
    <StretchedForm>
      <Grid container={true} direction="column">
        <Grid container={true} item={true} xs={12} direction="row">
          <MaxWidthGrid maxwidth={350} item={true} xs={12} sm={6}>
            <StyledSelect>
              <InputLabel htmlFor="parameter">Parameter</InputLabel>
              <Field
                name="parameter"
                component={Select}
                disabled={isSubmitting || isLoading}
              >
                {parameterList.map((param, index) => (
                  <MenuItem value={param} key={index}>
                    {param}
                  </MenuItem>
                ))}
              </Field>
            </StyledSelect>
          </MaxWidthGrid>
        </Grid>
        <Field
          color={"primary"}
          Label={{
            label: (
              <span>
                Select hours in <b>from</b> and <b>to</b>
              </span>
            )
          }}
          name="showHours"
          component={CheckboxWithLabel}
          onClick={handleHoursChange}
          disabled={isLoading || isSubmitting}
        />
        <Grid container={true} item={true} direction="row" xs={12}>
          <MaxWidthGrid maxwidth={350} item={true} xs={12} sm={6}>
            <ExportDatepicker
              name="from"
              label="From (including)"
              component={showHours ? FormikDateTimePicker : FormikDatePicker}
              disabled={isSubmitting || isLoading}
            />
          </MaxWidthGrid>
          <MaxWidthGrid maxwidth={350} item={true} xs={12} sm={6}>
            <ExportDatepicker
              name="to"
              label="To (excluding)"
              disabled={isSubmitting || isLoading}
              minDate={from.clone().add(1, "days")}
              minDateMessage="To Date should not be before or equal to From Date"
              component={showHours ? FormikDateTimePicker : FormikDatePicker}
            />
          </MaxWidthGrid>
        </Grid>
        <br />
        <Field
          color={"primary"}
          Label={{ label: "Aggregate results" }}
          name="aggregationEnabled"
          component={CheckboxWithLabel}
          disabled={isLoading || isSubmitting}
        />
        <Grid container={true} item={true} direction="row" xs={12}>
          <MaxWidthGrid maxwidth={350} item={true} xs={12} sm={6}>
            <StyledSelect>
              <InputLabel htmlFor="aggregationFunction">Function</InputLabel>
              <Field
                name="aggregationFunction"
                multiple={true}
                component={Select}
                renderValue={renderMultiselect}
                onChange={SelectHandler}
                disabled={isSubmitting || isLoading || !aggregationEnabled}
              >
                {aggregationFunctionList.map(({ name, value }, i) => (
                  <MenuItem value={value} key={i}>
                    <Checkbox
                      color="primary"
                      checked={aggregationFunction.indexOf(value) > -1}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Field>
            </StyledSelect>
          </MaxWidthGrid>
          <MaxWidthGrid maxwidth={350} item={true} xs={12} sm={6}>
            <StyledSelect>
              <InputLabel htmlFor="aggregationInterval">Every</InputLabel>
              <Field
                name="aggregationInterval"
                component={Select}
                onChange={SelectHandler}
                validate={
                  aggregationEnabled
                    ? Validators.viewDeviceParameters.required
                    : undefined
                }
                disabled={isSubmitting || isLoading || !aggregationEnabled}
              >
                {aggregationIntervalList.map(({ name }, i) => (
                  <MenuItem value={name} key={i}>
                    {name}
                  </MenuItem>
                ))}
              </Field>
            </StyledSelect>
          </MaxWidthGrid>
        </Grid>
      </Grid>
      <Grid
        container={true}
        item={true}
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={submitForm}
          disabled={isSubmitting || isLoading || parameter === ""}
        >
          Refresh
        </Button>
      </Grid>
    </StretchedForm>
  );
};
ViewDeviceParameterHeaderForm.displayName = "ViewDeviceParameterHeaderForm";
