import {
  IDevice,
  IDeviceApi,
  IDeviceChartMeasurementQuery,
  IDeviceCollection,
  IDeviceCollectionApi,
  IParameters,
  initialDevice,
  initialDeviceCollection,
  IDeviceTemplate
} from "../models/IDevice";
import { IDeviceModelCollectionApi } from "../models/IDeviceModels";
import { getShortId } from "../../core/utilities/ServiceUtilities";
import { Configuration } from "../../core/configuration/config";

export const deviceFromApi = (
  device: IDeviceApi,
  modelName?: string
): IDevice => {
  const result = { ...initialDevice };
  result.isLoading = false;
  result.id = device["@id"];
  result.shortId = getShortId(device["@id"]);
  result.type = device["@type"];
  result.name = device.name;
  result.serial = device.serial;
  result.networks = device.networks;
  result.owner = device.owner;
  result.subscription = device.subscription;
  result.note = device.note ? device.note : "";
  result.model = modelName ? modelName : "<INACCESSIBLE>";
  result.deviceModel = device.deviceModel;
  result.autoLocation = {
    ...device.autoLocation,
    geo: {
      latitude:
        device.autoLocation && device.autoLocation.geo.latitude
          ? device.autoLocation.geo.latitude.toString()
          : "",
      longitude:
        device.autoLocation && device.autoLocation.geo.longitude
          ? device.autoLocation.geo.longitude.toString()
          : ""
    }
  };
  result.manualLocation = {
    ...device.manualLocation,
    geo: {
      latitude:
        device.manualLocation && device.manualLocation.geo.latitude
          ? device.manualLocation.geo.latitude.toString()
          : "",
      longitude:
        device.manualLocation && device.manualLocation.geo.longitude
          ? device.manualLocation.geo.longitude.toString()
          : ""
    }
  };
  result.template = device.template;

  if (result.template.parameters && result.template.parameters.length > 0) {
    result.payloadFieldProperties = [];
    result.basicCalculationProperties = [];
    result.template.parameters.filter((property: IParameters) => {
      if (property.method) {
        switch (property.method.kind) {
          case "PayloadField":
            result.payloadFieldProperties = [
              ...result.payloadFieldProperties,
              property
            ];
            break;
          case "BasicCalculation":
            result.basicCalculationProperties = [
              ...result.basicCalculationProperties,
              property
            ];
            break;
          case "Value":
            result.valueParameters = [...result.valueParameters, property];
            break;
          default:
            break;
        }
      }

      return result;
    });

    result.template.parameters.forEach(parameter => {
      parameter.visible = false;
    });
  }

  result.downlinkFormats = device.template.downlinkFormats || [];
  result.weather = device.weather;
  return result;
};

export const deviceToApiFormat = (device: IDevice): IDeviceApi => {
  return {
    "@id": device.id,
    "@type": device.type,
    deviceModel: device.deviceModel,
    manualLocation: {
      ...device.manualLocation,
      geo: {
        latitude:
          device.manualLocation && device.manualLocation.geo.latitude
            ? device.manualLocation.geo.latitude.toString()
            : undefined,
        longitude:
          device.manualLocation && device.manualLocation.geo.longitude
            ? device.manualLocation.geo.longitude.toString()
            : undefined
      }
    },
    autoLocation: {
      ...device.autoLocation,
      geo: {
        latitude:
          device.autoLocation && device.autoLocation.geo.latitude
            ? device.autoLocation.geo.latitude.toString()
            : undefined,
        longitude:
          device.autoLocation && device.autoLocation.geo.longitude
            ? device.autoLocation.geo.longitude.toString()
            : undefined
      }
    },
    name: device.name,
    networks: device.networks,
    note: device.note,
    owner: device.owner,
    serial: device.serial,
    subscription: device.subscription,
    template: device.template,
    weather: device.weather
  };
};

export const deviceCollectionFromApi = (
  collection: IDeviceCollectionApi
): IDeviceCollection => {
  const result = { ...initialDeviceCollection };
  result.isLoading = false;
  result.id = collection["@id"];
  result.shortId = getShortId(collection["@id"]);
  result.type = collection["@type"];
  result.context = collection["@context"];
  result.totalItems = collection.totalItems;
  result.members = collection.member.map(apiDevice => deviceFromApi(apiDevice));
  result.view = collection.view;
  return result;
};

export const addDeviceModelName = (
  device: IDevice,
  models: IDeviceModelCollectionApi
): IDevice => {
  const filteredModel = models.member.filter(
    model => model["@id"] === device.deviceModel
  );
  return {
    ...device,
    model: filteredModel.length === 1 ? filteredModel[0].name : device.model
  };
};

export const getDevicePrimaryParameter = (
  { parameters }: IDeviceTemplate,
  considerChargeLevel?: boolean
) => {
  if (parameters) {
    const params = [...Configuration.deviceMainMeasuredParameters].filter(
      parameter => {
        if (
          parameter.toLowerCase() === "chargelevel" &&
          considerChargeLevel === false
        ) {
          return false;
        }
        return parameter;
      }
    );

    for (const parameter of params) {
      const filteredParameter = parameters
        .filter(
          templateParameter =>
            templateParameter.name.toLowerCase() === parameter.toLowerCase()
        )
        .pop();
      if (filteredParameter) {
        return filteredParameter;
      }
    }
    return;
  }
};
export const splitDevicesByParameter = (
  collection: IDeviceCollection,
  period: string
): IDeviceChartMeasurementQuery[] => {
  const result: IDeviceChartMeasurementQuery[] = [];
  collection.members.forEach(device => {
    const primaryParameter = getDevicePrimaryParameter(device.template);
    if (primaryParameter) {
      result.push({
        deviceId: device.shortId || device.id,
        period,
        property: primaryParameter.name,
        unit: primaryParameter.unitText,
        unitCode: primaryParameter.unitCode
      });
    }
  });
  return result;
};

export const parameterExists = (
  property: string,
  parameters?: IParameters[]
): boolean => {
  if (parameters) {
    return (
      parameters.filter(parameter => parameter.name === property).length > 0
    );
  }
  return false;
};
export const chartUnitToValueType = (
  name: string,
  property?: string
): string => {
  const prefix =
    name === "minValue" ? "Min " : name === "maxValue" ? "Max " : "";
  return property ? prefix + property : name;
};
