import * as React from "react";
import { TableRow, TableHead } from "@material-ui/core";

import { CustomizedTableCell } from "../DeviceTable";
import { TableLoadingIndicator } from "../../../components/TableStyles";

interface IDeviceTableHead {
  hide: boolean;
  isLoading?: boolean;
}
export const DeviceTableHead: React.FunctionComponent<IDeviceTableHead> = (
  props
) => {
  const { hide, isLoading } = props;
  return (
    <TableHead>
      <TableRow>
        <CustomizedTableCell header={1} width="150px">
          Name & Serial
        </CustomizedTableCell>
        <CustomizedTableCell width="70px" textalign="center" header={1}>
          Last Seen
        </CustomizedTableCell>
        <CustomizedTableCell
          header={1}
          textalign="center"
          hide={hide ? 1 : 0}
          width="320px">
          Main parameter min/max
        </CustomizedTableCell>
        <CustomizedTableCell width="70px" textalign="center" header={1} />
        <CustomizedTableCell width="50px" textalign="center" header={1} />
        <CustomizedTableCell width="50px" textalign="center" header={1} />
        <CustomizedTableCell textalign="center" header={1} hide={hide ? 1 : 0}>
          Note
        </CustomizedTableCell>
        <CustomizedTableCell
          width="100px"
          header={1}
          textalign="center"
          type="button"
          hide={hide ? 1 : 0}
        />
        <CustomizedTableCell
          width="120px"
          header={1}
          textalign="center"
          type="button"
          hide={hide ? 1 : 0}
        />
      </TableRow>
      {isLoading && <TableLoadingIndicator colspan={9} />}
    </TableHead>
  );
};
