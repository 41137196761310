import * as React from "react";
import styled from "styled-components";
import { Grid, Tooltip } from "@material-ui/core";

import { StyledVolumeIcon } from "../../../components/VolumeIcon";
import { NotAvailable } from "../../../components/MeasurementNA";
import { growthPercent } from "../../services/ResourceService";
import { StyledProgress } from "../../Resources";
import { ILoadable } from "../../../core/models/MixinInterfaces";
import { VolumeTooltipText } from "./ResourceVolumePrediction";
import {
  ResourceVolumeDrop,
  ResourceVolumeRise
} from "../ResourceVolumeHistory";

interface IResourceVolumeValues extends ILoadable {
  volume?: number;
  volumeUnit?: string;
  volumePercent?: number;
  volumePercentUnit?: string;
  volumeLastYear?: number;
  volumeLastYearUnit?: string;
}

export const ResourceVolumeValuesGrid = styled(Grid)`
  && {
    padding-bottom: 40px;
  }
`;
ResourceVolumeValuesGrid.displayName = "ResourceVolumeValuesGrid";

export const ResourceVolumeValuesIcon = styled(StyledVolumeIcon)`
  height: 100px;
`;
ResourceVolumeValuesIcon.displayName = "ResourceVolumeValuesIcon";

export const ResourceVolumeValuesMeasurement = styled.div`
  color: black;
  font-size: 32px;
  font-weight: lighter;
  align-items: baseline;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 225px;
  & > span {
    padding-left: 10px;
    font-size: 27px;
  }
`;
ResourceVolumeValuesMeasurement.displayName = "ResourceVolumeValuesMeasurement";

export const ResourceVolumeValuesCaption = styled.div`
  font-size: 12px;
  color: #8e8e8e;
  text-align: center;
  padding-left: 20px;
`;
ResourceVolumeValuesCaption.displayName = "ResourceVolumeValuesCaption";

export const ResourceVolumeValues: React.FunctionComponent<IResourceVolumeValues> = props => {
  const {
    volume,
    volumeUnit,
    volumePercent,
    volumePercentUnit,
    volumeLastYear,
    volumeLastYearUnit,
    isLoading
  } = props;
  const changes = growthPercent(volume, volumeLastYear);
  return (
    <ResourceVolumeValuesGrid
      container={true}
      item={true}
      direction="row"
      alignItems="center"
      justify="center"
    >
      <ResourceVolumeValuesIcon percent={volumePercent} />
      <ResourceVolumeValuesCaption>
        {isLoading && <StyledProgress margin="0 20px" />}
        {!isLoading && (
          <Tooltip
            title={
              <VolumeTooltipText>
                {volumePercent
                  ? `${volumePercent} ${volumePercentUnit}`
                  : volume
                  ? `${volume} ${volumeUnit}`
                  : "No Data"}
              </VolumeTooltipText>
            }
          >
            <ResourceVolumeValuesMeasurement>
              <NotAvailable fontSize="40px" property={volumePercent || volume}>
                {volumePercent ? volumePercent : volume}
                <span>{volumePercent ? volumePercentUnit : volumeUnit}</span>
              </NotAvailable>
            </ResourceVolumeValuesMeasurement>
          </Tooltip>
        )}
        {!isLoading && changes && (
          <span>
            {changes.rise && <ResourceVolumeRise />}
            {changes.drop && <ResourceVolumeDrop />}
            {changes.percent}% Last Year(
            {volumeLastYear}
            {volumeLastYearUnit})
          </span>
        )}
      </ResourceVolumeValuesCaption>
    </ResourceVolumeValuesGrid>
  );
};
ResourceVolumeValues.displayName = "ResourceVolumeValues";
