import { history } from "../configuration/history";

export const blocker = (_: any) => (next: any) => (action: any) => {
  if (action.id) {
    if (action.id === history.location.key) {
      return next(action);
    }
  } else {
    return next(action);
  }
};
