import React, { useEffect, useState } from "react";
import { FeatureCollection, Point, LineString } from "geojson";
import DeckGL from "@deck.gl/react";
import { StaticMap } from "react-map-gl";
import styled from "styled-components";

import {
  IDeckGLViewport,
  IGeoJSONPathProperties,
  IGeoJSONPointProperties
} from "../models/ILocationsHistory";
import { Configuration } from "../../core/configuration/config";
import {
  getMaxTime,
  getPathBeginningIconLayer,
  getPathLayer,
  getScatterplotLayer,
  getTripsLayer
} from "../services/LocationService";
import { useAnimationFrame } from "../../core/lifecycle/animateEffect";

// tslint:disable-next-line
const { MapView }: any = require("@deck.gl/core");

export interface ITripsMapProperties {
  points: FeatureCollection<Point, IGeoJSONPointProperties>;
  trips: FeatureCollection<LineString, IGeoJSONPathProperties>;
  isLoading: boolean;
  viewport: IDeckGLViewport;
}

export interface IDeckGLTooltip {
  x: number;
  y: number;
  item?: any;
  message: string;
}

export const DeckGLTooltip = styled.div`
  position: absolute;
  z-index: 1;
  pointer-events: none;
  background: black;
  color: white;
  padding: 10px;
`;
DeckGLTooltip.displayName = "PointTooltip";

export const TripsMap: React.FunctionComponent<ITripsMapProperties> = ({
  children,
  viewport: initialViewport,
  points,
  trips,
  isLoading
}) => {
  const [pointTooltip, setPointTooltip] = useState<IDeckGLTooltip>({
    x: 0,
    y: 0,
    message: ""
  });

  const [pathTooltip, setPathTooltip] = useState<IDeckGLTooltip>({
    x: 0,
    y: 0,
    message: ""
  });

  const [viewport, setViewport] = useState({
    viewport: initialViewport,
    initialViewport
  });

  const [frame, setFrame] = useState(0);
  useAnimationFrame((deltaTime, maxTime) => {
    setFrame(prevFrame =>
      Math.round(
        (prevFrame +
          deltaTime * Configuration.geo.trips.animationSpeedMultiplier) %
          maxTime
      )
    );
  }, getMaxTime(trips));

  useEffect(() => {
    if (
      !isLoading &&
      (initialViewport.zoom !== viewport.initialViewport.zoom ||
        initialViewport.latitude !== viewport.initialViewport.latitude ||
        initialViewport.longitude !== viewport.initialViewport.longitude)
    ) {
      setFrame(0);
      setViewport({
        viewport: initialViewport,
        initialViewport
      });
    }
  }, [initialViewport, viewport, isLoading]);

  const stateChangeHandler = (v: any) => {
    setViewport({
      viewport: { ...viewport, ...v.viewState },
      initialViewport: viewport.initialViewport
    });
  };

  return (
    <DeckGL
      effects={[]}
      width="100%"
      height="100%"
      viewState={viewport.viewport}
      onViewStateChange={stateChangeHandler}
      layers={[
        getPathLayer(trips, setPathTooltip),
        getScatterplotLayer(points, setPointTooltip),
        getTripsLayer(trips, frame),
        getPathBeginningIconLayer(trips)
      ]}
      controller={true}
    >
      <MapView id="map" width="100%" height="100%" controller={true}>
        <StaticMap
          width="100%"
          height="100%"
          mapboxApiAccessToken={Configuration.geo.mapboxKey}
        />
      </MapView>
      {pointTooltip.item && (
        // using passed props for styled component results in flickering because of slow rendering speed.
        // Maybe because of DeckGL lazy loading?
        // possible solution - using style property directly.
        <DeckGLTooltip
          style={{ left: pointTooltip.x, top: pointTooltip.y }}
          key={frame}
        >
          {pointTooltip.message}
        </DeckGLTooltip>
      )}
      {pathTooltip.item && (
        <DeckGLTooltip style={{ left: pathTooltip.x, top: pathTooltip.y }}>
          {pathTooltip.message}
        </DeckGLTooltip>
      )}
      {children}
    </DeckGL>
  );
};
