import axios, { AxiosResponse } from "axios";

import { ActionType } from "../../core/ActionTypes";
import {
  IChartQueryResult,
  IDeviceCollection,
  IDeviceCollectionApi
} from "../models/IDevice";
import {
  Configuration,
  ediApiHeaders,
  earaApiHeaders
} from "../../core/configuration/config";
import {
  deviceCollectionFromApi,
  splitDevicesByParameter
} from "../services/DeviceService";
import {
  getTableChartData,
  getTableMeasurements
} from "../../Resources/services/InfluxService";
import { Thunk } from "../../core/store";
import { deviceCollectionReducerTypes } from "../reducers/deviceCollectionReducer";
import {
  IDeviceAlertApi,
  IDeviceAlertCollectionApi
} from "../DeviceAlerts/models/IAlerts";
import { createErrorConsoleMessage } from "../../core/utilities/ServiceUtilities";
import {
  checkError,
  SnackbarError
} from "../../core/utilities/SnackbarUtilities";
import { IInfluxTableQuery } from "../../Resources/models/IResource";

export const getDeviceCollection: Thunk<deviceCollectionReducerTypes> = () => {
  return async (dispatch): Promise<IDeviceCollection | SnackbarError> => {
    dispatch({ type: ActionType.DEVICE_COLLECTION_LOADING, payload: true });
    try {
      const response: AxiosResponse<IDeviceCollectionApi> = await axios.get(
        Configuration.EdiAPIUrl + "/devices",
        ediApiHeaders
      );
      const devices = deviceCollectionFromApi(response.data);
      dispatch({ type: ActionType.GET_DEVICE_COLLECTION, payload: devices });
      return devices;
    } catch (err) {
      createErrorConsoleMessage(err, "getDeviceCollection");
      return checkError(err);
    } finally {
      dispatch({ type: ActionType.DEVICE_COLLECTION_LOADING, payload: false });
    }
  };
};

export const changeDeviceCollectionPage: Thunk<deviceCollectionReducerTypes> = (
  page: string
) => {
  return async (dispatch): Promise<IDeviceCollection | SnackbarError> => {
    dispatch({ type: ActionType.DEVICE_COLLECTION_LOADING, payload: true });
    try {
      const response: AxiosResponse<IDeviceCollectionApi> = await axios.get(
        Configuration.APIBaseURL + page,
        {
          ...ediApiHeaders
        }
      );
      const devices: IDeviceCollection = deviceCollectionFromApi(response.data);
      dispatch({
        type: ActionType.GET_DEVICE_COLLECTION,
        payload: devices
      });
      return devices;
    } catch (err) {
      createErrorConsoleMessage(err, "changeDeviceCollectionPage", { page });
      return checkError(err);
    } finally {
      dispatch({ type: ActionType.DEVICE_COLLECTION_LOADING, payload: false });
    }
  };
};

export const changeMeasurementPeriod = (
  measurementPeriod: string
): deviceCollectionReducerTypes => {
  return {
    type: ActionType.CHANGE_DEVICE_COLLECTION_MEASUREMENT_PERIOD,
    payload: measurementPeriod
  };
};

export const getCollectionMeasurements: Thunk<deviceCollectionReducerTypes> = (
  collection: IDeviceCollection
) => {
  return async (dispatch): Promise<IInfluxTableQuery[] | SnackbarError> => {
    dispatch({
      type: ActionType.COLLECTION_MEASUREMENTS_LOADING,
      payload: true
    });
    try {
      const measurements = await getTableMeasurements(collection.members);
      dispatch({
        type: ActionType.GET_DEVICE_COLLECTION_MEASUREMENTS,
        payload: measurements
      });
      return measurements;
    } catch (err) {
      createErrorConsoleMessage(err, "getCollectionMeasurements", {
        collection
      });
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.COLLECTION_MEASUREMENTS_LOADING,
        payload: false
      });
    }
  };
};

export const getCollectionMeasurementsChart: Thunk<deviceCollectionReducerTypes> = (
  deviceCollection: IDeviceCollection,
  property: string,
  measurementPeriod?: string
) => {
  return async (dispatch): Promise<IChartQueryResult[] | SnackbarError> => {
    dispatch({ type: ActionType.COLLECTION_CHART_LOADING, payload: true });
    try {
      const result = await Promise.all(
        splitDevicesByParameter(
          deviceCollection,
          measurementPeriod || "24h"
        ).map(query => getTableChartData(query))
      );
      const charts = ([] as IChartQueryResult[]).concat(...result);
      dispatch({
        type: ActionType.GET_DEVICE_COLLECTION_CHART,
        payload: charts
      });
      return charts;
    } catch (err) {
      createErrorConsoleMessage(err, "getCollectionMeasurementsChart", {
        deviceCollection,
        measurementPeriod
      });
      return checkError(err);
    } finally {
      dispatch({ type: ActionType.COLLECTION_CHART_LOADING, payload: false });
    }
  };
};

export const getChartAlertLevels: Thunk<deviceCollectionReducerTypes> = (
  collection: IDeviceCollection
) => {
  return async (dispatch): Promise<IDeviceAlertApi[] | SnackbarError> => {
    try {
      const result: Array<AxiosResponse<
        IDeviceAlertCollectionApi
      >> = await Promise.all(
        collection.members.map(device =>
          axios.get(Configuration.EaraAPIUrl + "/alerts", {
            ...earaApiHeaders,
            params: {
              deviceId: Configuration.devicePrefixUrl + device.shortId
            }
          })
        )
      );

      const alerts: IDeviceAlertApi[] = [];
      result.forEach(alertCollection =>
        alerts.push(...alertCollection.data.member)
      );

      dispatch({
        type: ActionType.GET_COLLECTION_CHART_THRESHOLDS,
        payload: alerts
      });
      return alerts;
    } catch (err) {
      createErrorConsoleMessage(err, "getChartAlertLevels", { collection });

      if (err.response?.status === 403) {
        return;
      } else {
        return checkError(err);
      }
    }
  };
};
