import Axios from "axios";

import { IAddNewDevice } from "../models/IAddNewDevice";
import { Configuration, ediApiHeaders } from "../../core/configuration/config";
import {
  createErrorConsoleMessage,
  getShortId
} from "../../core/utilities/ServiceUtilities";
import { Thunk } from "../../core/store";
import { globalStateReducerTypes } from "../../reducers/globalStateReducer";
import { logOut } from "../../actions/globalStateAction";
import { IDevice, IDeviceApi } from "../../Devices/models/IDevice";
import {
  checkError,
  SnackbarError
} from "../../core/utilities/SnackbarUtilities";
import { deviceFromApi } from "../../Devices/services/DeviceService";

export const addNewDevice: Thunk<globalStateReducerTypes> = (
  newDevice: IAddNewDevice
) => {
  return async (
    dispatch,
    getState,
    { history }
  ): Promise<IDevice | SnackbarError> => {
    const {
      globalState: { user }
    } = getState();
    if (!user) {
      logOut();
    } else {
      try {
        const { data } = await Axios.post<IDeviceApi>(
          Configuration.EdiAPIUrl + "/devices/transfer",
          {
            "@context": "https://schema.ellenex.com/v1/context.jsonld",
            "@type": "Transfer",
            ...newDevice,
            toSubscription: "/v1/esma/subscriptions/" + user.subId
          },
          ediApiHeaders
        );
        const device = deviceFromApi(data);
        history.push("/devices/" + getShortId(device.id));
        return device;
      } catch (e) {
        createErrorConsoleMessage(e, "AddNewDevice", {
          params: {
            newDevice
          }
        });
        return checkError(e);
      }
    }
  };
};
