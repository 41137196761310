import { Configuration } from "../configuration/config";

export function createBlockableDispatch(
  dispatch: (action: any) => void,
  key?: string
) {
  return (action: any): void => {
    dispatch({
      ...action,
      id: key
    });
  };
}

export function createErrorConsoleMessage(
  error: any,
  method: string,
  parameters?: any
) {
  if (Configuration.development) {
    console.error({ error, method, parameters });
  } else {
    console.error({ error });
  }
}

export const getUnit = (propertyId?: string) => {
  if (propertyId) {
    const property = getShortId(propertyId);
    if (property) {
      if (property === "id-turbidity-1") {
        return Configuration.baseUnits.percent;
      }
      const unit = property.split("-").pop();
      if (unit) {
        return Configuration.baseUnits[unit];
      }
    }
  }
};
export const getShortId = (id: string): string => {
  if (id.indexOf("/") > -1) {
    return id.split("/").pop() as string;
  }
  return id;
};

export const limitDecimal = (
  value: number,
  fractionDigitsMax: number
): number => {
  try {
    return parseFloat(value.toFixed(fractionDigitsMax));
  } catch (e) {
    return value;
  }
};

export const mobileAppAvailable = (): boolean =>
  /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const deepLinkRedirect = (fallbackUrl: string) => {
  let deeplinkEndpoint = "";
  switch (fallbackUrl) {
    case "/devices":
      deeplinkEndpoint = "device-list";
      break;
    case "/devices/add":
      deeplinkEndpoint = "add-device";
      break;
    default:
      break;
  }
  const now = new Date().valueOf();
  setTimeout(() => {
    if (new Date().valueOf() - now > 100) {
      return;
    }
    window.location.assign(mobileAppAvailable() ? "/mobileapp" : fallbackUrl);
  }, 0);
  window.location.assign("ellenex://" + deeplinkEndpoint);
};

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const getPathName = (pathName: string): string =>
  pathName.split("/").pop() || "";

export const temperatureToCelsius = (value: number, unitCode?: string) => {
  const temperature = parseFloat(value.toString());
  if (!unitCode || isNaN(temperature)) {
    return value;
  }
  if (unitCode.toUpperCase() === "FAH") {
    return (temperature - 32) / 1.8;
  }
  return value;
};

export const volumeFillPercent = (percent?: number) => {
  if (
    !percent ||
    percent < 0 ||
    percent > 100 ||
    !parseFloat(percent.toString())
  ) {
    return "35%";
  }
  return 100 - parseFloat(percent.toString(10)) + "%";
};

export const splitCamelCase = (input: string) =>
  input.replace(/([a-z])([A-Z])/g, "$1 $2");

export const thousandsSeparator = (
  value: string,
  separator: string = (1000).toLocaleString().substring(1, 2)
) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);

export const getBreadcrumbName = (paramter: string, url: string): string => {
  const [preceding, following] = url.split(paramter);
  if (following === undefined) {
    return paramter;
  }
  const precedingItem = preceding
    .split("/")
    .filter(v => v !== "")
    .pop();
  if (precedingItem) {
    const matchedValue = Configuration.breadcrumbDetails
      .filter(detail => detail.name === precedingItem)
      .pop();
    if (matchedValue) {
      return matchedValue.value;
    }
  }
  return paramter.toLowerCase();
};
