import {
  IDevcieAlertParameterSelection,
  IDeviceAlert,
  IDeviceAlertApi,
  IDeviceAlertCollection,
  IDeviceAlertCollectionApi,
  IInterval,
  initialDeviceAlert,
  initialDeviceAlertCollection
} from "../models/IAlerts";
import { getShortId } from "../../../core/utilities/ServiceUtilities";
import {
  IChartAlert,
  IDevice,
  IDeviceCollection,
  initialDevice,
  IParameters,
  initialParameter,
  IDeviceTemplate,
  IDomain
} from "../../models/IDevice";
import { Configuration } from "../../../core/configuration/config";
import {
  IDeviceAlertTemplateApi,
  INotification,
  INotificationApi,
  IDeviceAlertNotificationApi
} from "../models/ITemplate";
import { store } from "../../../core/store";
import {
  getPhoneCode,
  getUserLocalePhoneCode
} from "../../../core/utilities/PhoneUtilities";
import { getDevicePrimaryParameter } from "../../services/DeviceService";

export const intervalToMinutes = (interval: IInterval): number => {
  return (
    parseInt(interval.value, 10) *
    Configuration.alert.timeMultipliers[interval.multiplier].value
  );
};

export const minutesToInterval = (minutes: number = 120): IInterval => {
  const { h, d } = Configuration.alert.timeMultipliers;
  if (minutes >= d.value) {
    return {
      value: Math.round(minutes / d.value).toString(),
      multiplier: "d"
    };
  } else if (minutes > h.value) {
    return {
      value: Math.round(minutes / h.value).toString(),
      multiplier: "h"
    };
  }
  return { value: minutes.toString(), multiplier: "m" };
};

export const deviceAlertFromApi = (alert: IDeviceAlertApi): IDeviceAlert => {
  const result = { ...initialDeviceAlert };

  result.context = alert["@context"];
  result.type = alert["@type"];
  result.id = alert["@id"];
  result.shortId = getShortId(alert["@id"]);
  result.name = alert.name;
  result.message = alert.message;
  result.device = getShortId(alert.device);
  result.deviceSerial = "";
  result.parameter = {
    label: alert.parameter,
    value: alert.parameter,
    unitText: getDeviceAlertParameterUnitText(alert.parameter)
  };
  result.subscription = alert.subscription;
  result.operator = alert.operator;
  result.threshold = alert.threshold;
  result.minimumRepeats = alert.minimumRepeats.toString() || "1";
  result.missedDataInterval = minutesToInterval(alert.missedDataInterval);
  result.alertLevel = alert.alertLevel;
  result.renotifyInterval = minutesToInterval(alert.renotifyInterval);
  result.notifyOnClearing =
    typeof alert.notifyOnClearing === "boolean"
      ? Boolean(alert.notifyOnClearing)
      : true;
  result.autoClear = Boolean(alert.autoClear);
  result.notificationTemplate = alert.notificationTemplate;
  result.tags = alert.tags;
  result.notification = [];
  result.notificationKind = [];
  result.thresholdDisabled = Boolean(alert.operator === "MISSED_DATA");
  result.missedDataDisabled = Boolean(alert.operator !== "MISSED_DATA");
  return result;
};

export const deviceAlertToApi = (alert: IDeviceAlert): IDeviceAlertApi => {
  return {
    "@context": "https://schema.ellenex.com/v1/context.jsonld",
    "@type": "Alert",
    "@id": alert.id,
    name: alert.name,
    message: alert.message,
    device: Configuration.devicePrefixUrl + alert.device,
    parameter: alert.parameter.value,
    subscription: alert.subscription,
    operator: alert.operator,
    threshold: alert.threshold,
    minimumRepeats: Number(alert.minimumRepeats),
    missedDataInterval: intervalToMinutes(alert.missedDataInterval),
    alertLevel: alert.alertLevel,
    renotifyInterval: intervalToMinutes(alert.renotifyInterval),
    notifyOnClearing: alert.notifyOnClearing,
    autoClear: alert.autoClear,
    notificationTemplate: alert.notificationTemplate,
    tags: alert.tags
  };
};

export const deviceAlertCollectionFromApi = (
  collection: IDeviceAlertCollectionApi
): IDeviceAlertCollection => {
  return {
    ...initialDeviceAlertCollection,
    context: collection["@context"],
    type: collection["@type"],
    id: collection["@id"],
    members: collection.member.map(alert => deviceAlertFromApi(alert)),
    shortId: getShortId(collection["@id"]),
    totalItems: collection.totalItems,
    view: collection.view
  };
};

export const getMaxMinThresholds = (thresholds: number[]): IDomain => {
  const sortedThresholds = thresholds.sort(
    (firstNum, secondNum) => firstNum - secondNum
  );
  return {
    maxThreshold: Math.max(...sortedThresholds),
    minThreshold: Math.min(...sortedThresholds)
  };
};

export const getLevelsFor = (
  deviceAlerts: IDeviceAlertApi[],
  parameter: string
) => {
  const filteredAlert = deviceAlerts.filter(
    deviceAlert =>
      deviceAlert.parameter.toUpperCase() === parameter.toUpperCase()
  );

  const criticalAlerts = filteredAlert.filter(
    item => item.alertLevel === "CRITICAL"
  );
  const warningAlerts = filteredAlert.filter(
    item => item.alertLevel === "WARNING"
  );

  return {
    critical: criticalAlerts.map(critical => Number(critical.threshold)),
    warning: warningAlerts.map(warning => Number(warning.threshold))
  };
};

export const getDeviceThresholds = (
  id: string,
  template: IDeviceTemplate,
  alerts: IDeviceAlertApi[]
) => {
  const deviceAlerts = alerts.filter(deviceAlert => deviceAlert.device === id);
  const primaryParameter = getDevicePrimaryParameter(template);
  const thresholds: IChartAlert = getLevelsFor(
    deviceAlerts,
    primaryParameter ? primaryParameter.name : ""
  );
  return {
    ...thresholds
  };
};

export const getDeviceOfAlert = (
  deviceId: string,
  deviceCollection: IDeviceCollection
): IDevice =>
  deviceCollection.members
    .filter(({ shortId }) => shortId === deviceId)
    .pop() || { ...initialDevice };

export const deviceAlertParameters = (
  basicCalculationProperties: IParameters[]
): IDevcieAlertParameterSelection[] => {
  return basicCalculationProperties.map(({ name, unitText }: IParameters) => {
    return {
      value: name.toLowerCase(),
      label: name,
      unitText
    };
  });
};

export const getDeviceAlertParameterName = (
  basicCalculationProperties: IParameters[],
  selectedParameter: string
): string => {
  const parameter: IParameters = basicCalculationProperties
    .filter(({ name }) => name.toLowerCase() === selectedParameter)
    .pop() || { ...initialParameter };
  return parameter.name;
};

export const getDeviceAlertParameterUnitText = (
  parameter: string,
  basicCalculations?: IParameters[]
): string => {
  const {
    device: { basicCalculationProperties }
  } = store.getState();
  const basicParameters: IParameters[] =
    basicCalculations || basicCalculationProperties;

  const result: IParameters = basicParameters
    .filter(({ name }) => name.toLowerCase() === parameter)
    .pop() || { ...initialParameter };
  return result.unitText;
};

export const deviceAlertTemplateToApi = (
  notifications: INotification[],
  subscription: string,
  templateId?: string
): IDeviceAlertTemplateApi => ({
  "@context": "https://schema.ellenex.com/v1/context.jsonld",
  "@type": "Template",
  "@id": templateId || "",
  name: "",
  defaultMessage: "",
  subscription,
  notifications: notifications.map(notification => {
    if (notification.kind === "EMAIL") {
      return {
        defaultMessage: notification.defaultMessage,
        emailAddress: notification.emailAddress,
        kind: notification.kind
      };
    } else {
      return {
        defaultMessage: notification.defaultMessage,
        mobileNumber:
          notification.countryCode + (notification.mobileNumber || ""),
        kind: notification.kind
      };
    }
  }),
  tags: { key: "" }
});

export const populateDeviceAlert = (
  deviceAlert: IDeviceAlert,
  name: string,
  serial: string
): IDeviceAlert => {
  const {
    device: { name: deviceName, serial: deviceSerial }
  } = store.getState();

  const populatedDeviceAlert: IDeviceAlert = {
    ...deviceAlert,
    device: deviceName || name,
    deviceSerial: deviceSerial || serial,
    notificationKind: []
  };

  if (populatedDeviceAlert.notification.length > 0) {
    populatedDeviceAlert.notification.forEach((item, index) => {
      populatedDeviceAlert.notificationKind[index] = item.kind;
    });
  }

  return populatedDeviceAlert;
};

export const splitNotificationPhoneNumber = (
  notification: INotificationApi
): INotification => {
  const notificationWithCode = {
    ...notification,
    timer: { count: 0, running: false, timerId: 0 }
  };
  const phoneCountry =
    notification.mobileNumber &&
    getPhoneCode(notification.mobileNumber.replace("+", ""));
  if (phoneCountry) {
    notificationWithCode.countryCode = phoneCountry.code;
    notificationWithCode.mobileNumber =
      notificationWithCode.mobileNumber &&
      notificationWithCode.mobileNumber
        .replace("+", "")
        .slice(phoneCountry.code.length);
  } else {
    notificationWithCode.countryCode = getUserLocalePhoneCode().code;
  }
  return notificationWithCode;
};

export const deviceAlertTestSMSNotificationToApi = (
  phone: string,
  subscription: string
): IDeviceAlertNotificationApi => ({
  "@context": "https://schema.ellenex.com/v1/context.jsonld",
  "@type": "Notification",
  kind: "SMS",
  shortMessage: "Test alert message from your device.",
  mobileNumber: phone,
  subscription
});
