import { Configuration } from "../../core/configuration/config";
import { ILoadable } from "../../core/models/MixinInterfaces";
export enum DevicePrimaryParameter {
  VOLUME,
  LEVEL,
  TEMPERATURE,
  CHARGE_LEVEL,
  PRESSURE,
  TURBIDITY
}

export interface IGeoClusterClick {
  cluster?: ICluster;
  showCluster?: (cluster?: ICluster) => any;
}
export interface IGeolocation {
  lat: number;
  lng: number;
  id?: string;
  name?: string;
  measurement?: string;
  primaryParameter?: DevicePrimaryParameter;
}

export interface IMapPosition {
  center: IGeolocation;
  zoom: number;
}

export interface ILocation extends ILoadable, IMapPosition {
  locations: IGeolocation[];
}

export const initialLocations: ILocation = {
  center: Configuration.geo.mapCenter,
  isLoading: true,
  locations: [],
  zoom: Configuration.geo.defaultZoom.noDevice
};

export interface ICluster {
  numPoints: number;
  points: IGeolocation[];
  wx: number;
  wy: number;
  x: number;
  y: number;
  zoom: number;
}
