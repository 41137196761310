import React from "react";
import moment from "moment";
import styled from "styled-components";
import { Button, TableRow } from "@material-ui/core";

import { CustomizedTableCell } from "../../../components/DeviceTable";
import { IDeviceDownlink } from "../../models/IDownlinks";
import { Configuration } from "../../../../core/configuration/config";
import { DeleteDialog } from "../DownlinkDeleteDialog";

interface IDeviceDownlinkTableRow {
  downlink: IDeviceDownlink;
  hide: boolean;
  deleteDownlink: (id: string) => Promise<void>;
}
export const ScrollableTableCell = styled(CustomizedTableCell)`
  &&& {
    overflow-x: auto;
    text-overflow: clip;
  }
`;
ScrollableTableCell.displayName = "ScrollableTableCell";

export const TableButton = styled(Button)`
  & svg {
    font-size: 18px;
    margin-left: 5px;
  }
`;
TableButton.displayName = "TableButton";

export const StyledTableRow = styled(TableRow)`
  && > td {
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
StyledTableRow.displayName = "StyledTableRow";

export const DeviceDownlinkTableRow: React.FunctionComponent<IDeviceDownlinkTableRow> = ({
  downlink: { id, status, network, data, dateCreated },
  deleteDownlink
}: IDeviceDownlinkTableRow) => (
  <StyledTableRow key={id}>
    <CustomizedTableCell textalign="center">
      {moment(dateCreated).format(Configuration.defaultDateFormat)}
      <br />
      {moment(dateCreated).format(Configuration.defaultTimeFormat)}
    </CustomizedTableCell>
    <CustomizedTableCell>{status}</CustomizedTableCell>
    <CustomizedTableCell>{network[`deviceNetworkId`]}</CustomizedTableCell>
    <ScrollableTableCell>{data[`hex`]}</ScrollableTableCell>
    <CustomizedTableCell textalign="center" type="button">
      {status === "PENDING" && (
        <DeleteDialog item={id} deleteAction={deleteDownlink}>
          Are you sure you want to delete this Downlink?
          <br />
          <b>Network:</b> {network[`deviceNetworkId`]}
          <br />
          <b>Data:</b> {data[`hex`]}
        </DeleteDialog>
      )}
    </CustomizedTableCell>
  </StyledTableRow>
);
