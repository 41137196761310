import { ActionType } from "../../core/ActionTypes";
import { IResource, initialResource } from "../models/IResource";

export type resourceReducerTypes =
  | { type: ActionType.GET_RESOURCE_MEASUREMENTS; payload: IResource }
  | { type: ActionType.INITIALIZE_RESOURCE_MEASUREMENTS; payload: IResource }
  | { type: ActionType.RESOURCE_TAB_STATE; payload: number }
  | { type: ActionType.REINITIALIZE };

export function resourceReducer(
  state: IResource = { ...initialResource },
  action: resourceReducerTypes
): IResource {
  switch (action.type) {
    case ActionType.REINITIALIZE:
      state = {
        ...initialResource
      };
      break;
    case ActionType.GET_RESOURCE_MEASUREMENTS:
      state = {
        ...action.payload,
        openedTab: state.openedTab
      };
      break;
    case ActionType.INITIALIZE_RESOURCE_MEASUREMENTS:
      state = {
        ...action.payload,
        openedTab: state.openedTab
      };
      break;
    case ActionType.RESOURCE_TAB_STATE:
      state = {
        ...state,
        openedTab: action.payload
      };
      break;
    default:
      break;
  }
  return state;
}
