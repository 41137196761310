import { IDeviceAlert, initialDeviceAlert } from "../models/IAlerts";
import { ActionType } from "../../../core/ActionTypes";
import { ITableDeleteCell } from "../../../core/models/IShared";

export type deviceAlertReducerType =
  | { type: ActionType.DEVICE_ALERT_LOADING; payload: boolean }
  | { type: ActionType.GET_EMPTY_DEVICE_ALERT; payload: IDeviceAlert }
  | { type: ActionType.CREATE_DEVICE_ALERT; payload: IDeviceAlert }
  | { type: ActionType.GET_DEVICE_ALERT; payload: IDeviceAlert }
  | { type: ActionType.UPDATE_DEVICE_ALERT; payload: IDeviceAlert }
  | { type: ActionType.CLEAR_DEVICE_ALERT }
  | { type: ActionType.DELETE_DEVICE_ALERT_DIALOG; payload: ITableDeleteCell }
  | { type: ActionType.SET_DEVICE_ALERT_FORM_VALUE; payload: IDeviceAlert }
  | { type: ActionType.SET_DEVICE_ALERT_NOTIFICATION_KIND; payload: number }
  | { type: ActionType.REINITIALIZE };

export function deviceAlertReducer(
  state: IDeviceAlert = { ...initialDeviceAlert },
  action: deviceAlertReducerType
): IDeviceAlert {
  switch (action.type) {
    case ActionType.REINITIALIZE:
      state = {
        ...initialDeviceAlert
      };
      break;
    case ActionType.DEVICE_ALERT_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
    case ActionType.GET_EMPTY_DEVICE_ALERT:
      state = {
        ...state,
        ...action.payload
      };
      break;
    case ActionType.GET_DEVICE_ALERT:
      state = {
        ...state,
        ...action.payload
      };
      break;
    case ActionType.UPDATE_DEVICE_ALERT:
      state = {
        ...state,
        ...action.payload
      };
      break;
    case ActionType.CLEAR_DEVICE_ALERT:
      state = {
        ...initialDeviceAlert
      };
      break;
    case ActionType.CREATE_DEVICE_ALERT:
      state = {
        ...state,
        ...action.payload
      };
      break;
    case ActionType.DELETE_DEVICE_ALERT_DIALOG:
      state = {
        ...state,
        deviceAlertDelete: action.payload
      };
      break;
    case ActionType.SET_DEVICE_ALERT_FORM_VALUE:
      state = {
        ...state,
        ...action.payload
      };
      break;
    case ActionType.SET_DEVICE_ALERT_NOTIFICATION_KIND:
      state = {
        notificationKind: [...state.notificationKind.splice(action.payload, 1)],
        ...state
      };
      break;
    default:
      break;
  }
  return state;
}
