import axios, { AxiosResponse } from "axios";

import { Configuration, ediApiHeaders } from "../../core/configuration/config";
import { IDeviceCollectionApi } from "../../Devices/models/IDevice";
import { ActionType } from "../../core/ActionTypes";
import { IExportDevice, initialExportDevice } from "../models/IExportDevice";
import {
  addDevicesForExport,
  saveExportToFile
} from "../services/ExportDeviceService";
import { deviceCollectionFromApi } from "../../Devices/services/DeviceService";
import { Thunk } from "../../core/store";
import { exportDeviceReducerTypes } from "../reducers/exportDeviceReducer";
import { createErrorConsoleMessage } from "../../core/utilities/ServiceUtilities";
import {
  checkError,
  SnackbarError
} from "../../core/utilities/SnackbarUtilities";

export const initializeExport: Thunk<exportDeviceReducerTypes> = () => {
  return async (dispatch): Promise<IExportDevice | SnackbarError> => {
    dispatch({ type: ActionType.EXPORT_INITIALIZING, payload: true });
    try {
      const response: AxiosResponse<IDeviceCollectionApi> = await axios.get(
        Configuration.EdiAPIUrl + "/devices",
        ediApiHeaders
      );
      const exportedDevices = addDevicesForExport(
        { ...initialExportDevice },
        deviceCollectionFromApi(response.data)
      );
      dispatch({
        type: ActionType.EXPORT_INITIALIZED,
        payload: exportedDevices
      });
      return exportedDevices;
    } catch (err) {
      createErrorConsoleMessage(err, "initializeExport");
      return checkError(err);
    } finally {
      dispatch({ type: ActionType.EXPORT_INITIALIZING, payload: false });
    }
  };
};

export const exportDevice: Thunk<exportDeviceReducerTypes> = (
  exportData: IExportDevice
) => {
  return async (dispatch): Promise<undefined | SnackbarError> => {
    dispatch({ type: ActionType.EXPORT_INITIALIZING, payload: true });
    try {
      const targetDevice = exportData.deviceList
        .filter(device => device.id === exportData.device.id)
        .pop();

      if (targetDevice) {
        dispatch({ type: ActionType.EXPORT_DEVICE, payload: exportData });

        const response: AxiosResponse<string> = await axios.post(
          Configuration.ExportAPIUrl,
          {
            device: targetDevice.shortId,
            from: exportData.from.toISOString(),
            to: exportData.to.toISOString(),
            format: exportData.fileFormat
          }
        );

        saveExportToFile(response.data, targetDevice.serial, targetDevice.name);
      }
    } catch (err) {
      createErrorConsoleMessage(err, "exportDevice", { exportData });
      return checkError(err);
    } finally {
      dispatch({ type: ActionType.EXPORT_INITIALIZING, payload: false });
    }
    return;
  };
};
