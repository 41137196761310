import React from "react";
import { LinearProgress } from "@material-ui/core";

import { CustomizedTableCell, IIconCell } from "../DeviceTable";
import {
  StyledLastSeenIconDanger,
  StyledLastSeenIconNormal
} from "../../../components/ThermometerIcon";
import { Configuration } from "../../../core/configuration/config";

export const LastSeenCell: React.FunctionComponent<IIconCell> = props => {
  const { isLoading, values } = props.measurements;
  const timeout = values?.lastSeen
    ? Configuration.threshold.time(values.lastSeen)
    : true;
  return (
    <CustomizedTableCell textalign="center">
      {isLoading ? (
        <LinearProgress />
      ) : (
        <React.Fragment>
          {values?.lastSeen && (
            <React.Fragment>
              {timeout && <StyledLastSeenIconDanger />}
              {!timeout && <StyledLastSeenIconNormal />}
              <br />
              <span>
                {values.lastSeen.format(Configuration.defaultDateFormat)}
              </span>
              <br />
              <span>
                {values.lastSeen.format(Configuration.defaultTimeFormat)}
              </span>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </CustomizedTableCell>
  );
};
