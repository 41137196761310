import * as React from "react";
import {
  Field,
  FieldArray,
  FieldArrayRenderProps,
  Form,
  FormikProps
} from "formik";
import styled, { keyframes } from "styled-components";
import {
  Grid,
  IconButton,
  Button,
  LinearProgress,
  Tooltip,
  FormHelperText,
  Chip,
  InputLabel,
  MenuItem,
  Typography
} from "@material-ui/core";
import {
  Delete as DeleteIcon,
  VisibilityOff,
  Add as AddIcon
} from "@material-ui/icons";

import { StyledTextField, TextField } from "../../components/TextField";
import { Validators } from "../../util/validators";
import { IDevice, IParameters, IParameterMethod } from "../models/IDevice";
import { Select, StyledSelect } from "../../components/Select";
import { Configuration } from "../../core/configuration/config";
import { IUnits } from "../../core/models/types";
import {
  ExpressionField,
  StyledExpressionField
} from "../../components/ExpressionField";

interface IActionButton {
  visibility: "hidden" | "visible";
}
export const ActionButton = styled(IconButton)<IActionButton>`
  && {
    height: 48px;
    color: #d3d3d3;
    visibility: ${props => props.visibility};
  }
`;
ActionButton.displayName = "ActionButton";

export const DeviceParameterFieldContainer = styled(Grid)`
  padding: 0 5px;
`;
export const PulseIn = keyframes`
  0% {
    transform: scale(0.7, 0.7);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    opacity: 1;
    filter: none;
  }
`;

export const AnimatedGrid = styled(Grid)`
  animation-name: ${PulseIn};
  animation-duration: 0.3s;
`;
AnimatedGrid.displayName = "AnimatedGrid";

export const PropertyChip = styled(Chip)`
  && {
    margin: 0 1vw 1vw 0;
    border-radius: 3px;
    animation-name: ${PulseIn};
    animation-duration: 0.3s;
  }
`;
PropertyChip.displayName = "PropertyChip";

export const DisabledPropertyChip = styled(PropertyChip)`
  && {
    background: rgba(224, 224, 224, 0.5);
    color: rgba(0, 0, 0, 0.5);
  }
`;
DisabledPropertyChip.displayName = "DisabledPropertyChip";

export const DeviceParameters: React.FunctionComponent<FormikProps<
  IDevice
>> = ({ setFieldValue, submitForm, isSubmitting, values }) => {
  if (!values.template.parameters) {
    values.template.parameters = [];
  }
  const showItem = (index: number) => {
    return () => {
      setFieldValue(`template.parameters[${index}].visible`, true);
    };
  };

  const composeChipLabel = (name: string, unitText?: string) =>
    name + (unitText ? ` (${unitText})` : "");

  const {
    isLoading,
    template: { parameters }
  } = values;
  return (
    <Form>
      {parameters.length > 0 && (
        <Typography variant="body1">Payload Parameters</Typography>
      )}
      {parameters &&
        parameters.map((parameter, index) => {
          if (
            !parameter.visible &&
            parameter.method &&
            parameter.method.kind === "PayloadField"
          ) {
            return (
              <DisabledPropertyChip
                key={index}
                label={composeChipLabel(parameter.name, parameter.unitText)}
              />
            );
          }
          return null;
        })}
      <FieldArray
        name="template.parameters"
        render={DeviceParametersFormArray(true)}
      />
      {parameters.length > 0 && (
        <Typography variant="body1">Calculated Parameters</Typography>
      )}
      {parameters &&
        parameters.map((parameter, index) => {
          if (
            !parameter.visible &&
            (!parameter.method || parameter.method.kind !== "PayloadField")
          ) {
            return (
              <Tooltip title="Expand" key={index}>
                <PropertyChip
                  onClick={showItem(index)}
                  label={composeChipLabel(parameter.name, parameter.unitText)}
                />
              </Tooltip>
            );
          }
          return null;
        })}
      <FieldArray
        name="template.parameters"
        render={DeviceParametersFormArray(false)}
      />
      {(isLoading || isSubmitting) && <LinearProgress />}
      <br />
      <Grid container={true} item={true} justify="flex-start">
        <Button
          variant="contained"
          color="primary"
          disabled={isLoading || isSubmitting}
          onClick={submitForm}
        >
          Save
        </Button>
      </Grid>
    </Form>
  );
};
DeviceParameters.displayName = "DeviceParameters";

export const DeviceParametersFormArray = (
  payloadFields: boolean
): React.FunctionComponent<FieldArrayRenderProps> => arrayHelpers => {
  const { form, push, remove } = arrayHelpers;

  // removing measured property from array of properties
  const removeProperty = (index: number) => () => remove(index);

  // hiding measured property from expanded parameters (collapsing into chip)
  const hideProperty = (index: number) => () =>
    form.setFieldValue(`template.parameters[${index}].visible`, false);

  // getting a field key which should be used as expression in form. Fields differ on method.kind
  const getFieldName = (index: number, method?: IParameterMethod) => {
    const start = "template.parameters[" + index + "].method.";
    if (!method) {
      return "template.parameters[" + index + "].text";
    }
    switch (method.kind) {
      case "BasicCalculation":
        return start + "expression";
      case "PayloadField":
        return start + "expression";
      case "Value":
        return start + "value";
      default:
        return start + "value";
    }
  };

  // creating custom custom empty property and adding it to array of rendered properties
  const addProperty = (e: any) => {
    e.preventDefault();
    push({
      "@type": "Parameter",
      "@id": `/parameters/${form.values.shortId}-${form.values.template
        .parameters.length + 2}-customparam`,
      name: "",
      visible: true,
      dataType: "Number",
      unitCode: "",
      unitText: "",
      featureOf: "",
      method: {
        "@type": "MeasurementMethod",
        kind: "BasicCalculation",
        expression: ""
      }
    });
  };

  const { name, expression } = Validators.editDeviceParameters;
  const {
    isLoading,
    template: { parameters }
  } = form.values;

  const handleUnitChange = (
    event: React.ChangeEvent<{ name?: string; value: string }>
  ) => {
    const { name: eventName, value } = event.target;
    const units = Configuration.units
      .filter(unit => unit.unitCode === value)
      .pop();
    if (eventName && units) {
      form.setFieldValue(eventName, units.unitCode);
      form.setFieldValue(
        eventName
          .split(".")
          .slice(0, -1)
          .join(".") + ".unitText",
        units.unitText
      );
    }
  };
  return (
    <Grid container={true} item={true} justify="flex-start">
      {parameters
        .filter((parameter: IParameters) => {
          const isPayloadField =
            parameter.method && parameter.method.kind === "PayloadField";
          return payloadFields ? isPayloadField : !isPayloadField;
        })
        .map((property: IParameters, index: number) => {
          const { visible } = property;
          const propertyIndex = parameters.indexOf(property);
          if (visible) {
            return (
              <AnimatedGrid
                item={true}
                container={true}
                direction="row"
                xs={12}
                alignItems="center"
                key={index}
              >
                <Grid item={true} container={true} direction="row" xs={true}>
                  <DeviceParameterFieldContainer item={true} xs={3}>
                    <StyledTextField
                      disabled={isLoading}
                      type="text"
                      label="Name"
                      validate={name(parameters, propertyIndex)}
                      name={`template.parameters[${propertyIndex}].name`}
                      component={TextField}
                    />
                  </DeviceParameterFieldContainer>
                  <DeviceParameterFieldContainer item={true} xs={6}>
                    <StyledExpressionField
                      name={getFieldName(propertyIndex, property.method)}
                      disabled={isLoading}
                      validate={expression}
                      label="Expression"
                      component={ExpressionField}
                      parameters={parameters}
                    />
                  </DeviceParameterFieldContainer>
                  <DeviceParameterFieldContainer item={true} xs={3}>
                    <StyledSelect>
                      <InputLabel htmlFor="device.id">Unit</InputLabel>
                      <Field
                        name={`template.parameters[${propertyIndex}].unitCode`}
                        label="Unit"
                        onChange={handleUnitChange}
                        component={Select}
                        disabled={isLoading}
                      >
                        {Configuration.units.map((unit: IUnits) => (
                          <MenuItem key={unit.unitCode} value={unit.unitCode}>
                            {unit.unitText}
                          </MenuItem>
                        ))}
                      </Field>
                    </StyledSelect>
                  </DeviceParameterFieldContainer>
                </Grid>
                <Tooltip title="Hide">
                  <ActionButton
                    visibility="visible"
                    onClick={hideProperty(propertyIndex)}
                    aria-label="Hide Property"
                  >
                    <VisibilityOff />
                  </ActionButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <ActionButton
                    visibility="visible"
                    onClick={removeProperty(propertyIndex)}
                    aria-label="Delete Property"
                  >
                    <DeleteIcon />
                  </ActionButton>
                </Tooltip>
              </AnimatedGrid>
            );
          }
          return null;
        })}
      {isLoading || payloadFields ? null : parameters.length <= 70 ? (
        <IconButton onClick={addProperty}>
          <AddIcon />
        </IconButton>
      ) : (
        <FormHelperText error={true}>70 parameters is maximum</FormHelperText>
      )}
    </Grid>
  );
};
DeviceParametersFormArray.displayName = "DeviceParametersFormArray";
