import {
  initialUserProfile,
  ISubscriptionWithRole,
  IUserProfile,
  IUserProfileAPI
} from "../models/IUserProfile";
import { getShortId } from "../../core/utilities/ServiceUtilities";
import { ISubscription } from "../../Subscriptions/models/ISubscription";
import { IRole } from "../../Roles/models/IRole";
import {
  getPhoneCode,
  getUserLocalePhoneCode,
  trimPhoneCountryCode
} from "../../core/utilities/PhoneUtilities";

export const profileFromApi = (profile: IUserProfileAPI): IUserProfile => {
  const result = { ...initialUserProfile };
  result.$$raw = profile;
  result.id = profile["@id"];
  result.type = profile["@type"];
  result.shortId = getShortId(profile["@id"]);
  result.email = profile.email;
  result.mobile = profile.mobile;
  result.name = profile.name;
  result.activeSubscription = profile.activeSubscription;
  result.activeRole = profile.activeRole;
  result.uiPreferences = profile.uiPreferences;
  result.status = profile.status;
  const phoneCountry = getPhoneCode(profile.mobile.replace("+", ""));
  if (phoneCountry) {
    result.countryCode = phoneCountry.code;
    result.mobile = trimPhoneCountryCode(result.mobile);
  } else if (result.mobile.length === 0) {
    result.countryCode = getUserLocalePhoneCode().code;
  }
  if (profile.notificationChannels && profile.notificationChannels.length > 0) {
    profile.notificationChannels.forEach((channel: string) => {
      switch (channel) {
        case "sms":
          result.smsNotification = true;
          break;
        case "email":
          result.emailNotification = true;
          break;
        default:
          break;
      }
    });
  }
  return result;
};

export const getSubscriptionsWithRoles = (
  hasRoles: { [subscriptoin: string]: string[] },
  subscriptions: ISubscription[],
  roles: IRole[]
): ISubscriptionWithRole[] =>
  Object.keys(hasRoles).map(subscriptionId => ({
    ...subscriptions.filter(sub => sub.id === subscriptionId)[0],
    roles: roles.filter(role => hasRoles[subscriptionId].indexOf(role.id) >= 0)
  }));

export const profileToApi = (profile: IUserProfile): IUserProfileAPI => {
  const notifications = [];
  if (profile.smsNotification) {
    notifications.push("sms");
  }
  if (profile.emailNotification) {
    notifications.push("email");
  }
  const hasRoles = {};
  profile.hasRoles.forEach(subWithRole => {
    hasRoles[subWithRole.id] = subWithRole.roles.map(role => role.id);
  });
  return {
    ...profile.$$raw,
    "@id": profile.id as string,
    "@type": profile.type as string,
    activeRole: profile.activeRole,
    activeSubscription: profile.activeSubscription,
    hasRoles,
    email: profile.email,
    mobile:
      profile.mobile.length > 0 ? profile.countryCode + profile.mobile : "",
    name: profile.name,
    notificationChannels: notifications,
    uiPreferences: profile.uiPreferences,
    status: profile.status
  };
};

export const getSubscriptionAndRoleName = (
  hasRoles: ISubscriptionWithRole[],
  activeSubscription: string,
  activeRole: string
): { subscription: string; role: string } => {
  const currentSub = hasRoles
    ? hasRoles
        .filter(subscription => subscription.id === activeSubscription)
        .pop()
    : undefined;
  const currentRole = currentSub
    ? currentSub.roles.filter(role => role.id === activeRole).pop()
    : undefined;
  return {
    subscription: currentSub ? currentSub.name : "",
    role: currentRole ? currentRole.name : ""
  };
};
