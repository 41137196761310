import React from "react";
import { connect } from "react-redux";
import { Button, Grid, RootRef } from "@material-ui/core";

import { withThemeProvider } from "../core/withThemeProvider";
import { IRootState } from "../core/store";
import { getDeviceLocationsHistory } from "./actions/locationAction";
import { Configuration } from "../core/configuration/config";
import { ILocation } from "./models/IGeolocation";
import {
  FullHeightBackground,
  StyledTitle
} from "../components/sharedStyledComponents";
import {
  isSnackbarError,
  SnackbarError
} from "../core/utilities/SnackbarUtilities";
import { withSnackbar, WithSnackbarProps } from "notistack";
import {
  ILocationsHistory,
  ILocationsHistoryQuery
} from "./models/ILocationsHistory";
import { Formik, FormikHelpers as FormikActions } from "formik";
import styled from "styled-components";
import { TripsMap } from "./components/TripsMap";
import { DeviceLocationsForm } from "./components/DeviceLocationsForm";

export enum backgroundColors { // todo move to config
  BLUE = "#2196F3",
  RED = "#f44336",
  GREEN = "#4CAF50",
  PURPLE = "#9C27B0"
}

export const BlueButton = styled(Button)`
  && {
    background-color: ${backgroundColors.BLUE};
    :hover {
      background-color: #1976d2;
    }
  }
`;
BlueButton.displayName = "BlueButton";

export const RedButton = styled(Button)`
  && {
    background-color: ${backgroundColors.RED};
    :hover {
      background-color: #d32f2f;
    }
  }
`;
RedButton.displayName = "RedButton";

export const GreenButton = styled(Button)`
  && {
    background-color: ${backgroundColors.GREEN};
    :hover {
      background-color: #388e3c;
    }
  }
`;
GreenButton.displayName = "GreenButton";

export const PurpleButton = styled(Button)`
  && {
    background-color: ${backgroundColors.PURPLE};
    :hover {
      background-color: #7b1fa2;
    }
  }
`;
PurpleButton.displayName = "PurpleButton";

export const LocationsHistoryHeader = styled(Grid)`
  && {
    margin: 15px;
  }
`;
LocationsHistoryHeader.displayName = "LocationsHistoryHeader";

export const MapControlContainer = styled(Grid)`
  height: 100%;
`;
MapControlContainer.displayName = "MapControlContainer";

export const TripSelector = styled(Grid)`
  height: calc(100% - 285px);
  overflow-y: scroll;
  width: 300px;
`;
TripSelector.displayName = "TripSelector";

export const LocationsHistoryMapWrapper = styled(Grid)`
  && {
    position: relative;
    height: calc(100% - 285px);
    flex: 1;
    display: flex;
  }
`;
LocationsHistoryMapWrapper.displayName = "LocationsHistoryMapWrapper";

interface IDevicesStateProps {
  globalMap: ILocation;
  deviceLocations: ILocationsHistory;
}

interface IDevicesDispatchProps {
  getLocationsHistory: (
    query: ILocationsHistoryQuery,
    height: number,
    width: number
  ) => Promise<void | SnackbarError>;
}

interface IDevicesState {
  popoverAnchorElement: any;
}

export class Location extends React.Component<
  IDevicesStateProps & IDevicesDispatchProps & WithSnackbarProps,
  IDevicesState
> {
  private container: React.RefObject<HTMLDivElement> = React.createRef();

  public async componentDidMount() {
    const {
      enqueueSnackbar,
      getLocationsHistory,
      deviceLocations: { from, to, radius, timeout }
    } = this.props;
    this.setState({ popoverAnchorElement: null });
    if (this.container.current) {
      const { clientHeight, clientWidth } = this.container.current;
      const locations = await getLocationsHistory(
        {
          from,
          to,
          radius,
          timeout
        },
        clientHeight,
        clientWidth
      );
      if (isSnackbarError(locations)) {
        enqueueSnackbar(locations.message, locations.options);
      }
    }
  }

  public onSubmit = async (
    values: ILocationsHistory,
    { setSubmitting }: FormikActions<ILocationsHistory>
  ) => {
    const { getLocationsHistory, enqueueSnackbar } = this.props;
    if (values && this.container.current) {
      const { clientHeight, clientWidth } = this.container.current;
      const locationsHistory = await getLocationsHistory(
        {
          deviceId: values.deviceId,
          deviceShortId: values.deviceShortId,
          radius: values.radius,
          timeout: values.timeout,
          from: values.from,
          to: values.to
        },
        clientHeight,
        clientWidth
      );
      if (isSnackbarError(locationsHistory)) {
        enqueueSnackbar(locationsHistory.message, locationsHistory.options);
      }
      setSubmitting(false);
    }
  };

  public openPopover = (event: React.MouseEvent) => {
    this.setState({ popoverAnchorElement: event.currentTarget });
  };

  public closePopover = () => {
    this.setState({ popoverAnchorElement: null });
  };

  public pickColor = (_: unknown) => () => {
    this.setState({ popoverAnchorElement: null });
  };

  public render() {
    const { deviceLocations } = this.props;
    return (
      <React.Fragment>
        {Configuration.isMobile ? <StyledTitle>Locations</StyledTitle> : ""}
        <FullHeightBackground component="div">
          <LocationsHistoryHeader
            item={true}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <Formik
              enableReinitialize={true}
              initialValues={deviceLocations}
              onSubmit={this.onSubmit}
            >
              {props => <DeviceLocationsForm {...props} />}
            </Formik>
          </LocationsHistoryHeader>
          <MapControlContainer container={true} item={true}>
            {/*<TripSelector>*/}
            {/*  <List>*/}
            {/*    <Divider />*/}
            {/*    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => {*/}
            {/*      return (*/}
            {/*        <React.Fragment>*/}
            {/*          <ListItem button key={index}>*/}
            {/*            <ListItemIcon>*/}
            {/*              <Checkbox color={"primary"} />*/}
            {/*            </ListItemIcon>*/}
            {/*            <ListItemText>Device {item}</ListItemText>*/}
            {/*            <ListItemSecondaryAction>*/}
            {/*              <IconButton onClick={this.openPopover}>*/}
            {/*                <PaletteIcon />*/}
            {/*              </IconButton>*/}
            {/*            </ListItemSecondaryAction>*/}
            {/*          </ListItem>*/}
            {/*          <Divider />*/}
            {/*        </React.Fragment>*/}
            {/*      );*/}
            {/*    })}*/}
            {/*  </List>*/}
            {/*  <Popover*/}
            {/*    open={this.state && this.state.popoverAnchorElement}*/}
            {/*    anchorEl={this.state ? this.state.popoverAnchorElement : null}*/}
            {/*    onClose={this.closePopover}*/}
            {/*    anchorOrigin={{*/}
            {/*      vertical: "top",*/}
            {/*      horizontal: "center"*/}
            {/*    }}*/}
            {/*    transformOrigin={{*/}
            {/*      vertical: "top",*/}
            {/*      horizontal: "center"*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <ButtonGroup>*/}
            {/*      <BlueButton onClick={this.pickColor(backgroundColors.BLUE)}>*/}
            {/*        &nbsp;*/}
            {/*      </BlueButton>*/}
            {/*      <RedButton>&nbsp;</RedButton>*/}
            {/*      <GreenButton>&nbsp;</GreenButton>*/}
            {/*      <PurpleButton>&nbsp;</PurpleButton>*/}
            {/*    </ButtonGroup>*/}
            {/*  </Popover>*/}
            {/*</TripSelector>*/}
            <RootRef rootRef={this.container}>
              <LocationsHistoryMapWrapper>
                <TripsMap
                  viewport={deviceLocations.viewport}
                  points={deviceLocations.points}
                  trips={deviceLocations.trips}
                  isLoading={deviceLocations.isLoading}
                />
              </LocationsHistoryMapWrapper>
            </RootRef>
          </MapControlContainer>
        </FullHeightBackground>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IDevicesStateProps => ({
  globalMap: state.locations,
  deviceLocations: state.deviceLocationsHistory
});

const mapDispatchToProps = (dispatch: any): IDevicesDispatchProps => {
  return {
    getLocationsHistory: (query, height, width) =>
      dispatch(getDeviceLocationsHistory(query, height, width))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(Location)));
