import React from "react";
import { LinearProgress } from "@material-ui/core";

import { CustomizedTableCell, IIconCell } from "../DeviceTable";
import {
  StyledThermometerIconDanger,
  StyledThermometerIconNormal
} from "../../../components/ThermometerIcon";
import { Configuration } from "../../../core/configuration/config";
import { parameterExists } from "../../services/DeviceService";
import {
  limitDecimal,
  temperatureToCelsius
} from "../../../core/utilities/ServiceUtilities";

export const TemperatureCell: React.FunctionComponent<IIconCell> = props => {
  const {
    measurements: { isLoading, values },
    parameters
  } = props;
  const dangerousTemperature =
    values &&
    values.temperature &&
    temperatureToCelsius(
      values.temperature.value,
      values.temperature.unitCode
    ) > Configuration.threshold.temperature;
  return (
    <CustomizedTableCell textalign="center">
      {isLoading ? (
        <LinearProgress />
      ) : (
        <React.Fragment>
          {values &&
            values.temperature &&
            parameterExists("Temperature", parameters) && (
              <React.Fragment>
                {dangerousTemperature && <StyledThermometerIconDanger />}
                {!dangerousTemperature && <StyledThermometerIconNormal />}
                <br />
                {values.temperature &&
                  `${limitDecimal(values.temperature.value, 1)} ${
                    values.temperature.unitText
                  }`}
              </React.Fragment>
            )}
        </React.Fragment>
      )}
    </CustomizedTableCell>
  );
};
