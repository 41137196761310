import * as React from "react";
import SwipeableViews from "react-swipeable-views";
import styled from "styled-components";
import { Grid, Tab, Tabs } from "@material-ui/core";

import { ResourcePredictionTab } from "./ResourcePredictionTab";
import { IResource } from "../../models/IResource";
import { theme } from "../../../core/withThemeProvider";
import { StretchedPaper } from "../ResourceGridTile";

interface IResourcePredictions {
  resource: IResource;
  setTab: (_: any, id: number) => void;
}

export const PredictionsContainer = styled(Grid)`
  height: 100%;
`;
PredictionsContainer.displayName = "PredictionsContainer";

export const ResourcePredictions: React.FunctionComponent<IResourcePredictions> = props => {
  const { resource, setTab } = props;
  return (
    <PredictionsContainer
      container={true}
      item={true}
      xs={12}
      direction="column"
    >
      <StretchedPaper>
        <Tabs
          value={resource.openedTab}
          onChange={setTab}
          indicatorColor="primary"
          textColor="primary"
          centered={true}
        >
          <Tab label={resource.nextMonthName} />
          <Tab label={resource.nextThreeMonthName} />
        </Tabs>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={resource.openedTab}
          onChangeIndex={setTab}
        >
          <ResourcePredictionTab
            volumeChance25={resource.nextMonthVolumeChance25}
            volumeChance25Unit={resource.nextMonthVolumeChance25Unit}
            volumeChance50={resource.nextMonthVolumeChance50}
            volumeChance50Unit={resource.nextMonthVolumeChance50Unit}
            volumeChance75={resource.nextMonthVolumeChance75}
            volumeChance75Unit={resource.nextMonthVolumeChance75Unit}
            rainChance={resource.nextMonthRainChance}
            loading={resource.isLoading}
            dir={theme.direction}
          />
          <ResourcePredictionTab
            volumeChance25={resource.nextThreeMonthVolumeChance25}
            volumeChance25Unit={resource.nextThreeMonthVolumeChance25Unit}
            volumeChance50={resource.nextThreeMonthVolumeChance50}
            volumeChance50Unit={resource.nextThreeMonthVolumeChance50Unit}
            volumeChance75={resource.nextThreeMonthVolumeChance75}
            volumeChance75Unit={resource.nextThreeMonthVolumeChance75Unit}
            rainChance={resource.nextThreeMonthRainChance}
            loading={resource.isLoading}
            dir={theme.direction}
          />
        </SwipeableViews>
      </StretchedPaper>
    </PredictionsContainer>
  );
};
ResourcePredictions.displayName = "ResourcePredictions";
