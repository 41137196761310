import moment from "moment";
import { Moment } from "moment";
import { InfluxDB } from "influx";
import { AxiosRequestConfig } from "axios";

import { theme } from "../withThemeProvider";
import { ProductionConfig } from "./config.prod";
import { StagingConfig } from "./config.stg";
import { DevelopmentConfig } from "./config.dev";
import {
  IAggregationFunction,
  IAggregationInterval
} from "../../Devices/DeviceParameters/models/IViewDeviceParameters";

export enum ENVIRONMENT {
  PRODUCTION,
  STAGING,
  DEVELOPMENT
}
const getReactEnvironment = (): ENVIRONMENT => {
  let currentEnvironment = ENVIRONMENT.PRODUCTION;
  const stage = process.env.REACT_APP_ENVIRONMENT;
  if (stage === "STG") {
    currentEnvironment = ENVIRONMENT.STAGING;
  } else if (stage === "DEV") {
    currentEnvironment = ENVIRONMENT.DEVELOPMENT;
  }
  return currentEnvironment;
};

export const environment: ENVIRONMENT = (() => {
  const nodeEnvironment = process.env.NODE_ENV;
  const CI = process.env.CI;
  if (CI) {
    return getReactEnvironment();
  }
  if (nodeEnvironment === "test") {
    return ENVIRONMENT.PRODUCTION;
  }
  return getReactEnvironment();
})();

export const Configuration = {
  ...ProductionConfig,
  ...(environment === ENVIRONMENT.STAGING && StagingConfig),
  ...(environment === ENVIRONMENT.DEVELOPMENT && DevelopmentConfig),
  devicePrefixUrl: "/v1/edi/devices/",
  menuWidth: 240,
  menuHoverColor: "rgba(0, 0, 0, 0.12)",
  rowsPerTablePage: 50,
  isMobile: window.innerWidth > theme.breakpoints.values.md,
  datepicker: {
    format: "DD/MM/YYYY",
    mask: "__/__/____",
    maskChar: "_"
  },
  dateTimePicker: {
    format: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    maskChar: "_"
  },
  influxClient: new InfluxDB(),
  threshold: {
    battery: 3.2,
    temperature: 50,
    time: (time: Moment) => time.isSameOrBefore(moment().subtract(1, "days"))
  },
  defaultDateFormat: "DD.MM.YYYY",
  defaultTimeFormat: "HH:mm:ss",
  devices: {
    defaultMeasurementPeriod: "24h",
    defaultMeasurementParameter: "level",
    defaultMeasurementParameterUnit: "mtr",
    measurementPeriods: [
      { text: "12 Samples", value: "12" },
      { text: "24 Hours", value: "24h" },
      { text: "7 Days", value: "7d" },
      { text: "30 Days", value: "30d" },
      { text: "1 Year", value: "365d" }
    ],
    graph: {
      intervals: [
        { name: "Hour", value: "1h", format: "MMM D LT" },
        { name: "2 Hours", value: "2h", format: "MMM D LT" },
        { name: "4 Hours", value: "4h", format: "MMM D LT" },
        { name: "6 Hours", value: "6h", format: "MMM D LT" },
        { name: "12 Hours", value: "12h", format: "MMM D LT" },
        { name: "Day", value: "1d", format: "MMM D" },
        { name: "Week", value: "7d", format: "MMM D" },
        { name: "Month", value: "30d", format: "MMM Y" },
        { name: "Year", value: "365d", format: "Y" }
      ] as IAggregationInterval[],
      functions: [
        { name: "Max", value: "MAX" },
        { name: "Min", value: "MIN" },
        { name: "Average", value: "MEAN" },
        { name: "Median", value: "MEDIAN" },
        { name: "Integral", value: "INTEGRAL" },
        { name: "Standard Deviation", value: "STDDEV" },
        { name: "Sum", value: "SUM" },
        { name: "Difference of min and max", value: "SPREAD" }
      ] as IAggregationFunction[]
    }
  },
  viewDeviceParameterTableLength: 100,
  deviceMainMeasuredParameters: [
    "volumePercent",
    "volume",
    "level",
    "weight",
    "pressure",
    "turbidity",
    "temperature",
    "chargeLevel",
    "rssi"
  ],
  units: [
    { unitCode: "", unitText: "" },
    { unitCode: "MTR", unitText: "m" },
    { unitCode: "CEL", unitText: "°C" },
    { unitCode: "FAH", unitText: "°F" },
    { unitCode: "VLT", unitText: "V" },
    { unitCode: "KGM", unitText: "kg" },
    { unitCode: "BAR", unitText: "bar" },
    { unitCode: "PAL", unitText: "Pa" },
    { unitCode: "UGA", unitText: "U.S. Gallon" },
    { unitCode: "L", unitText: "L" },
    { unitCode: "MAL", unitText: "ML" },
    { unitCode: "G/ML", unitText: "g/mL" },
    { unitCode: "%", unitText: "%" }
  ],
  colors: {
    icon: {
      default: "#0000b0",
      normal: "#00b000",
      danger: "#ff0050",
      blue: "#2196f3",
      timeout: "#9E9E9E"
    },
    charts: {
      cartesianGrid: "#e5e5e5",
      referenceLine: "#6b6b6b",
      viewDeviceParameter: {
        max: "#f58231",
        min: "#4363d8",
        mean: "#3cb44b",
        median: "#ffe119",
        integral: "#6dff3e",
        sum: "#16dcff",
        deviation: "#ff9afd",
        diffenrece: "#ff807b"
      },
      deviceChart: {
        min: "#2196f3",
        max: "#000000"
      },
      thresholdReferenceLine: "orange"
    },
    rainForecastChartColors: [
      "#003b71",
      "#064580",
      "#0F5699",
      "#1B69B3",
      "#1F78CC",
      "#1688F2",
      "#40a4f2",
      "#6EB9FF",
      "#79c0ff",
      "#81cbff",
      "#85d4ff",
      "#92dfff"
    ]
  },
  limits: {
    deviceParameterResultsLength: 500
  },
  clientVersionCheckIntervalMs: 10000,
  baseUnits: {
    cel: " °C",
    pal: " Pa",
    vlt: " V",
    mtr: " m",
    percent: " %"
  },
  snackbarAutohideDuration: {
    success: 4000,
    error: 15000
  },
  alert: {
    timeMultipliers: {
      m: { value: 1, name: "Minute(s)" },
      h: { value: 60, name: "Hour(s)" },
      d: { value: 60 * 24, name: "Day(s)" }
    },
    operations: [
      { id: "LESS_THAN", name: "Less than" },
      { id: "GREATER_THAN", name: "Greater than" },
      { id: "EQUAL_TO", name: "Equal to" },
      { id: "LESS_THAN_OR_EQUAL_TO", name: "Less than or equal to" },
      { id: "GREATER_THAN_OR_EQUAL_TO", name: "Greater than or equal to" },
      { id: "MISSED_DATA", name: "Missed data" }
    ],
    types: [
      { id: "WARNING", name: "Warning" },
      { id: "CRITICAL", name: "Critical" }
    ],
    notificationTypes: [
      { id: "SMS", name: "SMS" },
      { id: "EMAIL", name: "Email" }
    ]
  },
  logo: "/img/logo-white.png",
  companyName: "Ellenex",
  theming: {} as any,
  breadcrumbDetails: [
    { name: "devices", value: "Device details" },
    { name: "alerts", value: "Alert details" }
  ]
};

Configuration.influxClient = new InfluxDB(Configuration.influxConfig as any);

export const ediApiHeaders: AxiosRequestConfig = {
  headers: {
    "x-api-key": Configuration.ediApikey
  }
};

export const esmaApiHeaders: AxiosRequestConfig = {
  headers: {
    "x-api-key": Configuration.esmaApikey
  }
};

export const authApiHeaders: AxiosRequestConfig = {
  headers: {
    "x-api-key": Configuration.authApikey
  }
};

export const eramaApiHeaders: AxiosRequestConfig = {
  headers: {
    "x-api-key": Configuration.eramaApikey
  }
};

export const earaApiHeaders: AxiosRequestConfig = {
  headers: {
    "x-api-key": Configuration.earaApikey
  }
};

export const ednaApiHeaders: AxiosRequestConfig = {
  headers: {
    "x-api-key": Configuration.ednaApikey
  }
};
export const elaApiHeaders: AxiosRequestConfig = {
  headers: {
    "x-api-key": Configuration.elaApikey
  }
};

export const edlaApiHeaders: AxiosRequestConfig = {
  headers: {
    "x-api-key": Configuration.edlaApikey
  }
};
