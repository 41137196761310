import { useCallback, useEffect, useRef } from "react";

export const useAnimationFrame = (
  animateFn: (deltaTime: number, maxTime: number) => void,
  maxTime: number
) => {
  const requestRef = useRef<number>();
  const previousTimeRef = useRef<number>();

  const animate = useCallback(
    (time: number) => {
      if (previousTimeRef.current !== undefined) {
        const deltaTime = time - previousTimeRef.current;
        animateFn(deltaTime, maxTime);
      }
      previousTimeRef.current = time;
      requestRef.current = requestAnimationFrame(animate);
    },
    [animateFn, maxTime]
  );

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current as number);
  }, [maxTime, animate]); // Make sure the effect runs only once
};
