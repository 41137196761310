export interface ICountryCode {
  country: string;
  iso: string;
  code: string;
}
export const countryCodes = [
  { country: "Afghanistan", iso: "AF / AFG", code: "93" },
  { country: "Albania", iso: "AL / ALB", code: "355" },
  { country: "Algeria", iso: "DZ / DZA", code: "213" },
  { country: "American Samoa", iso: "AS / ASM", code: "1-684" },
  { country: "Andorra", iso: "AD / AND", code: "376" },
  { country: "Angola", iso: "AO / AGO", code: "244" },
  { country: "Anguilla", iso: "AI / AIA", code: "1-264" },
  { country: "Antarctica", iso: "AQ / ATA", code: "672" },
  { country: "Antigua and Barbuda", iso: "AG / ATG", code: "1-268" },
  { country: "Argentina", iso: "AR / ARG", code: "54" },
  { country: "Armenia", iso: "AM / ARM", code: "374" },
  { country: "Aruba", iso: "AW / ABW", code: "297" },
  { country: "Australia", iso: "AU / AUS", code: "61" },
  { country: "Austria", iso: "AT / AUT", code: "43" },
  { country: "Azerbaijan", iso: "AZ / AZE", code: "994" },
  { country: "Bahamas", iso: "BS / BHS", code: "1-242" },
  { country: "Bahrain", iso: "BH / BHR", code: "973" },
  { country: "Bangladesh", iso: "BD / BGD", code: "880" },
  { country: "Barbados", iso: "BB / BRB", code: "1-246" },
  { country: "Belarus", iso: "BY / BLR", code: "375" },
  { country: "Belgium", iso: "BE / BEL", code: "32" },
  { country: "Belize", iso: "BZ / BLZ", code: "501" },
  { country: "Benin", iso: "BJ / BEN", code: "229" },
  { country: "Bermuda", iso: "BM / BMU", code: "1-441" },
  { country: "Bhutan", iso: "BT / BTN", code: "975" },
  { country: "Bolivia", iso: "BO / BOL", code: "591" },
  { country: "Bosnia and Herzegovina", iso: "BA / BIH", code: "387" },
  { country: "Botswana", iso: "BW / BWA", code: "267" },
  { country: "Brazil", iso: "BR / BRA", code: "55" },
  { country: "British Indian Ocean Territory", iso: "IO / IOT", code: "246" },
  { country: "British Virgin Islands", iso: "VG / VGB", code: "1-284" },
  { country: "Brunei", iso: "BN / BRN", code: "673" },
  { country: "Bulgaria", iso: "BG / BGR", code: "359" },
  { country: "Burkina Faso", iso: "BF / BFA", code: "226" },
  { country: "Burundi", iso: "BI / BDI", code: "257" },
  { country: "Cambodia", iso: "KH / KHM", code: "855" },
  { country: "Cameroon", iso: "CM / CMR", code: "237" },
  { country: "Canada", iso: "CA / CAN", code: "1" },
  { country: "Cape Verde", iso: "CV / CPV", code: "238" },
  { country: "Cayman Islands", iso: "KY / CYM", code: "1-345" },
  { country: "Central African Republic", iso: "CF / CAF", code: "236" },
  { country: "Chad", iso: "TD / TCD", code: "235" },
  { country: "Chile", iso: "CL / CHL", code: "56" },
  { country: "China", iso: "CN / CHN", code: "86" },
  { country: "Colombia", iso: "CO / COL", code: "57" },
  { country: "Comoros", iso: "KM / COM", code: "269" },
  { country: "Cook Islands", iso: "CK / COK", code: "682" },
  { country: "Costa Rica", iso: "CR / CRI", code: "506" },
  { country: "Croatia", iso: "HR / HRV", code: "385" },
  { country: "Cuba", iso: "CU / CUB", code: "53" },
  { country: "Curacao", iso: "CW / CUW", code: "599" },
  { country: "Cyprus", iso: "CY / CYP", code: "357" },
  { country: "Czech Republic", iso: "CZ / CZE", code: "420" },
  { country: "Democratic Republic of the Congo", iso: "CD / COD", code: "243" },
  { country: "Denmark", iso: "DK / DNK", code: "45" },
  { country: "Djibouti", iso: "DJ / DJI", code: "253" },
  { country: "Dominica", iso: "DM / DMA", code: "1-767" },
  {
    country: "Dominican Republic",
    iso: "DO / DOM",
    code: "1-809, 1-829, 1-849"
  },
  { country: "East Timor", iso: "TL / TLS", code: "670" },
  { country: "Ecuador", iso: "EC / ECU", code: "593" },
  { country: "Egypt", iso: "EG / EGY", code: "20" },
  { country: "El Salvador", iso: "SV / SLV", code: "503" },
  { country: "Equatorial Guinea", iso: "GQ / GNQ", code: "240" },
  { country: "Eritrea", iso: "ER / ERI", code: "291" },
  { country: "Estonia", iso: "EE / EST", code: "372" },
  { country: "Ethiopia", iso: "ET / ETH", code: "251" },
  { country: "Falkland Islands", iso: "FK / FLK", code: "500" },
  { country: "Faroe Islands", iso: "FO / FRO", code: "298" },
  { country: "Fiji", iso: "FJ / FJI", code: "679" },
  { country: "Finland", iso: "FI / FIN", code: "358" },
  { country: "France", iso: "FR / FRA", code: "33" },
  { country: "French Polynesia", iso: "PF / PYF", code: "689" },
  { country: "French Guiana", iso: "GF / GUF", code: "594" },
  { country: "Gabon", iso: "GA / GAB", code: "241" },
  { country: "Gambia", iso: "GM / GMB", code: "220" },
  { country: "Georgia", iso: "GE / GEO", code: "995" },
  { country: "Germany", iso: "DE / DEU", code: "49" },
  { country: "Ghana", iso: "GH / GHA", code: "233" },
  { country: "Gibraltar", iso: "GI / GIB", code: "350" },
  { country: "Greece", iso: "GR / GRC", code: "30" },
  { country: "Greenland", iso: "GL / GRL", code: "299" },
  { country: "Grenada", iso: "GD / GRD", code: "1-473" },
  { country: "Guam", iso: "GU / GUM", code: "1-671" },
  { country: "Guatemala", iso: "GT / GTM", code: "502" },
  { country: "Guernsey", iso: "GG / GGY", code: "44-1481" },
  { country: "Guinea", iso: "GN / GIN", code: "224" },
  { country: "Guinea-Bissau", iso: "GW / GNB", code: "245" },
  { country: "Guyana", iso: "GY / GUY", code: "592" },
  { country: "Haiti", iso: "HT / HTI", code: "509" },
  { country: "Honduras", iso: "HN / HND", code: "504" },
  { country: "Hong Kong", iso: "HK / HKG", code: "852" },
  { country: "Hungary", iso: "HU / HUN", code: "36" },
  { country: "Iceland", iso: "IS / ISL", code: "354" },
  { country: "India", iso: "IN / IND", code: "91" },
  { country: "Indonesia", iso: "ID / IDN", code: "62" },
  { country: "Iran", iso: "IR / IRN", code: "98" },
  { country: "Iraq", iso: "IQ / IRQ", code: "964" },
  { country: "Ireland", iso: "IE / IRL", code: "353" },
  { country: "Isle of Man", iso: "IM / IMN", code: "44-1624" },
  { country: "Israel", iso: "IL / ISR", code: "972" },
  { country: "Italy", iso: "IT / ITA", code: "39" },
  { country: "Ivory Coast", iso: "CI / CIV", code: "225" },
  { country: "Jamaica", iso: "JM / JAM", code: "1-876" },
  { country: "Japan", iso: "JP / JPN", code: "81" },
  { country: "Jersey", iso: "JE / JEY", code: "44-1534" },
  { country: "Jordan", iso: "JO / JOR", code: "962" },
  { country: "Kenya", iso: "KE / KEN", code: "254" },
  { country: "Kiribati", iso: "KI / KIR", code: "686" },
  { country: "Kosovo", iso: "XK / XKX", code: "383" },
  { country: "Kuwait", iso: "KW / KWT", code: "965" },
  { country: "Kyrgyzstan", iso: "KG / KGZ", code: "996" },
  { country: "Laos", iso: "LA / LAO", code: "856" },
  { country: "Latvia", iso: "LV / LVA", code: "371" },
  { country: "Lebanon", iso: "LB / LBN", code: "961" },
  { country: "Lesotho", iso: "LS / LSO", code: "266" },
  { country: "Liberia", iso: "LR / LBR", code: "231" },
  { country: "Libya", iso: "LY / LBY", code: "218" },
  { country: "Liechtenstein", iso: "LI / LIE", code: "423" },
  { country: "Lithuania", iso: "LT / LTU", code: "370" },
  { country: "Luxembourg", iso: "LU / LUX", code: "352" },
  { country: "Macau", iso: "MO / MAC", code: "853" },
  { country: "Macedonia", iso: "MK / MKD", code: "389" },
  { country: "Madagascar", iso: "MG / MDG", code: "261" },
  { country: "Malawi", iso: "MW / MWI", code: "265" },
  { country: "Malaysia", iso: "MY / MYS", code: "60" },
  { country: "Maldives", iso: "MV / MDV", code: "960" },
  { country: "Mali", iso: "ML / MLI", code: "223" },
  { country: "Malta", iso: "MT / MLT", code: "356" },
  { country: "Marshall Islands", iso: "MH / MHL", code: "692" },
  { country: "Mauritania", iso: "MR / MRT", code: "222" },
  { country: "Mauritius", iso: "MU / MUS", code: "230" },
  { country: "Mayotte", iso: "YT / MYT", code: "262" },
  { country: "Mexico", iso: "MX / MEX", code: "52" },
  { country: "Micronesia", iso: "FM / FSM", code: "691" },
  { country: "Moldova", iso: "MD / MDA", code: "373" },
  { country: "Monaco", iso: "MC / MCO", code: "377" },
  { country: "Mongolia", iso: "MN / MNG", code: "976" },
  { country: "Montenegro", iso: "ME / MNE", code: "382" },
  { country: "Montserrat", iso: "MS / MSR", code: "1-664" },
  { country: "Morocco", iso: "MA / MAR", code: "212" },
  { country: "Mozambique", iso: "MZ / MOZ", code: "258" },
  { country: "Myanmar", iso: "MM / MMR", code: "95" },
  { country: "Namibia", iso: "NA / NAM", code: "264" },
  { country: "Nauru", iso: "NR / NRU", code: "674" },
  { country: "Nepal", iso: "NP / NPL", code: "977" },
  { country: "Netherlands", iso: "NL / NLD", code: "31" },
  { country: "New Caledonia", iso: "NC / NCL", code: "687" },
  { country: "New Zealand", iso: "NZ / NZL", code: "64" },
  { country: "Nicaragua", iso: "NI / NIC", code: "505" },
  { country: "Niger", iso: "NE / NER", code: "227" },
  { country: "Nigeria", iso: "NG / NGA", code: "234" },
  { country: "Niue", iso: "NU / NIU", code: "683" },
  { country: "North Korea", iso: "KP / PRK", code: "850" },
  { country: "Northern Mariana Islands", iso: "MP / MNP", code: "1-670" },
  { country: "Norway", iso: "NO / NOR", code: "47" },
  { country: "Oman", iso: "OM / OMN", code: "968" },
  { country: "Pakistan", iso: "PK / PAK", code: "92" },
  { country: "Palau", iso: "PW / PLW", code: "680" },
  { country: "Palestine", iso: "PS / PSE", code: "970" },
  { country: "Panama", iso: "PA / PAN", code: "507" },
  { country: "Papua New Guinea", iso: "PG / PNG", code: "675" },
  { country: "Paraguay", iso: "PY / PRY", code: "595" },
  { country: "Peru", iso: "PE / PER", code: "51" },
  { country: "Philippines", iso: "PH / PHL", code: "63" },
  { country: "Poland", iso: "PL / POL", code: "48" },
  { country: "Portugal", iso: "PT / PRT", code: "351" },
  { country: "Puerto Rico", iso: "PR / PRI", code: "1-787, 1-939" },
  { country: "Qatar", iso: "QA / QAT", code: "974" },
  { country: "Republic of the Congo", iso: "CG / COG", code: "242" },
  { country: "Romania", iso: "RO / ROU", code: "40" },
  { country: "Russia / Kazakhstan", iso: "RU / RUS / KZ / KAZ", code: "7" },
  { country: "Rwanda", iso: "RW / RWA", code: "250" },
  {
    country: "Saint Barthelemy / Saint Martin",
    iso: "BL / BLM / MF / MAF",
    code: "590"
  },
  { country: "Saint Helena", iso: "SH / SHN", code: "290" },
  { country: "Saint Kitts and Nevis", iso: "KN / KNA", code: "1-869" },
  { country: "Saint Lucia", iso: "LC / LCA", code: "1-758" },
  { country: "Saint Pierre and Miquelon", iso: "PM / SPM", code: "508" },
  {
    country: "Saint Vincent and the Grenadines",
    iso: "VC / VCT",
    code: "1-784"
  },
  { country: "Samoa", iso: "WS / WSM", code: "685" },
  { country: "San Marino", iso: "SM / SMR", code: "378" },
  { country: "Sao Tome and Principe", iso: "ST / STP", code: "239" },
  { country: "Saudi Arabia", iso: "SA / SAU", code: "966" },
  { country: "Senegal", iso: "SN / SEN", code: "221" },
  { country: "Serbia", iso: "RS / SRB", code: "381" },
  { country: "Seychelles", iso: "SC / SYC", code: "248" },
  { country: "Sierra Leone", iso: "SL / SLE", code: "232" },
  { country: "Singapore", iso: "SG / SGP", code: "65" },
  { country: "Sint Maarten", iso: "SX / SXM", code: "1-721" },
  { country: "Slovakia", iso: "SK / SVK", code: "421" },
  { country: "Slovenia", iso: "SI / SVN", code: "386" },
  { country: "Solomon Islands", iso: "SB / SLB", code: "677" },
  { country: "Somalia", iso: "SO / SOM", code: "252" },
  { country: "South Africa", iso: "ZA / ZAF", code: "27" },
  { country: "South Korea", iso: "KR / KOR", code: "82" },
  { country: "South Sudan", iso: "SS / SSD", code: "211" },
  { country: "Spain", iso: "ES / ESP", code: "34" },
  { country: "Sri Lanka", iso: "LK / LKA", code: "94" },
  { country: "Sudan", iso: "SD / SDN", code: "249" },
  { country: "Suriname", iso: "SR / SUR", code: "597" },
  { country: "Swaziland", iso: "SZ / SWZ", code: "268" },
  { country: "Sweden", iso: "SE / SWE", code: "46" },
  { country: "Switzerland", iso: "CH / CHE", code: "41" },
  { country: "Syria", iso: "SY / SYR", code: "963" },
  { country: "Taiwan", iso: "TW / TWN", code: "886" },
  { country: "Tajikistan", iso: "TJ / TJK", code: "992" },
  { country: "Tanzania", iso: "TZ / TZA", code: "255" },
  { country: "Thailand", iso: "TH / THA", code: "66" },
  { country: "Togo", iso: "TG / TGO", code: "228" },
  { country: "Tokelau", iso: "TK / TKL", code: "690" },
  { country: "Tonga", iso: "TO / TON", code: "676" },
  { country: "Trinidad and Tobago", iso: "TT / TTO", code: "1-868" },
  { country: "Tunisia", iso: "TN / TUN", code: "216" },
  { country: "Turkey", iso: "TR / TUR", code: "90" },
  { country: "Turkmenistan", iso: "TM / TKM", code: "993" },
  { country: "Turks and Caicos Islands", iso: "TC / TCA", code: "1-649" },
  { country: "Tuvalu", iso: "TV / TUV", code: "688" },
  { country: "U.S. Virgin Islands", iso: "VI / VIR", code: "1-340" },
  { country: "Uganda", iso: "UG / UGA", code: "256" },
  { country: "Ukraine", iso: "UA / UKR", code: "380" },
  { country: "United Arab Emirates", iso: "AE / ARE", code: "971" },
  { country: "United Kingdom", iso: "GB / GBR", code: "44" },
  { country: "United States", iso: "US / USA", code: "1" },
  { country: "Uruguay", iso: "UY / URY", code: "598" },
  { country: "Uzbekistan", iso: "UZ / UZB", code: "998" },
  { country: "Vanuatu", iso: "VU / VUT", code: "678" },
  { country: "Vatican", iso: "VA / VAT", code: "379" },
  { country: "Venezuela", iso: "VE / VEN", code: "58" },
  { country: "Vietnam", iso: "VN / VNM", code: "84" },
  { country: "Wallis and Futuna", iso: "WF / WLF", code: "681" },
  { country: "Yemen", iso: "YE / YEM", code: "967" },
  { country: "Zambia", iso: "ZM / ZMB", code: "260" },
  { country: "Zimbabwe", iso: "ZW / ZWE", code: "263" }
];

export const CanadianCodes = [
  204,
  226,
  236,
  249,
  250,
  263,
  289,
  306,
  343,
  354,
  365,
  367,
  368,
  382,
  387,
  403,
  416,
  418,
  428,
  431,
  437,
  438,
  450,
  468,
  474,
  506,
  514,
  519,
  548,
  579,
  581,
  584,
  587,
  600,
  604,
  613,
  622,
  633,
  639,
  644,
  647,
  655,
  672,
  677,
  683,
  688,
  705,
  709,
  742,
  753,
  778,
  780,
  782,
  807,
  819,
  825,
  867,
  873,
  879,
  902,
  905,
  907
];
