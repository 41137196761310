import axios, { AxiosResponse } from "axios";

import { ActionType } from "../../core/ActionTypes";
import { IDevice, IDeviceApi } from "../models/IDevice";
import { Configuration, ediApiHeaders } from "../../core/configuration/config";
import { deviceFromApi, deviceToApiFormat } from "../services/DeviceService";
import { getTableMeasurements } from "../../Resources/services/InfluxService";
import { Thunk } from "../../core/store";
import { deviceReducerActionTypes } from "../reducers/deviceReducer";
import {
  createBlockableDispatch,
  createErrorConsoleMessage,
  getShortId
} from "../../core/utilities/ServiceUtilities";
import { IDeviceModelApi } from "../models/IDeviceModels";
import {
  checkError,
  SnackbarError
} from "../../core/utilities/SnackbarUtilities";
import { IInfluxTableQuery } from "../../Resources/models/IResource";

export const getDeviceById: Thunk<deviceReducerActionTypes> = (id: string) => {
  return async (dispatch, _, opt): Promise<IDevice | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    blockableDispatch({ type: ActionType.DEVICE_LOADING, payload: true });
    try {
      const response: AxiosResponse<IDeviceApi> = await axios.get(
        Configuration.EdiAPIUrl + "/devices/" + id,
        ediApiHeaders
      );
      const device = deviceFromApi(response.data);
      blockableDispatch({
        type: ActionType.GET_DEVICE,
        payload: device
      });
      return device;
    } catch (err) {
      createErrorConsoleMessage(err, "getDeviceById", { id });
      return checkError(err);
    } finally {
      blockableDispatch({ type: ActionType.DEVICE_LOADING, payload: false });
    }
  };
};

export const getDeviceByIdWithModel: Thunk<deviceReducerActionTypes> = (
  id: string
) => {
  return async (dispatch, _, opt): Promise<IDevice | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    blockableDispatch({ type: ActionType.DEVICE_LOADING, payload: true });
    try {
      const device = await axios.get<IDeviceApi>(
        Configuration.EdiAPIUrl + "/devices/" + id,
        ediApiHeaders
      );
      let deviceWithModel: IDevice;
      try {
        const model = await axios.get<IDeviceModelApi>(
          Configuration.EdiAPIUrl +
            "/devicemodels/" +
            getShortId(device.data.deviceModel),
          ediApiHeaders
        );
        deviceWithModel = deviceFromApi(device.data, model.data.name);
      } catch (e) {
        deviceWithModel = deviceFromApi(device.data);
      }
      blockableDispatch({
        type: ActionType.GET_DEVICE,
        payload: deviceWithModel
      });
      return deviceWithModel;
    } catch (err) {
      createErrorConsoleMessage(err, "getDeviceByIdWithModel", { id });
      return checkError(err);
    } finally {
      blockableDispatch({ type: ActionType.DEVICE_LOADING, payload: false });
    }
  };
};

export const getDeviceMeasurements: Thunk<deviceReducerActionTypes> = (
  device: IDevice
) => {
  return async (
    dispatch,
    _,
    opt
  ): Promise<IInfluxTableQuery[] | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    blockableDispatch({
      type: ActionType.DEVICE_MEASUREMENT_LOADING,
      payload: true
    });
    try {
      const measurements = await getTableMeasurements([device]);
      blockableDispatch({
        type: ActionType.GET_DEVICE_MEASUREMENTS,
        payload: measurements
      });
      return measurements;
    } catch (err) {
      createErrorConsoleMessage(
        err,
        "getDeviceMeasurements::InfluxService.getTableMeasurements",
        { device, toInflux: [device] }
      );
      return checkError(err);
    } finally {
      blockableDispatch({
        type: ActionType.DEVICE_MEASUREMENT_LOADING,
        payload: false
      });
    }
  };
};

export const updateDevice: Thunk<deviceReducerActionTypes> = (
  id: string,
  device: IDevice
) => {
  return async (dispatch): Promise<IDevice | SnackbarError> => {
    dispatch({ type: ActionType.DEVICE_LOADING, payload: true });
    try {
      const response: AxiosResponse<IDeviceApi> = await axios.put(
        Configuration.EdiAPIUrl + "/devices/" + id,
        deviceToApiFormat(device),
        ediApiHeaders
      );
      const updatedDevice = deviceFromApi(response.data);
      dispatch({
        type: ActionType.UPDATE_DEVICE,
        payload: updatedDevice
      });
      return updatedDevice;
    } catch (err) {
      createErrorConsoleMessage(err, "updateDevice", { id, device });
      return checkError(err);
    } finally {
      dispatch({ type: ActionType.DEVICE_LOADING, payload: false });
    }
  };
};

export const clearDevice = () => {
  return { type: ActionType.CLEAR_DEVICE };
};
