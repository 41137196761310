import * as React from "react";
import styled from "styled-components";
import {
  TablePagination,
  TableFooter,
  TableRow,
  TableCell
} from "@material-ui/core";

import { IDeviceTableProps } from "../DeviceTable";
import { Pagination } from "../../../components/Pagination";

export const TablePaginationLeft = styled(TablePagination)<any>`
  && {
    display: flex;
    align-items: start;
  }
`;
TablePaginationLeft.displayName = "TablePaginationLeft";

export const DeviceTableFooter: React.FunctionComponent<IDeviceTableProps> = props => {
  return props.onChangePage ? (
    <TableFooter>
      <TableRow>
        {props.pagination && (
          <TableCell>
            <Pagination
              totalItems={props.totalItems || 0}
              pagination={props.pagination}
              onChangePage={props.onChangePage}
            />
          </TableCell>
        )}
      </TableRow>
    </TableFooter>
  ) : null;
};
