import axios, { AxiosResponse } from "axios";

import { ActionType } from "../../../core/ActionTypes";
import {
  Configuration,
  ediApiHeaders
} from "../../../core/configuration/config";
import {
  IDeviceParameterChartItem,
  IViewDeviceParameters
} from "../models/IViewDeviceParameters";
import { IDeviceApi } from "../../models/IDevice";
import {
  convertToHours,
  getDeviceParametersService,
  getParameterAggregatedChartService,
  getParameterChartService
} from "../services/DeviceParametersService";
import { createErrorConsoleMessage } from "../../../core/utilities/ServiceUtilities";
import { Thunk } from "../../../core/store";
import { viewDeviceParametersReducerTypes } from "../reducers/DeviceParametersReducer";
import {
  checkError,
  SnackbarError
} from "../../../core/utilities/SnackbarUtilities";

export const getDeviceParameters: Thunk<viewDeviceParametersReducerTypes> = (
  id: string
) => {
  return async (dispatch): Promise<string[] | SnackbarError> => {
    dispatch({ type: ActionType.DEVICE_PARAMETERS_LOADING, payload: true });
    try {
      const response: AxiosResponse<IDeviceApi> = await axios.get(
        Configuration.EdiAPIUrl + "/devices/" + id,
        ediApiHeaders
      );
      const parameters = getDeviceParametersService(response.data);
      dispatch({
        type: ActionType.GET_DEVICE_PARAMETERS,
        payload: { device: id, parameters }
      });
      return parameters.parameterList;
    } catch (err) {
      createErrorConsoleMessage(err, "getDeviceParameters", { id });
      return checkError(err);
    } finally {
      dispatch({ type: ActionType.DEVICE_PARAMETERS_LOADING, payload: false });
    }
  };
};

export const getDeviceParameterChart: Thunk<viewDeviceParametersReducerTypes> = (
  options: IViewDeviceParameters
) => {
  return async (
    dispatch
  ): Promise<IDeviceParameterChartItem[] | SnackbarError> => {
    dispatch({
      type: ActionType.DEVICE_PARAMETERS_CHART_LOADING,
      payload: true
    });
    try {
      let result: IDeviceParameterChartItem[] = [];
      if (options.aggregationEnabled) {
        result = await getParameterAggregatedChartService(
          options.device,
          options.parameter,
          convertToHours(options.from, options.showHours),
          convertToHours(options.to, options.showHours),
          options.aggregationFunction,
          options.aggregationInterval,
          (options.parameterUnits[options.parameter] &&
            options.parameterUnits[options.parameter].unitCode) ||
            "",
          options.parameterUnits[options.parameter] &&
            options.parameterUnits[options.parameter].unitText
        );
      } else {
        result = await getParameterChartService(
          options.device,
          options.parameter,
          convertToHours(options.from, options.showHours),
          convertToHours(options.to, options.showHours),
          (options.parameterUnits[options.parameter] &&
            options.parameterUnits[options.parameter].unitCode) ||
            "",
          options.parameterUnits[options.parameter] &&
            options.parameterUnits[options.parameter].unitText
        );
      }
      const chartOverload =
        result.length >= Configuration.limits.deviceParameterResultsLength;

      dispatch({
        type: ActionType.REFRESH_DEVICE_PARAMETER_OPTIONS,
        payload: {
          ...options,
          chart: chartOverload ? [] : result,
          chartOverload
        }
      });
      return chartOverload ? [] : result;
    } catch (err) {
      createErrorConsoleMessage(err, "getDeviceParameterChart", { options });
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.DEVICE_PARAMETERS_CHART_LOADING,
        payload: false
      });
    }
  };
};

export const clearViewParameter = () => ({
  type: ActionType.CLEAR_VIEW_DEVICE_PARAMETER
});

export const expandPanel = () => ({
  type: ActionType.DEVICE_PARAMETER_EXPANSION_STATE
});
