import React from "react";
import styled from "styled-components";
import {
  Field,
  Form,
  FormikProps,
  FieldArray,
  FieldArrayRenderProps
} from "formik";
import {
  Button,
  Grid,
  LinearProgress,
  InputLabel,
  MenuItem,
  Typography,
  InputAdornment,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Paper
} from "@material-ui/core";
import { Edit as EditIcon, ExpandMore } from "@material-ui/icons";

import { StyledTextField, TextField } from "../../../components/TextField";
import { Validators } from "../../../util/validators";
import {
  IDeviceAlert,
  IDevcieAlertParameterSelection,
  IDeviceAlertOperation
} from "../models/IAlerts";
import { Switch } from "../../../components/SwitchWithLabel";
import { Select, StyledSelect } from "../../../components/Select";
import { IDevice } from "../../models/IDevice";
import {
  deviceAlertParameters,
  getDeviceAlertParameterUnitText,
  getDeviceAlertParameterName
} from "../services/DeviceAlertService";
import { DeviceAlertTemplateFormArray } from "./DeviceAlertTemplate";
import { Configuration } from "../../../core/configuration/config";
import { StyledTitle } from "../../../components/sharedStyledComponents";

interface IStyledGrid {
  paddingtop?: string;
  paddingright?: string;
}

export const EditAlertContainer = styled(Form)`
  margin-bottom: 20px;
  && > div:first-child {
    padding-top: 10px;
  }
`;
EditAlertContainer.displayName = "EditAlertContainer";
export const AlertExpansionPanelContainer = styled(ExpansionPanelDetails)`
  flex-direction: column;
`;
AlertExpansionPanelContainer.displayName = "AlertExpansionPanelContainer";

export const Elevation = styled(Paper)`
  padding: 30px 15px 15px;
  margin-bottom: 15px;
`;
Elevation.displayName = "Elevation";

export const StyledExpansionPanelTitle = styled(StyledTitle)`
  &&& {
    padding: 0;
    font-size: 12px;
  }
`;
StyledExpansionPanelTitle.displayName = "StyledExpansionPanelTitle";

export const IntervalContainer = styled.div`
  display: flex;
  align-items: baseline;
  p:first-of-type {
    padding-right: 10px;
  }
  div:last-of-type {
    max-width: 150px;
  }
`;
IntervalContainer.displayName = "IntervalContainer";

export const StyledGrid = styled(Grid)<IStyledGrid>`
  && {
    ${props => (props.paddingtop ? `padding-top: ${props.paddingtop}` : "")}
    ${props =>
      props.paddingright ? `padding-right: ${props.paddingright}` : ""}
  }
`;
StyledGrid.displayName = "StyledGrid";

interface IStyledTypograghy {
  disabled?: boolean;
}

export const StyledTypograghy = styled(Typography)<IStyledTypograghy>`
  && {
    line-height: 275%;
    display: inline;
    ${props => (props.disabled ? `color: #aaa` : "")};
  }
`;
StyledTypograghy.displayName = "StyledTypograghy";

export const StyledInlineTextField = styled(Field)`
  && {
    margin-top: -5px;
    width: 80px;
  }
`;
StyledInlineTextField.displayName = "StyledInlineTextField";

export const StyledEditIcon = styled(EditIcon)`
  && {
    font-size: 95%;
    color: gray;
  }
`;
StyledEditIcon.displayName = "StyledEditIcon";

interface IEditDeviceAlertFormProps {
  device: IDevice;
  deviceAlertNotificationKind: (index: number) => void;
  sendDeviceAlertTestMessage: (phone: string) => void;
}

type EditDeviceAlertFormType = FormikProps<IDeviceAlert> &
  IEditDeviceAlertFormProps;

export const EditDeviceAlertForm: React.FunctionComponent<EditDeviceAlertFormType> = ({
  submitForm,
  isSubmitting,
  values: {
    isLoading,
    thresholdDisabled,
    missedDataDisabled,
    parameter: { unitText, label },
    notifyOnClearing,
    operator,
    threshold
  },
  device,
  setFieldValue,
  deviceAlertNotificationKind,
  sendDeviceAlertTestMessage
}: EditDeviceAlertFormType) => {
  const {
    alert: { operations, types, timeMultipliers }
  } = Configuration;

  const {
    required,
    threshold: thresholdValidator,
    renotifyInterval,
    minimumRepeats,
    missedDataInterval
  } = Validators.editDeviceAlert;

  const generateAlertMessage = (
    parameter: string,
    amOperator: string,
    amThreshold: string,
    parameterUnitText: string
  ) => {
    if (amOperator !== "MISSED_DATA") {
      const AlertOperator = operations
        .filter(({ id }) => id === amOperator)
        .pop() || { id: "", name: "" };

      setFieldValue(
        "message",
        `${parameter || "<Parameter>"} is ${
          amOperator ? AlertOperator.name.toLowerCase() : "<Operation>"
        } ${
          amThreshold ? amThreshold + " " + parameterUnitText : "<Threshold>"
        }`
      );
    } else {
      setFieldValue("message", `${parameter || "<Parameter>"} is missing`);
    }
  };

  const changeParameter = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedParameter = getDeviceAlertParameterName(
      device.basicCalculationProperties,
      event.target.value
    );
    const selectedParameterUnitText = getDeviceAlertParameterUnitText(
      event.target.value,
      device.basicCalculationProperties
    );
    setFieldValue("parameter", {
      label: selectedParameter,
      value: selectedParameter.toLowerCase(),
      unitText: selectedParameterUnitText
    });
    setFieldValue("threshold", "");

    generateAlertMessage(
      selectedParameter,
      operator,
      threshold,
      selectedParameterUnitText
    );
  };

  const changeAlertOperator = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFieldValue("operator", event.target.value);

    if (event.target.value !== "MISSED_DATA") {
      setFieldValue("missedDataDisabled", true);
      setFieldValue("thresholdDisabled", false);
      setFieldValue("missedDataInterval", { value: "2", multiplier: "h" });
    } else {
      setFieldValue("missedDataDisabled", false);
      setFieldValue("thresholdDisabled", true);

      setFieldValue("threshold", "");
      setFieldValue("minimumRepeats", "1");
    }

    generateAlertMessage(label, event.target.value, threshold, unitText);
  };

  const changeThresholdHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue("threshold", event.target.value);

    generateAlertMessage(label, operator, event.target.value, unitText);
  };

  const changeAlertLevel = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedAlertlevel = event.target.value;

    setFieldValue("alertLevel", selectedAlertlevel);
    setFieldValue("autoClear", Boolean(selectedAlertlevel === "WARNING"));
  };

  const renderDeviceAlertTemplateFormArray = (
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => (
    <DeviceAlertTemplateFormArray
      {...fieldArrayRenderProps}
      deviceAlertNotificationKind={deviceAlertNotificationKind}
      sendDeviceAlertTestMessage={sendDeviceAlertTestMessage}
    />
  );

  return (
    <EditAlertContainer>
      <StyledTitle>Conditions</StyledTitle>
      <Elevation>
        <StyledSelect>
          <InputLabel htmlFor="Parameter">Parameter</InputLabel>
          <Field
            name="parameter.value"
            label="Parameter"
            component={Select}
            validate={required}
            onChange={changeParameter}
            disabled={isLoading || isSubmitting}
          >
            {deviceAlertParameters(device.basicCalculationProperties).map(
              ({
                value,
                label: parameterLabel
              }: IDevcieAlertParameterSelection) => (
                <MenuItem key={value} value={value}>
                  {parameterLabel}
                </MenuItem>
              )
            )}
          </Field>
        </StyledSelect>
        <br />
        <StyledSelect>
          <InputLabel htmlFor="Operation">Operation</InputLabel>
          <Field
            name="operator"
            label="Operation"
            component={Select}
            validate={required}
            disabled={isLoading || isSubmitting}
            onChange={changeAlertOperator}
          >
            {operations.map(({ id, name }: IDeviceAlertOperation) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Field>
        </StyledSelect>
        <br />

        <StyledTextField
          type="text"
          label={`Threshold ${unitText ? `(${unitText})` : ""}`}
          name="threshold"
          validate={thresholdDisabled ? null : thresholdValidator}
          disabled={isLoading || isSubmitting || thresholdDisabled}
          onChange={changeThresholdHandler}
          component={TextField}
        />
        <br />
        {!missedDataDisabled && (
          <React.Fragment>
            <IntervalContainer>
              <StyledTypograghy
                disabled={missedDataDisabled || isLoading || isSubmitting}
              >
                Notify if data is missing for
              </StyledTypograghy>
              <StyledInlineTextField
                type="text"
                name="missedDataInterval.value"
                validate={missedDataInterval}
                disabled={isLoading || isSubmitting || missedDataDisabled}
                component={TextField}
              />

              <StyledSelect>
                <InputLabel htmlFor="Operation">Interval</InputLabel>
                <Field
                  name="missedDataInterval.multiplier"
                  label="Interval"
                  component={Select}
                  validate={required}
                  disabled={isLoading || isSubmitting || missedDataDisabled}
                >
                  {Object.keys(timeMultipliers).map((key, index) => (
                    <MenuItem key={index} value={key}>
                      {timeMultipliers[key].name}
                    </MenuItem>
                  ))}
                </Field>
              </StyledSelect>
            </IntervalContainer>
            <br />
          </React.Fragment>
        )}
        <Grid container={true} item={true} alignItems="baseline">
          <StyledTypograghy
            disabled={thresholdDisabled || isLoading || isSubmitting}
          >
            Only alert when the threshold has been breached for&nbsp;
          </StyledTypograghy>
          <StyledInlineTextField
            type="text"
            name="minimumRepeats"
            validate={minimumRepeats}
            disabled={isLoading || isSubmitting || thresholdDisabled}
            component={TextField}
          />
          <StyledTypograghy
            disabled={thresholdDisabled || isLoading || isSubmitting}
          >
            &nbsp;time(s).
          </StyledTypograghy>
        </Grid>
      </Elevation>
      <StyledTitle>Alert Details</StyledTitle>
      <Elevation>
        <StyledTextField
          type="text"
          label="Message"
          name="message"
          validate={required}
          multiline={true}
          disabled={isLoading || isSubmitting}
          component={TextField}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <StyledEditIcon />
              </InputAdornment>
            )
          }}
        />
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <StyledExpansionPanelTitle>Advanced</StyledExpansionPanelTitle>
          </ExpansionPanelSummary>
          <AlertExpansionPanelContainer>
            <StyledSelect>
              <InputLabel htmlFor="Parameter">Alert Type</InputLabel>
              <Field
                name="alertLevel"
                label="Alert Type"
                component={Select}
                validate={required}
                onChange={changeAlertLevel}
                disabled={isLoading || isSubmitting}
              >
                {types.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Field>
            </StyledSelect>
            <IntervalContainer>
              <StyledTypograghy disabled={isLoading || isSubmitting}>
                {`Send renotification every `}
              </StyledTypograghy>
              <StyledInlineTextField
                type="text"
                name="renotifyInterval.value"
                validate={renotifyInterval}
                disabled={isLoading || isSubmitting}
                component={TextField}
              />
              <StyledSelect>
                <Field
                  name="renotifyInterval.multiplier"
                  component={Select}
                  validate={required}
                  disabled={isLoading || isSubmitting}
                >
                  {Object.keys(timeMultipliers).map((key, index) => (
                    <MenuItem key={index} value={key}>
                      {timeMultipliers[key].name}
                    </MenuItem>
                  ))}
                </Field>
              </StyledSelect>
            </IntervalContainer>
            <Field
              disabled={isLoading || isSubmitting}
              name="notifyOnClearing"
              label="Also send a notification when alert is cleared"
              component={Switch}
              color="primary"
              value={notifyOnClearing}
            />
          </AlertExpansionPanelContainer>
        </ExpansionPanel>
      </Elevation>

      <StyledTitle>Notifications</StyledTitle>
      <Elevation>
        <FieldArray
          name="notification"
          render={renderDeviceAlertTemplateFormArray}
        />
      </Elevation>
      <br />
      {(isLoading || isSubmitting) && <LinearProgress />}
      <br />
      <Grid container={true} item={true} justify="flex-start">
        <Button
          variant="contained"
          color="primary"
          disabled={isLoading || isSubmitting}
          onClick={submitForm}
        >
          Save
        </Button>
      </Grid>
    </EditAlertContainer>
  );
};
EditDeviceAlertForm.displayName = "EditDeviceAlertForm";
