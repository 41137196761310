import axios, { AxiosResponse } from "axios";
import {
  Configuration,
  earaApiHeaders,
  ednaApiHeaders
} from "../../../core/configuration/config";
import {
  IDeviceAlert,
  IDeviceAlertApi,
  initialDeviceAlert
} from "../models/IAlerts";
import { ActionType } from "../../../core/ActionTypes";
import {
  deviceAlertFromApi,
  deviceAlertTemplateToApi,
  deviceAlertToApi,
  populateDeviceAlert,
  splitNotificationPhoneNumber,
  deviceAlertTestSMSNotificationToApi
} from "../services/DeviceAlertService";
import { Thunk } from "../../../core/store";
import { deviceAlertReducerType } from "../reducers/alertReducer";
import {
  createBlockableDispatch,
  createErrorConsoleMessage
} from "../../../core/utilities/ServiceUtilities";
import { ITableDeleteCell } from "../../../core/models/IShared";
import { getAlertCollectionByDeviceId } from "./alertCollectionAction";
import { getDeviceById } from "../../actions/deviceAction";
import { IDeviceAlertTemplateApi } from "../models/ITemplate";
import {
  checkError,
  SnackbarError
} from "../../../core/utilities/SnackbarUtilities";
import { getUserLocalePhoneCode } from "../../../core/utilities/PhoneUtilities";

export const getAlertByDeviceId: Thunk<deviceAlertReducerType> = (
  create: boolean,
  deviceId: string,
  alertId?: string
) => {
  return async (
    dispatch,
    getState,
    opt
  ): Promise<IDeviceAlert | SnackbarError | undefined> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    blockableDispatch({
      type: ActionType.DEVICE_ALERT_LOADING,
      payload: true
    });

    const {
      device: { name: deviceName, serial: deviceSerial },
      deviceCollection
    } = getState();

    let name: string = "";
    let serial: string = "";
    if (deviceCollection.members.length === 0) {
      const result = await dispatch(getDeviceById(deviceId));
      if (result !== undefined) {
        name = result[`name`];
        serial = result[`serial`];
      }
    }

    if (create) {
      const deviceAlert = { ...initialDeviceAlert };
      deviceAlert.device = deviceName || name;
      deviceAlert.deviceSerial = deviceSerial || serial;
      deviceAlert.shortId = "create";
      deviceAlert.alertLevel = Configuration.alert.types[0].id;
      deviceAlert.notification = [
        {
          kind: "",
          defaultMessage: "",
          countryCode: getUserLocalePhoneCode().code,
          mobileNumber: "",
          emailAddress: "",
          timer: { count: 0, running: false, timerId: 0 }
        }
      ];
      deviceAlert.notificationKind = [];
      blockableDispatch({
        type: ActionType.GET_EMPTY_DEVICE_ALERT,
        payload: deviceAlert
      });
      blockableDispatch({
        type: ActionType.DEVICE_ALERT_LOADING,
        payload: false
      });
      return;
    }

    try {
      const alertResult: AxiosResponse<IDeviceAlertApi> = await axios.get(
        Configuration.EaraAPIUrl + "/alerts/" + alertId,
        earaApiHeaders
      );

      let deviceAlert = deviceAlertFromApi(alertResult.data);
      if (deviceAlert.notificationTemplate) {
        try {
          const templateResult: AxiosResponse<IDeviceAlertTemplateApi> = await axios.get(
            Configuration.APIBaseURL + deviceAlert.notificationTemplate,
            ednaApiHeaders
          );
          deviceAlert.notification = templateResult.data.notifications.map(
            splitNotificationPhoneNumber
          );
        } catch (err) {
          return checkError(err);
        }
      }

      deviceAlert = populateDeviceAlert(deviceAlert, name, serial);

      blockableDispatch({
        type: ActionType.GET_DEVICE_ALERT,
        payload: deviceAlert
      });

      return deviceAlert;
    } catch (err) {
      createErrorConsoleMessage(err, "getAlertByDeviceId", {
        deviceId,
        create
      });
      return checkError(err);
    } finally {
      blockableDispatch({
        type: ActionType.DEVICE_ALERT_LOADING,
        payload: false
      });
    }
  };
};

export const createAlertByDeviceId: Thunk<deviceAlertReducerType> = (
  id: string,
  deviceAlert: IDeviceAlert
) => {
  return async (
    dispatch,
    getState,
    opt
  ): Promise<IDeviceAlert | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.SET_DEVICE_ALERT_FORM_VALUE,
      payload: deviceAlert
    });
    blockableDispatch({
      type: ActionType.DEVICE_ALERT_LOADING,
      payload: true
    });
    try {
      const {
        device: { subscription: devcieSub }
      } = getState();

      deviceAlert.notificationTemplate = "";
      if (deviceAlert.notification.length > 0) {
        try {
          const {
            data
          }: AxiosResponse<IDeviceAlertTemplateApi> = await axios.post(
            Configuration.EdnaAPIUrl + "/templates",
            deviceAlertTemplateToApi(deviceAlert.notification, devcieSub),
            ednaApiHeaders
          );
          deviceAlert.notificationTemplate = data["@id"];
        } catch (err) {
          return checkError(err);
        }
      }

      const deviceAlertResponse: AxiosResponse<IDeviceAlertApi> = await axios.post(
        Configuration.EaraAPIUrl + "/alerts",
        deviceAlertToApi({
          ...deviceAlert,
          device: id,
          subscription: devcieSub
        }),
        earaApiHeaders
      );

      const createdDeviceAlert = deviceAlertFromApi(deviceAlertResponse.data);
      blockableDispatch({
        type: ActionType.CREATE_DEVICE_ALERT,
        payload: createdDeviceAlert
      });

      return createdDeviceAlert;
    } catch (err) {
      createErrorConsoleMessage(err, "createAlertByDeviceId", { id, alert });
      return checkError(err);
    } finally {
      blockableDispatch({
        type: ActionType.DEVICE_ALERT_LOADING,
        payload: false
      });
    }
  };
};

export const updateAlertByDeviceId: Thunk<deviceAlertReducerType> = (
  alertId: string,
  deviceAlert: IDeviceAlert
) => {
  return async (dispatch, getState): Promise<IDeviceAlert | SnackbarError> => {
    dispatch({ type: ActionType.UPDATE_DEVICE_ALERT, payload: deviceAlert }); // removes flickering of data
    dispatch({
      type: ActionType.SET_DEVICE_ALERT_FORM_VALUE,
      payload: deviceAlert
    });
    dispatch({ type: ActionType.DEVICE_ALERT_LOADING, payload: true });
    try {
      const {
        device: { shortId, name, serial }
      } = getState();

      if (deviceAlert.notificationTemplate) {
        if (deviceAlert.notification.length > 0) {
          try {
            await axios.put(
              Configuration.APIBaseURL + deviceAlert.notificationTemplate,
              deviceAlertTemplateToApi(
                deviceAlert.notification,
                deviceAlert.subscription,
                deviceAlert.notificationTemplate
              ),
              ednaApiHeaders
            );
          } catch (err) {
            return checkError(err);
          }
        } else {
          try {
            await axios.delete(
              Configuration.APIBaseURL + deviceAlert.notificationTemplate,
              ednaApiHeaders
            );

            deviceAlert.notificationTemplate = "";
          } catch (err) {
            return checkError(err);
          }
        }
      } else {
        if (deviceAlert.notification.length > 0) {
          try {
            const alertTemplateResponse: AxiosResponse<IDeviceAlertTemplateApi> = await axios.post(
              Configuration.EdnaAPIUrl + "/templates",
              deviceAlertTemplateToApi(
                deviceAlert.notification,
                deviceAlert.subscription
              ),
              ednaApiHeaders
            );
            deviceAlert.notificationTemplate =
              alertTemplateResponse.data["@id"];
          } catch (err) {
            return checkError(err);
          }
        }
      }

      const deviceAlertResponse: AxiosResponse<IDeviceAlertApi> = await axios.put(
        Configuration.EaraAPIUrl + "/alerts/" + alertId,
        deviceAlertToApi({ ...deviceAlert, device: shortId || "" }),
        earaApiHeaders
      );

      const updatedDeviceAlert = deviceAlertFromApi(deviceAlertResponse.data);
      updatedDeviceAlert.device = name;
      updatedDeviceAlert.deviceSerial = serial;

      if (updatedDeviceAlert.notificationTemplate) {
        try {
          const result: AxiosResponse<IDeviceAlertTemplateApi> = await axios.get(
            Configuration.APIBaseURL + updatedDeviceAlert.notificationTemplate,
            ednaApiHeaders
          );

          updatedDeviceAlert.notification = result.data.notifications.map(
            splitNotificationPhoneNumber
          );

          if (updatedDeviceAlert.notification.length > 0) {
            updatedDeviceAlert.notification.forEach((item, index) => {
              updatedDeviceAlert.notificationKind[index] = item.kind;
            });
          }
        } catch (err) {
          return checkError(err);
        }
      }

      dispatch({
        type: ActionType.UPDATE_DEVICE_ALERT,
        payload: updatedDeviceAlert
      });

      return updatedDeviceAlert;
    } catch (err) {
      createErrorConsoleMessage(err, "updateAlertByDeviceId", {
        alertId,
        alert
      });
      return checkError(err);
    } finally {
      dispatch({ type: ActionType.DEVICE_ALERT_LOADING, payload: false });
    }
  };
};

export const clearAlert = (): deviceAlertReducerType => {
  return {
    type: ActionType.CLEAR_DEVICE_ALERT
  };
};

export const deviceAlertDeleteConfirmDialog: Thunk<deviceAlertReducerType> = (
  deviceAlertInfo: ITableDeleteCell
) => {
  return async dispatch => {
    dispatch({
      type: ActionType.DELETE_DEVICE_ALERT_DIALOG,
      payload: deviceAlertInfo
    });
  };
};

export const deleteDeviceAlert: Thunk<deviceAlertReducerType> = (
  deviceId: string,
  deviceAlertInfo: ITableDeleteCell
) => {
  return async (dispatch): Promise<SnackbarError> => {
    dispatch({
      type: ActionType.DEVICE_ALERT_LOADING,
      payload: true
    });
    try {
      await axios.delete(
        Configuration.APIBaseURL + deviceAlertInfo.id,
        earaApiHeaders
      );

      dispatch({
        type: ActionType.DELETE_DEVICE_ALERT_DIALOG,
        payload: { ...deviceAlertInfo, visible: false }
      });

      dispatch(getAlertCollectionByDeviceId(deviceId));
    } catch (err) {
      createErrorConsoleMessage(err, "deleteDeviceAlert", {
        deviceId,
        deviceAlertInfo
      });
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.DEVICE_ALERT_LOADING,
        payload: false
      });
    }
  };
};

export const deviceAlertNotificationKind = (
  index: number
): deviceAlertReducerType => {
  return {
    type: ActionType.SET_DEVICE_ALERT_NOTIFICATION_KIND,
    payload: index
  };
};

export const sendDeviceAlertTestMessage: Thunk<deviceAlertReducerType> = (
  phone: string
) => {
  return async (_, getState): Promise<SnackbarError> => {
    try {
      const {
        profile: { activeSubscription }
      } = getState();
      await axios.post(
        Configuration.EdnaAPIUrl + "/notifications",
        deviceAlertTestSMSNotificationToApi(phone, activeSubscription),
        ednaApiHeaders
      );
    } catch (err) {
      createErrorConsoleMessage(err, "sendDeviceAlertTestMessage", {
        phone
      });
      return checkError(err);
    }
  };
};
