import React from "react";
import { TableBody } from "@material-ui/core";

import { DeviceTableWrapper } from "../../components/DeviceTable";
import { IDeviceAlert } from "../models/IAlerts";
import { DeviceAlertTableHead } from "./DeviceAlertTable/DeviceAlertTableHead";
import { DeviceAlertTableRow } from "./DeviceAlertTable/DeviceAlertTableRow";
import { DeviceAlertTableFooter } from "./DeviceAlertTable/DeviceAlertTableFooter";
import { ITableDeleteCell } from "../../../core/models/IShared";
import { IDevice } from "../../models/IDevice";
import { IPaginationView } from "../../../core/models/MixinInterfaces";

export interface IAlertTableProps {
  isLoading: boolean;
  rows: IDeviceAlert[];
  pagination: IPaginationView;
  totalItems: number;
  onChangePage: (page: string) => void;
  handleDialogOpen: (
    itemInfo: ITableDeleteCell
  ) => (_: React.MouseEvent) => void;
  device: IDevice;
}

export const AlertTable: React.FunctionComponent<IAlertTableProps> = props => {
  const {
    onChangePage,
    totalItems,
    isLoading,
    handleDialogOpen,
    device,
    rows
  } = props;
  const isDeviceAlertCollection = Boolean(
    typeof totalItems === "number" && onChangePage
  );

  return (
    <DeviceTableWrapper>
      <DeviceAlertTableHead
        hide={!isDeviceAlertCollection}
        isLoading={isLoading}
      />
      <TableBody>
        {rows.map(row => (
          <DeviceAlertTableRow
            key={row.id}
            alert={row}
            hide={!isDeviceAlertCollection}
            handleDialogOpen={handleDialogOpen}
            device={device}
          />
        ))}
      </TableBody>
      <DeviceAlertTableFooter {...props} />
    </DeviceTableWrapper>
  );
};
AlertTable.displayName = "AlertTable";
