import React from "react";
import { Tooltip } from "@material-ui/core";
import { Sms as SMSIcon } from "@material-ui/icons";

import { IDeviceAlert } from "../models/IAlerts";
import { ActionButton, StyledButton } from "./DeviceAlertTemplate";

interface IDeviceAlertTestMessage {
  index: number;
  alert: IDeviceAlert;
  isSubmitting: boolean;
  sendTestMessageHandle: (index: number) => (_: React.MouseEvent) => void;
}
export const DeviceAlertTestSMSMessage: React.FunctionComponent<IDeviceAlertTestMessage> = ({
  index,
  alert: { notification: notifications, isLoading },
  isSubmitting,
  sendTestMessageHandle
}: IDeviceAlertTestMessage) => (
  <Tooltip
    title={
      !notifications[index].timer.running
        ? "Send test SMS"
        : "Wait to send another test SMS"
    }
  >
    {!notifications[index].timer.running ? (
      <ActionButton
        visibility="visible"
        onClick={sendTestMessageHandle(index)}
        aria-label="SMS"
        disabled={Boolean(isSubmitting || isLoading)}
      >
        <SMSIcon />
      </ActionButton>
    ) : (
      <StyledButton variant="text" color="secondary">
        {`WAIT (00:${notifications[index].timer.count})`}
      </StyledButton>
    )}
  </Tooltip>
);
