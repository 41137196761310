import React from "react";
import styled from "styled-components";
import { Configuration } from "../core/configuration/config";

const { danger, timeout: timeoutColor, normal } = Configuration.colors.icon;

interface ITimeout {
  readonly timeout: boolean;
}

export const RSSIErrorIcon: React.FunctionComponent<ITimeout> = ({
  timeout,
  ...props
}) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
    <path
      style={{ fill: "currentColor" }}
      d="M15,16 L18,16 L18,13.6 L15,13.6 L15,16 Z M10,16 L13,16 L13,13.6 L10,13.6 L10,16 Z M5,16 L8,16 L8,13.6 L5,13.6 L5,16 Z M0,16 L3,16 L3,13.6 L0,13.6 L0,16 Z M0.13,0.13 C0.33,-0.04 0.64,-0.04 0.84,0.13 L0.84,0.13 L3.00,2.36 L5.14,0.13 C5.34,-0.04 5.65,-0.04 5.85,0.13 C6.04,0.34 6.04,0.66 5.85,0.87 L5.85,0.87 L3.70,3.09 L5.85,5.30 C6.04,5.51 6.04,5.83 5.85,6.03 C5.75,6.14 5.62,6.19 5.49,6.19 C5.37,6.19 5.24,6.14 5.14,6.03 L5.14,6.03 L3.00,3.81 L0.84,6.03 C0.74,6.14 0.61,6.19 0.49,6.19 C0.36,6.19 0.23,6.14 0.13,6.03 C-0.04,5.83 -0.04,5.51 0.13,5.30 L0.13,5.30 L2.28,3.09 L0.13,0.87 C-0.04,0.66 -0.04,0.34 0.13,0.13 Z"
    />
  </svg>
);
RSSIErrorIcon.displayName = "RSSIErrorIcon";

export const RSSINoSignalIcon: React.FunctionComponent<ITimeout> = ({
  timeout,
  ...props
}) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
    <path
      style={{ fill: "currentColor" }}
      d="M18,22 L21,22 L21,20 L18,20 L18,22 Z M13,22 L16,22 L16,20 L13,20 L13,22 Z M8,22 L11,22 L11,20 L8,20 L8,22 Z M3,22 L6,22 L6,20 L3,20 L3,22 Z"
    />
  </svg>
);
RSSINoSignalIcon.displayName = "RSSINoSignalIcon";

export const RSSILowSignalIcon: React.FunctionComponent<ITimeout> = ({
  timeout,
  ...props
}) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
    <path
      style={{ fill: "currentColor" }}
      d="M18,22 L21,22 L21,20 L18,20 L18,22 Z M13,22 L16,22 L16,20 L13,20 L13,22 Z M8,22 L11,22 L11,20 L8,20 L8,22 Z M3,22 L6,22 L6,17 L3,17 L3,22 Z"
    />
  </svg>
);
RSSILowSignalIcon.displayName = "RSSILowSignalIcon";

export const RSSIMidSignalIcon: React.FunctionComponent<ITimeout> = ({
  timeout,
  ...props
}) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
    <path
      style={{ fill: "currentColor" }}
      d="M18,22 L21,22 L21,20 L18,20 L18,22 Z M13,22 L16,22 L16,7 L13,7 L13,22 Z M8,22 L11,22 L11,12 L8,12 L8,22 Z M3,22 L6,22 L6,17 L3,17 L3,22 Z"
    />
  </svg>
);
RSSIMidSignalIcon.displayName = "RSSIMidSignalIcon";

export const RSSIHighSignalIcon: React.FunctionComponent<ITimeout> = ({
  timeout,
  ...props
}) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
    <path
      style={{ fill: "currentColor" }}
      d="M18,22 L21,22 L21,2 L18,2 L18,22 Z M13,22 L16,22 L16,7 L13,7 L13,22 Z M8,22 L11,22 L11,12 L8,12 L8,22 Z M3,22 L6,22 L6,17 L3,17 L3,22 Z"
    />
  </svg>
);
RSSIHighSignalIcon.displayName = "RSSIHighSignalIcon";

export const StyledRSSIErrorIcon = styled(RSSIErrorIcon)<ITimeout>`
  color: ${p => (p.timeout ? timeoutColor : danger)};
`;
StyledRSSIErrorIcon.displayName = "StyledRSSIErrorIcon";

export const StyledRSSINoSignalIcon = styled(RSSINoSignalIcon)<ITimeout>`
  color: ${p => (p.timeout ? timeoutColor : danger)};
`;
StyledRSSINoSignalIcon.displayName = "StyledRSSINoSignalIcon";

export const StyledRSSILowSignalIcon = styled(RSSILowSignalIcon)<ITimeout>`
  color: ${p => (p.timeout ? timeoutColor : danger)};
`;
StyledRSSILowSignalIcon.displayName = "StyledRSSILowSignalIcon";

export const StyledRSSIMidSignalIcon = styled(RSSIMidSignalIcon)<ITimeout>`
  color: ${p => (p.timeout ? timeoutColor : normal)};
`;
StyledRSSIMidSignalIcon.displayName = "StyledRSSIMidSignalIcon";

export const StyledRSSIHighSignalIcon = styled(RSSIHighSignalIcon)<ITimeout>`
  color: ${p => (p.timeout ? timeoutColor : normal)};
`;
StyledRSSIHighSignalIcon.displayName = "StyledRSSIHighSignalIcon";
