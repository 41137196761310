import React from "react";
import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps
} from "@material-ui/core/Checkbox";
import { FieldProps } from "formik";

export interface ICheckboxProps
  extends FieldProps,
    Omit<
      MuiCheckboxProps,
      | "name"
      | "value"
      | "error"
      | "form"
      | "checked"
      | "defaultChecked"
      // Excluded for conflict with Field type
      | "type"
    > {
  type?: string;
}

export const fieldToCheckbox = ({
  field,
  form: { isSubmitting },
  disabled = false,
  ...props
}: ICheckboxProps): MuiCheckboxProps => {
  return {
    disabled: isSubmitting || disabled,
    ...props,
    ...field,
    onChange: props.onChange ? props.onChange : field.onChange,
    checked: Boolean(field.value),
    value: field.value ? "checked" : ""
  };
};

export const Checkbox: React.ComponentType<ICheckboxProps> = (
  props: ICheckboxProps
) => <MuiCheckbox {...fieldToCheckbox(props)} />;

Checkbox.displayName = "FormikMaterialUICheckbox";
