import axios, { AxiosResponse } from "axios";

import {
  Configuration,
  edlaApiHeaders
} from "../../../core/configuration/config";
import { ActionType } from "../../../core/ActionTypes";
import { deviceDownlinkCollectionFromApi } from "../services/DeviceDownlinksService";
import { Thunk } from "../../../core/store";
import { deviceDownlinkCollectionReducerTypes } from "../reducers/downlinkCollectionReducer";
import {
  createBlockableDispatch,
  createErrorConsoleMessage
} from "../../../core/utilities/ServiceUtilities";
import {
  checkError,
  SnackbarError
} from "../../../core/utilities/SnackbarUtilities";
import {
  IDeviceDownlinkCollection,
  IDeviceDownlinkCollectionApi
} from "../models/IDownlinks";
import { getDeviceById } from "../../actions/deviceAction";
import { getDeviceOfDownlink } from "../services/DeviceDownlinksService";

export const getDownlinkCollectionByDeviceId: Thunk<deviceDownlinkCollectionReducerTypes> = (
  id: string
) => {
  return async (
    dispatch,
    getState,
    opt
  ): Promise<IDeviceDownlinkCollection | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    blockableDispatch({
      type: ActionType.DEVICE_DOWNLINK_COLLECTION_LOADING,
      payload: true
    });

    const { deviceCollection } = getState();

    if (deviceCollection.members.length > 0) {
      blockableDispatch({
        type: ActionType.GET_DEVICE,
        payload: getDeviceOfDownlink(id, deviceCollection)
      });
    } else {
      dispatch(getDeviceById(id));
    }

    try {
      const response: AxiosResponse<IDeviceDownlinkCollectionApi> = await axios.get(
        Configuration.EdlaAPIUrl + "/downlinks",
        {
          ...edlaApiHeaders,
          params: {
            device: Configuration.devicePrefixUrl + id
          }
        }
      );

      const deviceDownlinks: IDeviceDownlinkCollection = deviceDownlinkCollectionFromApi(
        response.data
      );

      blockableDispatch({
        type: ActionType.GET_DEVICE_DOWNLINK_COLLECTION,
        payload: deviceDownlinks
      });

      return deviceDownlinks;
    } catch (err) {
      createErrorConsoleMessage(err, "getDownlinkCollectionByDeviceId", { id });
      return checkError(err);
    } finally {
      blockableDispatch({
        type: ActionType.DEVICE_DOWNLINK_COLLECTION_LOADING,
        payload: false
      });
    }
  };
};

export const changePageDownlinkCollectionByDeviceId: Thunk<deviceDownlinkCollectionReducerTypes> = (
  id: string,
  page: string
) => {
  return async (
    dispatch,
    getState,
    opt
  ): Promise<IDeviceDownlinkCollection | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    blockableDispatch({
      type: ActionType.DEVICE_DOWNLINK_COLLECTION_LOADING,
      payload: true
    });

    const { deviceCollection } = getState();

    if (deviceCollection.members.length > 0) {
      blockableDispatch({
        type: ActionType.GET_DEVICE,
        payload: getDeviceOfDownlink(id, deviceCollection)
      });
    } else {
      dispatch(getDeviceById(id));
    }

    try {
      const response: AxiosResponse<IDeviceDownlinkCollectionApi> = await axios.get(
        Configuration.APIBaseURL + page,
        {
          ...edlaApiHeaders
        }
      );

      const deviceDownlinks: IDeviceDownlinkCollection = deviceDownlinkCollectionFromApi(
        response.data
      );

      blockableDispatch({
        type: ActionType.GET_DEVICE_DOWNLINK_COLLECTION,
        payload: deviceDownlinks
      });

      return deviceDownlinks;
    } catch (err) {
      createErrorConsoleMessage(err, "getDownlinkCollectionByDeviceId", {
        id
      });
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.DEVICE_DOWNLINK_COLLECTION_LOADING,
        payload: false
      });
    }
  };
};

export const clearDeviceDownlinkCollection = (): deviceDownlinkCollectionReducerTypes => {
  return {
    type: ActionType.CLEAR_DEVICE_DOWNLINK_COLLECTION
  };
};
