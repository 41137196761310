import { ICollection, ILoadable } from "../../../core/models/MixinInterfaces";
import {
  IDetermination,
  IApiResponseHeader
} from "../../../core/models/MixinInterfaces";
import { IDownlinkFormats, IDevice, initialDevice } from "../../models/IDevice";
import { IDeviceModelApi } from "../../models/IDeviceModels";

export interface IDeviceDownlinkApi extends IApiResponseHeader {
  "@context": string;
  device: string;
  status: string;
  network: {
    name: string;
    deviceNetworkId: string;
    [key: string]: {};
  };
  data: { hex: string };
  confirmed: boolean;
  port: number;
  dateCreated: string;
  dateUpdated: string;
}

export interface IDeviceDownlinkCollectionApi
  extends IApiResponseHeader,
    ICollection {
  "@context": string;
  member: IDeviceDownlinkApi[];
}

export interface IDownlinkConfig {
  defaultPort: number;
  defaultConfirmed: boolean;
  url: string;
  apiToken: string;
}

export interface IDeviceDownlinkNetwork {
  name: string;
  deviceNetworkId: string;
  downlinkConfig: IDownlinkConfig;
  networkType: string;
  appID: string;
}

export interface IDownlinkParameter {
  format: string;
  initialValue: string;
  value: string;
  mask: any[];
  name: string;
}

export interface IDeviceDownlink extends ILoadable, IDetermination {
  context: string;
  advanced: boolean;
  device: IDevice;
  deviceModel?: IDeviceModelApi;
  status: string;
  network: IDeviceDownlinkNetwork;
  data: { hex: string };
  tmpHexs: IDownlinkParameter[];
  confirmed: boolean;
  downlinkFormat: IDownlinkFormats;
  port: number;
  dateCreated: string;
  dateUpdated: string;
}

export interface IDeviceDownlinkCollection
  extends ILoadable,
    IDetermination,
    ICollection {
  context: string;
  members: IDeviceDownlink[];
  __initialRequest: IDeviceDownlinkCollectionApi;
}

export const initialDeviceDownlink: IDeviceDownlink = {
  context: "",
  isLoading: false,
  id: "",
  shortId: "",
  advanced: false,
  type: "",
  confirmed: false,
  data: { hex: "" },
  tmpHexs: [],
  device: { ...initialDevice },
  network: {
    name: "",
    deviceNetworkId: "",
    appID: "",
    downlinkConfig: {
      defaultPort: 0,
      defaultConfirmed: true,
      url: "",
      apiToken: ""
    },
    networkType: ""
  },
  status: "",
  downlinkFormat: {
    "@type": "",
    contentType: "",
    name: "",
    format: ""
  },
  port: 5,
  dateCreated: "",
  dateUpdated: ""
};

export const initialDeviceDownlinkCollection: IDeviceDownlinkCollection = {
  context: "",
  isLoading: false,
  id: "",
  shortId: "",
  type: "",
  members: [],
  totalItems: 0,
  view: { "@id": "", "@type": "", first: "", last: "", next: "", previous: "" },
  __initialRequest: {
    member: [],
    "@id": "",
    view: {
      "@type": "",
      "@id": "",
      previous: "",
      next: "",
      last: "",
      first: ""
    },
    totalItems: 0,
    "@type": "",
    "@context": ""
  }
};

export const initialNetworkDownlinkConfig: IDownlinkConfig = {
  defaultPort: 0,
  defaultConfirmed: true,
  url: "",
  apiToken: ""
};

export interface ISeparatedDownlinkFormat {
  formatField: string;
  initialValue: string;
  format: string;
  hexLength: number;
  mask: any[];
}
