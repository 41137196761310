import * as React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { FormikHelpers as FormikActions } from "formik/dist/types";
import { Grid } from "@material-ui/core";

import { withThemeProvider } from "../core/withThemeProvider";
import { IRootState } from "../core/store";
import { IExportDevice } from "./models/IExportDevice";
import { exportDevice, initializeExport } from "./actions/exportDeviceAction";
import { ExportDeviceForm } from "./components/ExportDeviceForm";
import {
  StyledBackground,
  StyledTitle
} from "../components/sharedStyledComponents";
import {
  getStyledSnackbarOptions,
  isSnackbarError,
  SnackbarError,
  snackbarMessages
} from "../core/utilities/SnackbarUtilities";
import { withSnackbar, WithSnackbarProps } from "notistack";

interface IExportDeviceStateProps {
  exportDeviceData: IExportDevice;
}

interface IExportDeviceDispatchProps {
  init: () => Promise<IExportDevice | SnackbarError>;
  exportData: (exportData: IExportDevice) => Promise<undefined | SnackbarError>;
}

export class ExportDeviceData extends React.Component<
  IExportDeviceStateProps & IExportDeviceDispatchProps & WithSnackbarProps
> {
  public async componentDidMount() {
    const { enqueueSnackbar, init } = this.props;
    const initializedState = await init();
    if (isSnackbarError(initializedState)) {
      enqueueSnackbar(initializedState.message, initializedState.options);
    }
  }

  public onSubmit = async (
    values: IExportDevice,
    { setSubmitting }: FormikActions<IExportDevice>
  ) => {
    const { exportData, enqueueSnackbar } = this.props;
    if (values) {
      const exportResult = await exportData(values);
      if (isSnackbarError(exportResult)) {
        enqueueSnackbar(exportResult.message, exportResult.options);
      } else {
        enqueueSnackbar(
          snackbarMessages.EXPORT_SUCCESS,
          getStyledSnackbarOptions("success")
        );
      }
      setSubmitting(false);
    }
  };

  public render() {
    const { exportDeviceData } = this.props;
    return (
      <React.Fragment>
        <StyledTitle>Export Device Data</StyledTitle>
        <StyledBackground>
          <Grid item={true} xs={12} sm={6} md={4} lg={3} xl={3}>
            <Formik
              enableReinitialize={true}
              initialValues={exportDeviceData}
              onSubmit={this.onSubmit}
            >
              {props => <ExportDeviceForm {...props} />}
            </Formik>
          </Grid>
        </StyledBackground>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IExportDeviceStateProps => ({
  exportDeviceData: state.exportDevice
});

const mapDispatchToProps = (dispatch: any): IExportDeviceDispatchProps => {
  return {
    init: () => dispatch(initializeExport()),
    exportData: (exportData: IExportDevice) =>
      dispatch(exportDevice(exportData))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(ExportDeviceData)));
