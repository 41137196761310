import moment from "moment";
import { Moment } from "moment";
import { IDevice } from "../../Devices/models/IDevice";
import { ILoadable } from "../../core/models/MixinInterfaces";

export interface IResourceVolumeData {
  volume: number;
  volumeUnit: string;
}
export interface IResourceVolumePercentData {
  volumePercent: number;
  volumePercentUnit: string;
}
export interface IResourceDepthData {
  depth: number;
  depthUnit: string;
}
export interface IForecast {
  value: number;
  chance: string;
  unit: string;
  time: Moment;
}
export interface IRainChance {
  chance: number;
  atLeastMm: number;
  unit: string;
  time: Moment;
}
export interface IVolume {
  time: Moment;
  value: number;
  unit: string;
}

export interface IVolumeChanges {
  time: Moment;
  max?: number;
  min?: number;
  prediction?: number;
  unit: string;
}
export interface ITableMeasurement {
  value: number;
  unitText: string;
  unitCode: string;
}
export interface IInfluxTableQuery {
  device?: string;
  volume?: ITableMeasurement;
  level?: ITableMeasurement;
  pressure?: ITableMeasurement;
  turbidity?: ITableMeasurement;
  temperature?: ITableMeasurement;
  chargeLevel?: ITableMeasurement;
  primaryParameter?: ITableMeasurement;
  rssi?: ITableMeasurement;
  lastSeen?: Moment;
  // batteryLevel: number;
  // waterTemp: number;
  // waterLevel: number;
  // volume: number;
  // batteryLevelPostfix: string;
  // waterTempPostfix: string;
  // waterLevelPostfix: string;
  // volumePostfix: string;
}
export interface IResourceData {
  device?: IDevice;
  volumeData?: IResourceVolumeData;
  volumePercentData?: IResourceVolumePercentData;
  depthData?: IResourceDepthData;
  chart?: IVolumeChanges[];
  previousMonthBaseData?: IVolume;
  previousThreeMonthsBaseData?: IVolume;
  baseDataSubtYear?: IVolume;
  previousMonthSubtYearBaseData?: IVolume;
  previousThreeMonthsSubtYearBaseData?: IVolume;
  nextMonth?: IForecast[];
  next3Month?: IForecast[];
  rainChanceNextMonth?: IRainChance[];
  rainChanceNext3Month?: IRainChance[];
}
export interface IResource extends ILoadable {
  openedTab: number;
  weatherAvailable: boolean;

  volume?: number;
  volumeUnit?: string;
  volumePercent?: number;
  volumePercentUnit?: string;
  volumeLastYear?: number;
  volumeLastYearUnit?: string;
  depth?: number;
  depthUnit?: string;
  nextMonthName: string;
  nextThreeMonthName: string;
  volumeChanges: IVolumeChanges[];
  pastVolumeOneMonthValue?: number;
  pastVolumeOneMonthValueUnit?: string;
  pastVolumeOneMonthLastYear?: number;
  pastVolumeOneMonthLastYearUnit?: string;
  pastVolumeOneMonthName: string;
  pastVolumeThreeMonthValue?: number;
  pastVolumeThreeMonthValueUnit?: string;
  pastVolumeThreeMonthLastYear?: number;
  pastVolumeThreeMonthLastYearUnit?: string;
  pastVolumeThreeMonthName: string;

  nextMonthVolumeChance75?: number;
  nextMonthVolumeChance50?: number;
  nextMonthVolumeChance25?: number;
  nextMonthVolumeChance75Unit?: string;
  nextMonthVolumeChance50Unit?: string;
  nextMonthVolumeChance25Unit?: string;
  nextMonthRainChance: IRainChance[];

  nextThreeMonthVolumeChance75?: number;
  nextThreeMonthVolumeChance50?: number;
  nextThreeMonthVolumeChance25?: number;
  nextThreeMonthVolumeChance75Unit?: string;
  nextThreeMonthVolumeChance50Unit?: string;
  nextThreeMonthVolumeChance25Unit?: string;
  nextThreeMonthRainChance: IRainChance[];
}
export const initialResource: IResource = {
  openedTab: 0,
  isLoading: true,
  weatherAvailable: true,
  volumeChanges: [],
  pastVolumeOneMonthName: moment()
    .subtract(1, "month")
    .format("MMMM"),
  pastVolumeThreeMonthName:
    moment()
      .subtract(4, "month")
      .format("MMMM") +
    " - " +
    moment()
      .subtract(1, "month")
      .format("MMMM"),
  nextMonthRainChance: [],
  nextThreeMonthRainChance: [],
  nextMonthName: moment()
    .add(1, "month")
    .format("MMMM"),
  nextThreeMonthName: moment()
    .add(3, "month")
    .format("MMMM")
};
