import React from "react";
import styled from "styled-components";
import { AccessTime, BatteryAlert, BatteryFull } from "@material-ui/icons";
import { Configuration } from "../core/configuration/config";

const {
  timeout: timeoutColor,
  normal,
  danger,
  default: defaultColor
} = Configuration.colors.icon;

interface ITimeout {
  readonly timeout: boolean;
}

export const BatteryAlertIcon: React.FunctionComponent<ITimeout> = ({
  timeout,
  ...props
}) => <BatteryAlert {...props} />;
BatteryAlertIcon.displayName = "BatteryAlertIcon";

export const BatteryFullIcon: React.FunctionComponent<ITimeout> = ({
  timeout,
  ...props
}) => <BatteryFull {...props} />;
BatteryFullIcon.displayName = "BatteryFullIcon";

export const ThermometerIcon: React.FunctionComponent = props => (
  <svg
    version="1.2"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g>
      <path
        d="M13 15.071v-5.571c0-.275-.225-.5-.5-.5s-.5.225-.5.5v5.571c-.86.224-1.5 1-1.5 1.929 0 1.103.896 2 2 2s2-.897 2-2c0-.929-.64-1.705-1.5-1.929zM16 13.459v-7.959c0-1.93-1.57-3.5-3.5-3.5s-3.5 1.57-3.5 3.5v7.959c-.922.902-1.5 2.151-1.5 3.541 0 2.757 2.243 5 5 5s5-2.243 5-5c0-1.39-.578-2.639-1.5-3.541zm-3.5 6.541c-1.654 0-3-1.346-3-3 0-1.105.607-2.062 1.5-2.583v-8.917c0-.827.673-1.5 1.5-1.5s1.5.673 1.5 1.5v8.917c.893.521 1.5 1.478 1.5 2.583 0 1.654-1.346 3-3 3z"
        style={{ fill: "currentColor" }}
      />
    </g>
  </svg>
);
ThermometerIcon.displayName = "ThermometerIcon";

export const StyledThermometerIconNormal = styled(ThermometerIcon)`
  color: ${defaultColor};
`;
StyledThermometerIconNormal.displayName = "StyledThermometerIconNormal";

export const StyledThermometerIconDanger = styled(ThermometerIcon)`
  color: ${danger};
`;
StyledThermometerIconDanger.displayName = "StyledThermometerIconDanger";

export const StyledBatteryNormalIcon = styled(BatteryFullIcon)<ITimeout>`
  color: ${p => (p.timeout ? timeoutColor : normal)};
`;
StyledBatteryNormalIcon.displayName = "StyledBatteryNormalIcon";

export const StyledBatteryDangerIcon = styled(BatteryAlertIcon)<ITimeout>`
  color: ${p => (p.timeout ? timeoutColor : danger)};
`;
StyledBatteryDangerIcon.displayName = "StyledBatteryDangerIcon";

export const StyledLastSeenIconNormal = styled(AccessTime)`
  color: ${normal};
`;
StyledLastSeenIconNormal.displayName = "StyledLastSeenIconNormal";

export const StyledLastSeenIconDanger = styled(AccessTime)`
  color: ${danger};
`;
StyledLastSeenIconDanger.displayName = "StyledLastSeenIconDanger";
