import React, { FunctionComponent } from "react";
import { Field, Form, FormikProps } from "formik";
import {
  Button,
  ExpansionPanelDetails,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem
} from "@material-ui/core";
// import { ExpandMore } from "@material-ui/icons";
import styled from "styled-components";

import { FormikDatePicker } from "../../components/DatePicker";
import { Select, StyledSelect } from "../../components/Select";
import { IDevice } from "../../Devices/models/IDevice";
import { ExportDatepicker } from "../../ExportDevice/components/ExportDeviceForm";
import { Validators } from "../../util/validators";
import { ILocationsHistory } from "../models/ILocationsHistory";

export const SplitTripsContainer = styled(Grid)`
  & > * {
    min-width: 465px;
  }
  padding: 0 15px;
`;
SplitTripsContainer.displayName = "SplitTripsContainer";

export const LocationAdvancedDetailsContainer = styled(ExpansionPanelDetails)`
  flex-direction: column;
`;
LocationAdvancedDetailsContainer.displayName =
  "LocationAdvancedDetailsContainer";

export const DeviceLocationsForm: FunctionComponent<FormikProps<
  ILocationsHistory
>> = ({ submitForm, isSubmitting, values }: FormikProps<ILocationsHistory>) => {
  const {
    isLoading,
    from,
    // radius,
    // interval,
    devices
  } = values;
  // const {
  //   alert: { timeMultipliers }
  // } = Configuration;
  // const distanceMultipliers = {
  //   m: { value: 1, name: "Meter(s)" },
  //   km: { value: 60, name: "Kilometer(s)" },
  //   mi: { value: 60 * 24, name: "Mile(s)" }
  // };
  return (
    <Form>
      <Grid container={true} direction="row">
        <Grid
          container={true}
          item={true}
          xs={12}
          sm={12}
          md={12}
          lg={4}
          xl={3}
        >
          <StyledSelect>
            <InputLabel htmlFor="deviceId">Device</InputLabel>
            <Field
              name="deviceId"
              label="Device"
              component={Select}
              validate={Validators.locationHistory.device}
              disabled={isSubmitting || isLoading}
            >
              {devices.map((device: IDevice) => (
                <MenuItem key={device.id} value={device.id}>
                  {`${device.serial} - ${device.name}`}
                </MenuItem>
              ))}
            </Field>
          </StyledSelect>
          <ExportDatepicker
            name="from"
            label="From Date (including)"
            component={FormikDatePicker}
            disabled={isSubmitting || isLoading}
          />
          <ExportDatepicker
            name="to"
            label="To Date (including)"
            disabled={isSubmitting || isLoading}
            minDate={from.clone().add(1, "days")}
            minDateMessage="To Date should not be before or equal to From Date"
            component={FormikDatePicker}
          />
        </Grid>
        {/*<SplitTripsContainer*/}
        {/*  container={true}*/}
        {/*  item={true}*/}
        {/*  direction="column"*/}
        {/*  xs={12}*/}
        {/*  sm={12}*/}
        {/*  md={12}*/}
        {/*  lg={8}*/}
        {/*  xl={5}*/}
        {/*>*/}
        {/*  <ExpansionPanel>*/}
        {/*    <ExpansionPanelSummary expandIcon={<ExpandMore />}>*/}
        {/*      <StyledExpansionPanelTitle>Advanced</StyledExpansionPanelTitle>*/}
        {/*    </ExpansionPanelSummary>*/}
        {/*    <LocationAdvancedDetailsContainer>*/}
        {/*      <IntervalContainer>*/}
        {/*        <StyledTypograghy disabled={isLoading || isSubmitting}>*/}
        {/*          Split trips if vehicle has stopped for*/}
        {/*        </StyledTypograghy>*/}
        {/*        <StyledInlineTextField*/}
        {/*          type="text"*/}
        {/*          name="truckStaying"*/}
        {/*          disabled={isLoading || isSubmitting}*/}
        {/*          component={TextField}*/}
        {/*        />*/}
        {/*        <StyledSelect>*/}
        {/*          <InputLabel htmlFor="Operation">Interval</InputLabel>*/}
        {/*          <Field*/}
        {/*            name="missedDataInterval.multiplier"*/}
        {/*            label="Interval"*/}
        {/*            component={Select}*/}
        {/*            disabled={isLoading || isSubmitting}*/}
        {/*          >*/}
        {/*            {Object.keys(timeMultipliers).map((key, index) => (*/}
        {/*              <MenuItem key={index} value={key}>*/}
        {/*                {timeMultipliers[key].name}*/}
        {/*              </MenuItem>*/}
        {/*            ))}*/}
        {/*          </Field>*/}
        {/*        </StyledSelect>*/}
        {/*      </IntervalContainer>*/}
        {/*      <IntervalContainer>*/}
        {/*        <StyledTypograghy disabled={isLoading || isSubmitting}>*/}
        {/*          Within*/}
        {/*        </StyledTypograghy>*/}
        {/*        <StyledInlineTextField*/}
        {/*          type="text"*/}
        {/*          name="truckStayingRadius"*/}
        {/*          disabled={isLoading || isSubmitting}*/}
        {/*          component={TextField}*/}
        {/*        />*/}
        {/*        <StyledSelect>*/}
        {/*          <InputLabel htmlFor="Operation">Distance</InputLabel>*/}
        {/*          <Field*/}
        {/*            name="km"*/}
        {/*            label="Interval"*/}
        {/*            component={Select}*/}
        {/*            disabled={isLoading || isSubmitting}*/}
        {/*          >*/}
        {/*            {Object.keys(distanceMultipliers).map((key, index) => (*/}
        {/*              <MenuItem key={index} value={key}>*/}
        {/*                {distanceMultipliers[key].name}*/}
        {/*              </MenuItem>*/}
        {/*            ))}*/}
        {/*          </Field>*/}
        {/*        </StyledSelect>*/}
        {/*      </IntervalContainer>*/}
        {/*    </LocationAdvancedDetailsContainer>*/}
        {/*  </ExpansionPanel>*/}
        {/*</SplitTripsContainer>*/}
      </Grid>
      {(isSubmitting || isLoading) && <LinearProgress />}
      <br />
      <Grid container={true} item={true} justify="flex-start">
        <Button
          variant="contained"
          color="primary"
          disabled={isLoading || isSubmitting}
          onClick={submitForm}
        >
          refresh
        </Button>
      </Grid>
    </Form>
  );
};
DeviceLocationsForm.displayName = "DeviceLocationsForm";
