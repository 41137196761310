import React, { FunctionComponent } from "react";
import { Form, FormikProps } from "formik";
import {
  Button,
  Grid,
  InputLabel,
  LinearProgress,
  Link
} from "@material-ui/core";
import styled from "styled-components";

import { StyledTextField, TextField } from "../../components/TextField";
import { IDevice } from "../models/IDevice";
import { Validators } from "../../util/validators";
import { theme } from "../../core/withThemeProvider";

export const ModelLink = styled.div`
  width: 100%;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.42);
  line-height: 1.1875em;
  font-size: 1rem;
  padding: 5px 0;
  & > label {
    padding-bottom: ${theme.spacing(0.5)}px;
  }
`;

export const EditDeviceForm: FunctionComponent<FormikProps<IDevice>> = ({
  submitForm,
  isSubmitting,
  values
}: FormikProps<IDevice>) => {
  const { isLoading, deviceModel, model } = values;
  const { name, note, latitude, longitude } = Validators.editDevice;

  return (
    <Form>
      <StyledTextField
        type="text"
        label="Serial"
        name="serial"
        disabled={true}
        component={TextField}
      />
      <br />
      {!isLoading && model === "<INACCESSIBLE>" ? (
        <ModelLink>
          <InputLabel shrink={true} disabled={true}>
            Model
          </InputLabel>
          <Link href={deviceModel}>{model}</Link>
        </ModelLink>
      ) : (
        <StyledTextField
          type="text"
          label="Model"
          name="model"
          disabled={true}
          component={TextField}
        />
      )}
      <br />
      <StyledTextField
        type="text"
        label="Name"
        name="name"
        validate={name}
        disabled={isLoading || isSubmitting}
        component={TextField}
      />
      <br />
      <StyledTextField
        type="text"
        label="Note"
        name="note"
        validate={note}
        multiline={true}
        disabled={isLoading || isSubmitting}
        component={TextField}
      />
      <br />
      <StyledTextField
        type="number"
        label="Latitude"
        name="manualLocation.geo.latitude"
        validate={latitude}
        disabled={isLoading || isSubmitting}
        component={TextField}
      />
      <br />
      <StyledTextField
        type="number"
        label="Longitude"
        name="manualLocation.geo.longitude"
        validate={longitude}
        disabled={isLoading || isSubmitting}
        component={TextField}
      />
      <br />
      {(isLoading || isSubmitting) && <LinearProgress />}
      <br />
      <Grid container={true} item={true} justify="flex-start">
        <Button
          variant="contained"
          color="primary"
          disabled={isLoading || isSubmitting}
          onClick={submitForm}
        >
          Save
        </Button>
      </Grid>
    </Form>
  );
};
EditDeviceForm.displayName = "EditDeviceForm";
