import React, { ComponentType } from "react";
import { Checkbox } from "@material-ui/core";
import FormControlLabel, {
  FormControlLabelProps as MuiFormControlLabelProps
} from "@material-ui/core/FormControlLabel";

import { ICheckboxProps, fieldToCheckbox } from "./Checkbox";
import { Omit } from "../core/models/types";
import styled from "styled-components";
import { FieldProps } from "formik";

/**
 * Exclude props that are passed directly to the control
 * https://github.com/mui-org/material-ui/blob/v3.1.1/packages/material-ui/src/FormControlLabel/FormControlLabel.js#L71
 */
export interface ICheckboxWithLabelProps extends FieldProps, ICheckboxProps {
  Label: Omit<
    MuiFormControlLabelProps,
    "checked" | "name" | "onChange" | "value" | "control"
  >;
}

export const CheckboxWithLabel: ComponentType<ICheckboxWithLabelProps> = ({
  Label,
  ...props
}) => (
  <FormControlLabel
    control={<Checkbox {...fieldToCheckbox(props)} />}
    {...Label}
  />
);

CheckboxWithLabel.displayName = "FormikMaterialUICheckboxWithLabel";

export const StyledCheckbox = styled.div`
  height: 35px;
  :last-child {
    margin-bottom: 30px;
  }
`;
StyledCheckbox.displayName = "StyledCheckbox";
