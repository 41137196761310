import * as React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";

import { StyledProgress } from "../../Resources";
import { NotAvailable } from "../../../components/MeasurementNA";
import { StyledVolumeIcon } from "../../../components/VolumeIcon";

export interface IVolumeChanceBlock {
  volume?: number;
  chance: number;
  unit?: string;
  loading: boolean;
}

export const VolumeChanceGrid = styled(Grid)`
  && {
    flex: 1 1 auto;
    padding: 30px 15px 60px;
    position: relative;
  }
`;
VolumeChanceGrid.displayName = "VolumeChanceGrid";

export const VolumeChanceIcon = styled(StyledVolumeIcon)`
  height: 100px;
`;
VolumeChanceIcon.displayName = "VolumeChanceIcon";

export const VolumeChanceValue = styled.span`
  font-size: 60px;
  padding-left: 25px;
  font-weight: lighter;
  color: #29527d;
  & > span {
    align-self: baseline;
    font-size: 25px;
    padding-left: 10px;
  }
`;
VolumeChanceValue.displayName = "VolumeChanceValue";

export const VolumeChanceCaption = styled.div`
  font-size: 15px;
  padding-top: 10px;
  width: 100%;
  text-align: center;
`;
VolumeChanceCaption.displayName = "VolumeChanceCaption";

export const ResourceVolumeChanceMain: React.FunctionComponent<IVolumeChanceBlock> = props => {
  const { volume, chance, unit, loading } = props;
  return (
    <VolumeChanceGrid
      container={true}
      item={true}
      direction="row"
      alignItems="center"
      justify="center"
    >
      <VolumeChanceIcon />
      {loading ? (
        <StyledProgress margin="0 40px" />
      ) : (
        <VolumeChanceValue>
          <NotAvailable fontSize="40px" property={volume}>
            {volume}
            <span>{unit}</span>
          </NotAvailable>
        </VolumeChanceValue>
      )}
      <VolumeChanceCaption>{chance}% Chance</VolumeChanceCaption>
    </VolumeChanceGrid>
  );
};
ResourceVolumeChanceMain.displayName = "ResourceVolumeChanceMain";
