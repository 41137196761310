import React from "react";
import moment from "moment";
import styled from "styled-components";
import {
  List,
  ListItem,
  Divider,
  Hidden,
  ListItemText,
  Grid,
  LinearProgress,
  Typography,
  ListItemSecondaryAction
} from "@material-ui/core";

import { IDeviceDownlink } from "../models/IDownlinks";
import { IPaginationView } from "../../../core/models/MixinInterfaces";
import { Pagination } from "../../../components/Pagination";
import { Configuration } from "../../../core/configuration/config";
import { DeleteDialog } from "./DownlinkDeleteDialog";

interface IDownlinkListProps {
  isLoading?: boolean;
  rows: IDeviceDownlink[];
  totalItems: number;
  onChangePage: (page: string) => void;
  pagination: IPaginationView;
  deleteDownlink: (id: string) => Promise<void>;
}

export const StyledGrid = styled(Grid)`
  && > li {
    padding-bottom: unset;
    width: 100%;
  }
`;
StyledGrid.displayName = "StyledGrid";

export const StyledTypography = styled(Typography)`
  && {
    padding-left: 16px;
    padding-bottom: 8px;
  }
`;
StyledTypography.displayName = "StyledTypography";

export const getPrimaryText = ({ network }: IDeviceDownlink) =>
  `${network[`deviceNetworkId`]}`;

export const getSecondaryText = ({ network }: IDeviceDownlink) =>
  `${network[`name`]}`;

export const DownlinkList: React.FunctionComponent<IDownlinkListProps> = ({
  isLoading,
  rows,
  onChangePage,
  pagination,
  totalItems,
  deleteDownlink
}: IDownlinkListProps) => (
  <List>
    {isLoading && <LinearProgress />}
    {rows.map(row => (
      <React.Fragment key={row.id}>
        <Hidden smUp={true}>
          <StyledGrid container={true} item={true} alignItems="center">
            <ListItem>
              <ListItemText
                primary={getPrimaryText(row)}
                secondary={getSecondaryText(row)}
              />
              <ListItemSecondaryAction>
                {row.status === "PENDING" && (
                  <DeleteDialog iconButton={true} item={row.id} deleteAction={deleteDownlink}>
                    Are you sure you want to delete this Downlink?
                    <br />
                    <b>Network:</b> {row.network[`deviceNetworkId`]}
                    <br />
                    <b>Data:</b> {row.data[`hex`]}
                  </DeleteDialog>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          </StyledGrid>
          <Grid container={true} item={true} alignItems="center">
            <StyledTypography>
              {` ${moment(row.dateCreated).format(
                Configuration.defaultDateFormat
              )} - ${moment(row.dateCreated).format(
                Configuration.defaultTimeFormat
              )} `}
            </StyledTypography>
          </Grid>
          <Divider />
        </Hidden>
        <Hidden xsDown={true}>
          <Grid container={true} alignItems="center">
            <Grid item={true} xs={8}>
              <ListItem>
                <ListItemText
                  primary={getPrimaryText(row)}
                  secondary={getSecondaryText(row)}
                />
              </ListItem>
            </Grid>
            <Grid item={true} xs={4}>
              <ListItem>
                <ListItemText
                  primary={moment(row.dateCreated).format(
                    Configuration.defaultDateFormat
                  )}
                  secondary={moment(row.dateCreated).format(
                    Configuration.defaultTimeFormat
                  )}
                />
                <ListItemSecondaryAction>
                  {row.status === "PENDING" && (
                    <DeleteDialog iconButton={true} item={row.id} deleteAction={deleteDownlink}>
                      Are you sure you want to delete this Downlink?
                      <br />
                      <b>Network:</b> {row.network[`deviceNetworkId`]}
                      <br />
                      <b>Data:</b> {row.data[`hex`]}
                    </DeleteDialog>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            </Grid>
          </Grid>
          <Divider />
        </Hidden>
      </React.Fragment>
    ))}
    {pagination && onChangePage && totalItems !== undefined && (
      <Pagination
        isLoading={isLoading}
        onChangePage={onChangePage}
        pagination={pagination}
        totalItems={totalItems}
      />
    )}
  </List>
);
DownlinkList.displayName = "DownlinkList";
