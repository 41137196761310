const APIBaseURL = "https://api.herames.net";

export const StagingConfig = {
  APIBaseURL,
  EdiAPIUrl: APIBaseURL + "/v1/edi",
  EsmaAPIUrl: APIBaseURL + "/v1/esma",
  EaraAPIBaseUrl: APIBaseURL,
  EaraAPIUrl: APIBaseURL + "/v1/eara",
  EdnaAPIBaseUrl: APIBaseURL,
  EdnaAPIUrl: APIBaseURL + "/v1/edna",
  ExportAPIUrl: APIBaseURL + "/exports",
  ElaAPIUrl: APIBaseURL + "/v1/ela",
  EdlaAPIUrl: APIBaseURL + "/v1/edla",

  ediApikey: "6QYFxhuG7e8EASKxD6BpF2SFGv1WyEZx2i6BQGr4",
  esmaApikey: "lHXlMHYfC39dFUqkHcBdralH7fggUNRuSGOnzZTa",
  authApikey: "Nmgs3RYCB2zi9wLfFMvR1BU8qEtf3QiaMdVALocj",
  eramaApikey: "CVhPZkMS538XDP8ELbGzuaEU063cMain790tk38W",
  earaApikey: "eX7jiengip1Thie0Oogh5ag2naiSuir3eguqu2Ej",
  ednaApikey: "Qui5oocapuX0aer4ucuJee3GOsho5fe6noo8naeN",
  elaApikey: "xZcGQ2Y9zVhgdkASKDYdp6K3fFL8hDcevrwgwxtZ",
  edlaApikey: "xvLzpz8iN850YkHGPssdD2cNYF3sVD154zu8dKHD",

  auth: {
    cognitoRoot:
      "https://beta-ellenex-net.auth.ap-southeast-2.amazoncognito.com/",
    tokenUrl: APIBaseURL + "/v1/auth/token",
    login: "login",
    logout: "logout",
    redirectUri: document.location ? document.location.origin : "",
    clientId: "ibq5s4341ha3iscp5qk9v5i10"
  },
  development: true,
  geo: {
    mapboxKey:
      "pk.eyJ1IjoiZXZ0LXZsYWQiLCJhIjoiY2s2MXlheTB3MDd1NzNkbzQwbXQ5OHJ3cSJ9.F0gdRvZRB0aTo1B2tf4pJg",
    googleMapsKey: "AIzaSyABJQFZExwCXTaz1j7A6mRBl1f7lB9m6m0",
    trips: {
      splitTripTimeout: 15 * 60 * 1000,
      trailLength: 1000,
      animationSpeedMultiplier: 1 * 10000,
      iconSizeMultiplier: 3,
      pathWidth: { min: 3, max: 10 }
    },
    mapCenter: {
      lat: -25.61,
      lng: 134.35472
    },
    defaultZoom: {
      noDevice: 4,
      oneDevice: 15
    },
    clustering: {
      minZoom: 3,
      maxZoom: 25,
      radius: 40
    }
  },
  influxConfig: {
    protocol: "https",
    host: "api.herames.net",
    port: "",
    database: "v1",
    options: {
      headers: {
        "x-api-key": "H3Vz903rbe3cN98Um9jNolLiSgt2HUP2sR5Mo1th",
        "x-lnx-token": ""
      },
      hostname: "api.herames.net/v1/influxdb"
    }
  }
};
