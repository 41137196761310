import { IDevice, initialDevice } from "../models/IDevice";
import { ActionType } from "../../core/ActionTypes";
import { IInfluxTableQuery } from "../../Resources/models/IResource";

export type deviceReducerActionTypes =
  | { type: ActionType.DEVICE_LOADING; payload: boolean }
  | { type: ActionType.GET_DEVICE; payload: IDevice }
  | { type: ActionType.UPDATE_DEVICE; payload: IDevice }
  | { type: ActionType.CLEAR_DEVICE }
  | { type: ActionType.DEVICE_MEASUREMENT_LOADING; payload: boolean }
  | { type: ActionType.GET_DEVICE_MEASUREMENTS; payload: IInfluxTableQuery[] }
  | { type: ActionType.REINITIALIZE };

export function deviceReducer(
  state: IDevice = { ...initialDevice },
  action: deviceReducerActionTypes
): IDevice {
  switch (action.type) {
    case ActionType.REINITIALIZE:
      state = {
        ...initialDevice
      };
      break;
    case ActionType.DEVICE_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
    case ActionType.GET_DEVICE:
      state = {
        ...state,
        ...action.payload
      };
      break;
    case ActionType.UPDATE_DEVICE:
      state = {
        ...state,
        ...action.payload
      };
      break;
    case ActionType.CLEAR_DEVICE:
      state = { ...initialDevice };
      break;
    case ActionType.DEVICE_MEASUREMENT_LOADING:
      state = {
        ...state,
        measurements: {
          ...state.measurements,
          isLoading: action.payload
        }
      };
      break;
    case ActionType.GET_DEVICE_MEASUREMENTS:
      const deviceMeasurements = action.payload.filter(
        measurement => measurement.device === state.shortId
      );
      state = {
        ...state,
        measurements: {
          ...state.measurements,
          values:
            deviceMeasurements.length === 1
              ? deviceMeasurements[0]
              : state.measurements.values
        }
      };
      break;
    default:
      break;
  }
  return state;
}
